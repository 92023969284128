import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { conditionListSelector, conditionLoadingSelector } from 'src/store/selectors/condition-selectors';
import { Paginated } from 'src/services/api-handlers/pagination';
import { conditionList } from 'src/store/thunks/condition-thunks';
import { Condition } from 'src/types/condition';
import ConditionList from 'src/screens/Condition/components/ConditionList';

const ConditionListScreen: FC = () => {
    const dispatch = useDispatch();
    const loading: boolean = useSelector(conditionLoadingSelector);
    const data: Paginated<Condition> = useSelector(conditionListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(conditionList(params));
    }, []);

    return (
        <MainLayout>
            <ContentHeader title="Conditions" />
            <Content>
                <ConditionList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default ConditionListScreen;
