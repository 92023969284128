import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Router from '../../../navigation/router';
import userRole from 'src/enumerables/user-role';
import Authorized from 'src/components/auth/Authorized';
import SidebarItem from 'src/components/layout/sidebar/SidebarItem';
import { faCheckSquare, faHeart, faHeartbeat, faMale, faUsers } from '@fortawesome/free-solid-svg-icons';
import { routes } from 'src/navigation';
import styles from './assets/sidebar.module.scss';
import bodyPartValidationType from 'src/enumerables/body-part-validation-type';

const Sidebar: React.FunctionComponent = React.memo(() => {
    const location = useLocation();
    const isActiveValidation = (): boolean => {
        return [
            Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                type: bodyPartValidationType.GENERAL,
            }),
            Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                type: bodyPartValidationType.IMAGE,
            }),
            Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                type: bodyPartValidationType.SQUAT,
            }),
        ].includes(location.pathname);
    };
    return (
        <aside className={classNames('main-sidebar', 'sidebar-dark-primary')}>
            <strong className={classNames('brand-link', 'text-center')}>
                <Link to={Router.generate('/')} className={classNames('text-white', 'text-lg', styles.title)}>
                    PHY
                    <span className={classNames(styles.subTitle)}>Point Tool</span>
                </Link>
            </strong>
            <div className={classNames('sidebar')}>
                <nav className={classNames('mt-2')}>
                    <ul className={classNames('nav', 'nav-pills', 'nav-sidebar', 'flex-column')}>
                        <Authorized actions={[userRole.ROLE_ADMIN, userRole.ROLE_PT, userRole.ROLE_STAFF]}>
                            <SidebarItem title="Sessions" icon={faMale} link={Router.generate(routes.SCAN_LIST)} />
                        </Authorized>
                        <Authorized actions={[userRole.ROLE_ADMIN, userRole.ROLE_PT]}>
                            <SidebarItem title="Models" icon={faMale} link={Router.generate(routes.MODEL_LIST)} />
                            <SidebarItem
                                title="Body Parts"
                                icon={faHeart}
                                link={Router.generate(routes.BODY_PART_LIST)}
                            />
                            <SidebarItem
                                title="Validation"
                                icon={faCheckSquare}
                                isActive={isActiveValidation}
                                link={Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                                    type: bodyPartValidationType.GENERAL,
                                })}
                            />
                            <SidebarItem
                                title="Conditions"
                                icon={faHeartbeat}
                                link={Router.generate(routes.CONDITION_LIST)}
                            />
                            <SidebarItem title="Users" icon={faUsers} link={Router.generate(routes.STAFF_LIST)} />
                        </Authorized>
                    </ul>
                </nav>
            </div>
        </aside>
    );
});

export default Sidebar;
