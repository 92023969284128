import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'scans';

const get = (id: string): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/${id}`);

const list = (params: any): AxiosPromise<any> => EndPointService.get(`/${ROUTE}`, params);

const update = (id: string, payload: any): AxiosPromise<any> => EndPointService.patch(`/${ROUTE}/${id}`, payload);

export default {
    get,
    list,
    update,
};
