import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import Restricted from 'src/components/auth/Restricted';
import userRole from 'src/enumerables/user-role';
import Authenticated from 'src/components/auth/Authenticated';
import { ScoreListScreen } from 'src/screens/Scan/screens/Score/screens/List';
import { ScoreCreateScreen } from 'src/screens/Scan/screens/Score/screens/Create';

const scanScoreRoutes = [
    <Route
        exact
        key={routes.SCAN_SCORES_LIST}
        path={routes.SCAN_SCORES_LIST}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ScoreListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.SCAN_SCORES_CREATE}
        path={routes.SCAN_SCORES_CREATE}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ScoreCreateScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default scanScoreRoutes;
