import { EnumerableAbstract } from './enumerable.abstract';

class ScanPhyScoreStatus extends EnumerableAbstract {
    readonly PENDING = 0;
    readonly APPROVED = 1;

    getName = () => 'UserRole';

    defaultChoices = () => ({
        [this.PENDING]: 'Pending',
        [this.APPROVED]: 'Approved',
    });
}

const scanPhyScoreStatus = new ScanPhyScoreStatus();

export default scanPhyScoreStatus;
