import * as React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../schema/login';
import { useDispatch } from 'src/hooks/dispatch';
import { login } from 'src/store/thunks/auth-thunks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import FormError from 'src/components/react-hook-form/FormError';
import { GlobalError, withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import FieldInput from 'src/components/react-hook-form/fields/FieldInput';

export interface LoginFormData {
    username: string;
    password: string;
}

export interface LoginFormProps {
    onSuccess?: () => void;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({ onSuccess }) => {
    const dispatch = useDispatch();
    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, errors },
    } = useForm<LoginFormData>({
        resolver: yupResolver(loginSchema),
    });

    const onSubmit = async (data: LoginFormData) => {
        const res = await withErrors<LoginFormData>(dispatch(login(data)).unwrap(), setError);
        if (res !== false) {
            onSuccess && onSuccess();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classNames('form')}>
            <div className={classNames('login-logo')}>PHY</div>
            <div className={classNames('card')}>
                <div className={classNames('card-body', 'login-card-body')}>
                    <p className="login-box-msg">Sign in to Point Tool</p>
                    <div className="text-center">
                        <FormError
                            error={(errors as GlobalError)._error?.message}
                            classNames={{ errorContainer: 'text-center mb-2 mt-0 d-flex justify-content-center' }}
                        />
                        <FieldInput
                            name={'username'}
                            control={control}
                            type="email"
                            error={errors?.username?.message}
                            placeholder={'Email'}
                            wrapperProps={{
                                classNames: {
                                    inputContainer: 'input-group mb-2',
                                    errorContainer: 'text-center mb-2',
                                },
                            }}
                        >
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                            </div>
                        </FieldInput>
                        <FieldInput
                            name={'password'}
                            control={control}
                            type="password"
                            error={errors?.password?.message}
                            placeholder={'Password'}
                            wrapperProps={{
                                classNames: {
                                    inputContainer: 'input-group mb-2',
                                    errorContainer: 'text-center mb-2',
                                },
                            }}
                        >
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faLock} />
                                </div>
                            </div>
                        </FieldInput>
                        <button type="submit" className={classNames('btn', 'btn-primary', 'btn-block')}>
                            {isSubmitting ? <span className={classNames('spinner-border')} /> : 'Sign in'}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LoginForm;
