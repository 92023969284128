import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import { deleteScanCondition } from 'src/store/thunks/scan-condition-thunks';
import { scanConditionLoadingSelector } from 'src/store/selectors/scan-condition-selectors';
import { ScanCondition } from 'src/types/scan-condition';
import { useParams } from 'react-router';

interface Props {
    scanCondition: ScanCondition;
    successCallback?: () => void;
    children: ReactNode;
}

type Params = { id: string };

const DeleteScanCondition: FC<Props> = ({ scanCondition, children, successCallback }: Props) => {
    const dispatch = useDispatch();
    const loading = useSelector(scanConditionLoadingSelector);
    const { id } = useParams<Params>();
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleDelete = useCallback(async () => {
        await dispatch(deleteScanCondition({ scanId: id, conditionId: scanCondition.id })).unwrap();
        setShowModal(false);
        toast.success('Scan condition was deleted');
        successCallback && successCallback();
    }, [scanCondition.id]);

    return (
        <>
            <a href="#" onClick={handleShow}>
                {children}
            </a>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this scan condition?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classNames('btn-default')} onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        className={classNames('btn-danger')}
                        onClick={handleDelete}
                        disabled={loading}
                        loading={loading}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteScanCondition;
