import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GuestLayout from 'src/components/layout/GuestLayout';
import classNames from 'classnames';
import { useUser } from 'src/hooks/user';
import { useHistory } from 'react-router-dom';
import routes from 'src/navigation/routes';
import ConfirmPhone from './components/ConfirmPhone';
import UpdatePhone from 'src/screens/PhoneVerification/screens/ConfirmPhoneScreen/components/UpdatePhone';
import Loader from 'src/components/Loader';

interface Props {}

enum Steps {
    CONFIRM,
    UPDATE,
}

const ConfirmPhoneScreen: React.FC<Props> = ({}: Props) => {
    const history = useHistory();
    const user = useUser();
    const [step, setStep] = useState(Steps.CONFIRM);

    const onPhoneUpdated = useCallback(() => {
        history.push(routes.PHONE_VERIFY);
    }, []);

    useEffect(() => {
        if (user && user.phoneVerified) {
            history.push(routes.VERIFY_CODE);
        }
    }, [user]);

    const content = useMemo(() => {
        if (!user) {
            return <Loader />;
        }
        switch (true) {
            case step === Steps.CONFIRM && !!user.phoneNumber:
                return (
                    <ConfirmPhone
                        user={user}
                        yesBtnHandler={() => history.push(routes.PHONE_VERIFY)}
                        noBtnHandler={() => setStep(Steps.UPDATE)}
                    />
                );
            case step === Steps.UPDATE || !user.phoneNumber:
                return <UpdatePhone user={user} onSuccess={onPhoneUpdated} onBack={() => setStep(Steps.CONFIRM)} />;
        }
    }, [step, user]);

    return <GuestLayout>{<div className={classNames('login-page')}>{content}</div>}</GuestLayout>;
};

export default ConfirmPhoneScreen;
