import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';
import ScanScoreApi from 'src/services/end-points/scan-score-api';
import { ScanScore } from 'src/types/scan-score';

export const scanScoreList = createAsyncThunk<Paginated<ScanScore>, { scanId: string } & QueryParams, ThunkConfig>(
    'scanScore/list',
    async ({ scanId, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanScore } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            scanScore,
            async () =>
                ScanScoreApi.list(scanId, {
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            scanScore.data,
        );
    },
);

export const createScanScore = createAsyncThunk<ScanScore, { scanId: string }, ThunkConfig>(
    'scanScore/create',
    async ({ scanId, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanScore } = getState();

        return resolveApiCall(
            thunkAPI,
            scanScore,
            async () => {
                const response = await ScanScoreApi.create(scanId, convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const updateScanScore = createAsyncThunk<
    ScanScore,
    { scanId: string; id: string } & Partial<ScanScore>,
    ThunkConfig
>('scanScore/update', async ({ scanId, id, ...payload }, thunkAPI) => {
    const { getState } = thunkAPI;
    const { scanScore } = getState();

    return resolveApiCall(
        thunkAPI,
        scanScore,
        async () => {
            const response = await ScanScoreApi.update(scanId, id, payload);
            return response.data;
        },
        async (err) => makeFormErrorsFromResponse(err.response.data),
    );
});
