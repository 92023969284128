import React from 'react';
import { CellProps } from 'react-table';
import moment from 'moment';
import { DATETIME_FORMAT } from 'src/config/env';

const DateTimeColumn: React.FC<CellProps<any>> = React.memo(({ cell: { value } }: CellProps<any>) => (
    <span>{moment(value).format(DATETIME_FORMAT)}</span>
));

export default DateTimeColumn;
