import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import styles from '../assets/scan-phy-score-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import FieldInput from 'src/components/react-hook-form/fields/FieldInput';
import scanPhyScoreSchema from 'src/screens/Scan/screens/PhyScore/schema/scanPhyScoreShema';
import { ScanPhyScore } from 'src/types/scan-phy-score';
import ScanPhyScoreStatus from 'src/enumerables/scan-phy-score-status';

export interface PhyScoresFormData {
    headCervicalSpine: number;
    shouldersScapula: number;
    elbowWrists: number;
    thoracicSpineRibcage: number;
    lumbarSpinePelvisSacrumCoccyx: number;
    hips: number;
    knees: number;
    feet: number;
    stance: number;
    stabilityStrengthScore: number;
    status: number;
}

interface PhyScoresFormProps {
    title: string;
    scanPhyScore: ScanPhyScore;
    onSubmit: (data: PhyScoresFormData, dispatch: AppDispatch) => Promise<any>;
}

const PhyScoreForm: FC<PhyScoresFormProps> = ({ title, onSubmit, scanPhyScore }: PhyScoresFormProps) => {
    const {
        control,
        register,
        handleSubmit,
        reset,
        setError,
        formState: { isSubmitting, submitCount, isSubmitSuccessful, errors },
    } = useForm<PhyScoresFormData>({
        resolver: yupResolver(scanPhyScoreSchema),
        mode: 'onBlur',
        defaultValues: scanPhyScore,
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const navigateBack = useCallback(() => {
        history.goBack();
    }, []);

    const isApproved = scanPhyScore?.status === ScanPhyScoreStatus.APPROVED;

    useEffect(() => {
        reset(scanPhyScore);
    }, [scanPhyScore]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            toast.success('Data was saved');
            navigateBack();
        } else {
            if ((errors as any).company) {
                toast.error((errors as any).company.message);
            }
        }
    }, [submitCount]);

    const submit = async (data: PhyScoresFormData) => {
        await withErrors<PhyScoresFormData>(onSubmit(data, dispatch), setError);
        toast.success(`Data was successfully updated`);
    };

    const submitAndApprove = async (data: PhyScoresFormData) => {
        await withErrors<PhyScoresFormData>(
            onSubmit({ ...data, status: ScanPhyScoreStatus.APPROVED }, dispatch),
            setError,
        );
        toast.success(`Data was successfully updated and approved`);
    };

    return (
        <form className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldInput
                            name={'headCervicalSpine'}
                            type={'number'}
                            control={control}
                            error={errors?.headCervicalSpine?.message}
                            disabled={isApproved}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.headCervicalSpine.spec.label }}
                        />
                        <FieldInput
                            name={'shouldersScapula'}
                            type={'number'}
                            control={control}
                            error={errors?.shouldersScapula?.message}
                            disabled={isApproved}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.shouldersScapula.spec.label }}
                        />
                        <FieldInput
                            name={'elbowWrists'}
                            type={'number'}
                            control={control}
                            error={errors?.elbowWrists?.message}
                            disabled={isApproved}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.elbowWrists.spec.label }}
                        />
                        <FieldInput
                            name={'thoracicSpineRibcage'}
                            type={'number'}
                            control={control}
                            disabled={isApproved}
                            error={errors?.thoracicSpineRibcage?.message}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.thoracicSpineRibcage.spec.label }}
                        />
                        <FieldInput
                            name={'lumbarSpinePelvisSacrumCoccyx'}
                            type={'number'}
                            control={control}
                            disabled={isApproved}
                            error={errors?.lumbarSpinePelvisSacrumCoccyx?.message}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.lumbarSpinePelvisSacrumCoccyx.spec.label }}
                        />
                        <FieldInput
                            name={'hips'}
                            type={'number'}
                            control={control}
                            error={errors?.hips?.message}
                            disabled={isApproved}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.hips.spec.label }}
                        />
                        <FieldInput
                            name={'knees'}
                            type={'number'}
                            control={control}
                            error={errors?.knees?.message}
                            disabled={isApproved}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.knees.spec.label }}
                        />
                        <FieldInput
                            name={'feet'}
                            type={'number'}
                            control={control}
                            disabled={isApproved}
                            error={errors?.feet?.message}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.feet.spec.label }}
                        />
                        <FieldInput
                            name={'stance'}
                            type={'number'}
                            control={control}
                            error={errors?.stance?.message}
                            disabled={isApproved}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.stance.spec.label }}
                        />
                        <FieldInput
                            name={'stabilityStrengthScore'}
                            type={'number'}
                            control={control}
                            disabled={isApproved}
                            error={errors?.stabilityStrengthScore?.message}
                            wrapperProps={{ label: scanPhyScoreSchema.fields.stabilityStrengthScore.spec.label }}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                {!isApproved && (
                    <>
                        <Button
                            type="button"
                            className={classNames('btn-success')}
                            onClick={handleSubmit(submit)}
                            {...register('status')}
                            disabled={isSubmitting}
                            value={ScanPhyScoreStatus.PENDING}
                        >
                            <FontAwesomeIcon icon={faSave} /> Update
                        </Button>
                        <Button
                            type="submit"
                            className={classNames('btn-success')}
                            onClick={handleSubmit(submitAndApprove)}
                            {...register('status')}
                            disabled={isSubmitting}
                            value={ScanPhyScoreStatus.APPROVED}
                        >
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                            <FontAwesomeIcon icon={faCheck} className="mr-2" /> Update and Approve
                        </Button>
                    </>
                )}
            </div>
        </form>
    );
};

export default PhyScoreForm;
