import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import ScanApi from 'src/services/end-points/scan-api';
import { Scan } from 'src/types/scan';

export const scanList = createAsyncThunk<Paginated<Scan>, QueryParams, ThunkConfig>(
    'scan/list',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scan } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            scan,
            async () =>
                ScanApi.list({
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            scan.data,
        );
    },
);

export const getScan = createAsyncThunk<Scan, Scan['id'], ThunkConfig>('scan/get', async (payload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { scan } = getState();

    return resolveApiCall(thunkAPI, scan, async () => {
        const response = await ScanApi.get(payload);
        return response.data;
    });
});

export const updateScan = createAsyncThunk<Scan, { id: string } & Partial<Scan>, ThunkConfig>(
    'scan/update',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scan } = getState();

        return resolveApiCall(
            thunkAPI,
            scan,
            async () => {
                const response = await ScanApi.update(id, payload);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);
