import React, { FC, useEffect } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { AppDispatch } from 'src/store/configure-store';
import { scanConditionLoadingSelector } from 'src/store/selectors/scan-condition-selectors';
import { createScanCondition } from 'src/store/thunks/scan-condition-thunks';
import ScanConditionForm, {
    ScanConditionFormData,
} from 'src/screens/Scan/screens/Condition/components/ScanConditionForm';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getScan } from 'src/store/thunks/scan-thunks';
import { useDispatch } from 'src/hooks/dispatch';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';

interface Props {}

const ScanConditionCreateScreen: FC<Props> = () => {
    const dispatch = useDispatch();
    const loading = useSelector(scanConditionLoadingSelector);
    const { id } = useParams<{ id: string }>();
    const scan = useSelector(scanCurrentSelector);

    const handleSubmit = async (data: ScanConditionFormData, dispatch: AppDispatch) => {
        await dispatch(createScanCondition({ id, ...data })).unwrap();
        toast.success('Data was saved');
    };

    useEffect(() => {
        if (scan?.id !== id) {
            dispatch(getScan(id));
        }
    }, [id]);

    return (
        <MainLayout>
            <ContentHeader
                title="Create scan condition"
                breadcrumbs={[
                    { title: 'Sessions', link: Router.generate(routes.SCAN_LIST) },
                    { title: `${scan?.name}`, link: Router.generate(routes.SCAN_VIEW, { id }) },
                    { title: `Session Conditions`, link: Router.generate(routes.SCAN_VIEW, { id }) },
                ]}
            />
            <Content>
                <ScanConditionForm title="Create" onSubmit={handleSubmit} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default ScanConditionCreateScreen;
