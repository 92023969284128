import { Paginated } from 'src/services/api-handlers/pagination';
import { Column } from 'src/components/react-table/Grid';
import React, { FC, useCallback } from 'react';
import { BodyPart } from 'src/types/body-part';
import { QueryParams } from 'src/types/grid';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import BodyPartsActionsColumn from 'src/screens/BodyPart/components/BodyPartActionsColumn';
import SortableGrid from 'src/components/react-table/SortableGrid';
import { useDispatch } from 'src/hooks/dispatch';
import { updateBodyPart } from 'src/store/thunks/body-part-thunks';

type Props = {
    data: Paginated<BodyPart>;
    getData: (params: QueryParams) => void;
};

const bodyPartColumns: Column<BodyPart>[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Note',
        accessor: 'note',
        disableFilters: true,
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <BodyPartsActionsColumn {...props} actions={['view', 'edit', 'children']} />,
        disableSortBy: true,
        disableFilters: true,
        width: '15%',
    },
];

const BodyPartList: FC<Props> = ({ data, getData }: Props) => {
    const dispatch = useDispatch();

    const updatePosition = useCallback(async (item: BodyPart, position: number) => {
        await dispatch(updateBodyPart({ id: item.id, position })).unwrap();
    }, []);

    return (
        <SortableGrid<BodyPart>
            columns={bodyPartColumns}
            data={data}
            getData={getData}
            defaultSorting={{ position: 'ASC' }}
            onDrop={updatePosition}
            refetchOnDrop={true}
        />
    );
};

export default BodyPartList;
