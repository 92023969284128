import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import { Group, Mesh, Object3D } from 'three';
import useModel from 'src/hooks/use-model';

export const BODY_OBJECT_NAME = 'bodyOuter';

interface Props {
    path?: string;
    onLoaded?: (obj: Group) => void | any;
}

const LoadedModel: React.FC<Props> = observer(({ path, onLoaded }: Props) => {
    const { modelScene } = useStores<Stores>();
    const scene = useModel(path);

    const toggleWireframe = useCallback((items: any[], value: boolean) => {
        for (const item of items) {
            item.material.wireframe = value;
            item.material.wireframeLinewidth = 0.1;
        }
    }, []);

    useEffect(() => {
        modelScene.scene?.children?.forEach((item: any) =>
            item instanceof Mesh
                ? toggleWireframe([item], modelScene.wireframeMode)
                : toggleWireframe(item.children, modelScene.wireframeMode),
        );
    }, [modelScene.scene?.children, modelScene.wireframeMode]);

    useEffect(() => {
        modelScene.scene?.children?.forEach((item: any) => {
            if (item instanceof Mesh) {
            }
        });
    }, [modelScene.scene?.children, modelScene.wireframeMode]);

    useEffect(() => {
        if (scene) {
            scene.scale.set(1, 1, 1);
            scene.name = BODY_OBJECT_NAME;
            modelScene.setScene(scene as Group);
            onLoaded && onLoaded(scene as Group);
        }
    }, [scene]);

    const renderObject3d = (item: Object3D): React.ReactNode => {
        if (item instanceof Mesh) {
            const { visible, opacity, side } = modelScene.getLayerOptions(item);

            return (
                visible && (
                    <mesh
                        key={item.uuid}
                        {...item}
                        material-transparent={opacity < 1}
                        material-opacity={opacity}
                        material-side={side}
                    />
                )
            );
        }

        return item.children.map(renderObject3d);
    };

    if (!path || !scene) return null;

    return (
        <group key={+new Date()} {...scene}>
            {scene.children.map(renderObject3d)}
        </group>
    );

    // old variant
    // return <>{scene && <primitive object={scene} dispose={null} />}</>;
});

export default LoadedModel;
