import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'body-parts';

const get = (id: string): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/${id}`);

const list = (params: any): AxiosPromise<any> => EndPointService.get(`/${ROUTE}`, params);

const update = (id: string, payload: any): AxiosPromise<any> => EndPointService.patch(`/${ROUTE}/${id}`, payload);

const remove = (id: string): AxiosPromise<any> => EndPointService.delete(`/${ROUTE}/${id}`);

const tree = (): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/tree`);

export default {
    get,
    list,
    update,
    remove,
    tree,
};
