import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import ModelApi from 'src/services/end-points/model-api';
import { Model } from 'src/types/model';
import FilterService from 'src/services/filter';

export const modelList = createAsyncThunk<Paginated<Model>, QueryParams, ThunkConfig>(
    'model/list',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { model } = getState();
        const { filters, sorting = {}, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            model,
            async () =>
                ModelApi.list({
                    ...FilterService.makeFilters(filters),
                    ...SortingService.makeOrder(Object.values(sorting).length ? sorting : { createdAt: 'ASC' }),
                    ...rest,
                }),
            payload.page,
            model.data,
        );
    },
);

export const getModel = createAsyncThunk<Model, Model['id'], ThunkConfig>('model/get', async (payload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { model } = getState();

    return resolveApiCall(thunkAPI, model, async () => {
        const response = await ModelApi.get(payload);
        return response.data;
    });
});
