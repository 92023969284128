import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import Restricted from 'src/components/auth/Restricted';
import userRole from 'src/enumerables/user-role';
import Authenticated from 'src/components/auth/Authenticated';
import { ScanConditionListScreen } from 'src/screens/Scan/screens/Condition/screens/List';
import { ScanConditionCreateScreen } from 'src/screens/Scan/screens/Condition/screens/Create';

const scanConditionRoutes = [
    <Route
        exact
        key={routes.SCAN_CONDITION_LIST}
        path={routes.SCAN_CONDITION_LIST}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ScanConditionListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.SCAN_CONDITION_CREATE}
        path={routes.SCAN_CONDITION_CREATE}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ScanConditionCreateScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default scanConditionRoutes;
