import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { ScanReview } from 'src/types/scan-review';
import ScanReviewApi from 'src/services/end-points/scan-review-api';

export const createScanReview = createAsyncThunk<ScanReview, { scanId: string }, ThunkConfig>(
    'scan-review/create',
    async ({ scanId }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanReview } = getState();

        return resolveApiCall(
            thunkAPI,
            scanReview,
            async () => {
                const response = await ScanReviewApi.create(scanId);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);
