import ModelReviewActionsColumn from 'src/screens/Model/screens/Reviews/components/ModelReviewActionsColumn';
import dateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import { ModelReview } from 'src/types/model-review';
import { QueryParams } from 'src/types/grid';
import React, { FC, memo } from 'react';

type Props = {
    data: Paginated<ModelReview>;
    getData: (params: QueryParams) => void;
};

const modelReviewColumns: Column<ModelReview>[] = [
    {
        Header: 'Comment',
        accessor: 'comment',
        disableFilters: true,
    },
    {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
    },
    {
        Header: 'Approved At',
        accessor: 'approvedAt',
        disableFilters: true,
    },
    {
        Header: 'Approved By',
        accessor: 'approvedBy',
        Cell: ({ value }) => {
            return `${value.firstName} ${value.lastName}`;
        },
        disableFilters: true,
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: dateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <ModelReviewActionsColumn {...props} actions={['view', 'edit']} />,
        disableSortBy: true,
        disableFilters: true,
        width: '10%',
    },
];

const ModelList: FC<Props> = ({ data, getData }: Props) => {
    return (
        <Grid<ModelReview>
            columns={modelReviewColumns}
            data={data}
            getData={getData}
            defaultSorting={{ createdAt: 'DESC' }}
        />
    );
};

export default memo(ModelList);
