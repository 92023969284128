import { useEffect, useMemo, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { isGltf, isObj } from '../helpers/model';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { Group } from 'three';

const useModel = (url?: string) => {
    const [obj, setObj] = useState<Group | null>(null);

    const loader = useMemo<GLTFLoader | OBJLoader | null | any>(() => {
        if (!url) return null;

        switch (true) {
            case isGltf(url):
                return GLTFLoader;

            case isObj(url):
                return OBJLoader;

            default:
                return null;
        }
    }, [url]);

    useEffect(() => {
        if (url && loader) {
            new loader().load(url, (model: any) => {
                if (model instanceof Group) {
                    setObj(model);
                } else {
                    setObj(model.scene);
                }
            });
        }
    }, [url]);

    return obj;
};

export default useModel;
