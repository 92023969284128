import React, { createRef, FC, useCallback, useEffect, useRef, useState } from 'react';

type CountUpProps = {
    amount: string;
    className?: string;
    duration?: number;
};

const CountUp: FC<CountUpProps> = ({ amount, duration, className }) => {
    const [, setCount] = useState(0);
    const amountValue = +amount.replace(/\D+/g, '');
    const counterRef = useRef(0);
    const targetRef = createRef<HTMLParagraphElement>();

    const counterAnimation = useCallback((targetRef: any) => {
        if (!targetRef || targetRef.classList.contains('-animated')) return;

        const rect = targetRef.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;
        const isVisible = elemTop < window?.innerHeight && elemBottom >= 0;

        if (isVisible) {
            targetRef.classList.add('-animated');
            const timerFn = () => {
                if (counterRef.current < amountValue) {
                    counterRef.current = counterRef.current + 1;
                    setCount(counterRef.current);
                }

                if (counterRef.current >= amountValue) clearInterval(timerInterval);
            };

            const timerInterval = setInterval(timerFn, Math.trunc(((duration ?? 1) * 1000) / amountValue));
        }
    }, []);

    useEffect(() => {
        counterAnimation(targetRef?.current);
    }, []);

    return (
        <span ref={targetRef} className={className}>
            {amount}
        </span>
    );
};

export default CountUp;
