import React from 'react';

interface Props {}

const AMBIENT_LIGHT_COLOR = 0xffffff;
const AMBIENT_LIGHT_INTENSITY = 0.35;

const POINT_LIGHT_COLOR = 0xffffff;
const POINT_LIGHT_INTENSITY = 0.25;

const Light: React.FC<Props> = ({}: Props) => {
    return (
        <React.Fragment>
            <ambientLight color={AMBIENT_LIGHT_COLOR} intensity={AMBIENT_LIGHT_INTENSITY} />
            <pointLight position={[0, 10, 10]} color={POINT_LIGHT_COLOR} intensity={POINT_LIGHT_INTENSITY} />
            <pointLight position={[0, -10, -10]} color={POINT_LIGHT_COLOR} intensity={POINT_LIGHT_INTENSITY} />
        </React.Fragment>
    );
};

export default Light;
