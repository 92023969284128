import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'reviews';

const list = (modelId: string, params: any): AxiosPromise<any> =>
    EndPointService.get(`models/${modelId}/${ROUTE}`, params);

const get = (modelId: string, reviewId: string): AxiosPromise<any> =>
    EndPointService.get(`models/${modelId}/${ROUTE}/${reviewId}`);

const create = (modelId: string, payload: any): AxiosPromise<any> =>
    EndPointService.post(`models/${modelId}/${ROUTE}`, payload);

const update = (modelId: string, reviewId: string, payload: any): AxiosPromise<any> =>
    EndPointService.patch(`models/${modelId}/${ROUTE}/${reviewId}`, payload);

export default {
    get,
    list,
    create,
    update,
};
