import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'src/hooks/dispatch';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';
import { AppDispatch } from 'src/store/configure-store';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import { getScanPhyScore, updateScanPhyScore } from 'src/store/thunks/scan-phy-score-thunks';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useSelector } from 'react-redux';
import PhyScoreForm, { PhyScoresFormData } from 'src/screens/Scan/screens/PhyScore/components/PhyScoreForm';
import { scanPhyScoreCurrentSelector, scanPhyScoreLoadingSelector } from 'src/store/selectors/scan-phy-score-selectors';
import { getScan } from 'src/store/thunks/scan-thunks';
import ScanTabs from 'src/screens/Scan/components/ScanTabs';

interface PhyScoreListProps {}

interface PhyRouteParams {
    id: string;
}

const PhyScoreScreen: FC<PhyScoreListProps> = () => {
    const { id } = useParams<PhyRouteParams>();
    const dispatch = useDispatch();
    const scan = useSelector(scanCurrentSelector);
    const loading = useSelector(scanPhyScoreLoadingSelector);
    const scanPhyScore = useSelector(scanPhyScoreCurrentSelector);

    const handleSubmit = async (data: PhyScoresFormData, dispatch: AppDispatch) => {
        await dispatch(updateScanPhyScore({ ...data, id })).unwrap();
    };

    useEffect(() => {
        if (scan?.id !== id) {
            dispatch(getScan(id));
        }
        dispatch(getScanPhyScore(id));
    }, [id]);

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getScanPhyScore(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.error);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title={`PHY Scores`}
                    breadcrumbs={[
                        { title: 'Sessions', link: Router.generate(routes.SCAN_LIST) },
                        {
                            title: scan ? `${scan?.name}` : 'Loading scan...',
                            link: Router.generate(routes.SCAN_VIEW, { id }),
                        },
                    ]}
                />
                <Content loading={!scan || scan.id !== id}>
                    <ScanTabs />
                    {scanPhyScore && (
                        <PhyScoreForm title={`${scan?.name}`} scanPhyScore={scanPhyScore} onSubmit={handleSubmit} />
                    )}
                    {loading && <Loader />}
                </Content>
            </Resource>
        </MainLayout>
    );
};

export default PhyScoreScreen;
