import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';
import { ScanDeviceInfo } from 'src/types/scan-device-info';
import ScanDeviceApi from 'src/services/end-points/scan-device-api';
import { Scan } from 'src/types/scan';

export const getScanDeviceInfo = createAsyncThunk<ScanDeviceInfo, Scan['id'], ThunkConfig>(
    'scan/get-device-info',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanDeviceInfo } = getState();

        return resolveApiCall(thunkAPI, scanDeviceInfo, async () => {
            const response = await ScanDeviceApi.getDeviceInfo(payload);
            return response.data;
        });
    },
);

export const updateScanDeviceInfo = createAsyncThunk<
    ScanDeviceInfo,
    { scanId: string } & Partial<ScanDeviceInfo>,
    ThunkConfig
>('scan/update-device-info', async ({ scanId, ...payload }, thunkAPI) => {
    const { getState } = thunkAPI;
    const { scanDeviceInfo } = getState();

    return resolveApiCall(
        thunkAPI,
        scanDeviceInfo,
        async () => {
            const response = await ScanDeviceApi.updateDeviceInfo(scanId, convertValuesToObject(payload));
            return response.data;
        },
        async (err) => makeFormErrorsFromResponse(err.response.data),
    );
});
