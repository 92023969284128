import React, { FC, memo, SyntheticEvent, useCallback } from 'react';
import classNames from 'classnames';
import styles from '../assets/model-info.module.scss';
import { DATETIME_FORMAT } from 'src/config/env';
import moment from 'moment';
import { Model } from 'src/types/model';
import modelQuality from 'src/enumerables/model-quality';
import modelStep from 'src/enumerables/model-step';
import modelPose from 'src/enumerables/model-pose';

interface Props {
    model: Model;
}

const ModelInfo: FC<Props> = ({ model }: Props) => {
    const modelCreatedAt = moment(model.createdAt).format(DATETIME_FORMAT);

    /**
     * To prevent instant downloading of the video, we insert video tag in the opened tab
     */
    const videoClickHandler = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            const openedWindow = window.open('', 'Video');
            if (openedWindow) {
                openedWindow.document.write(
                    `<video src=${model.videoUrl} controls width="100%" height="100%"></video>`,
                );
                openedWindow.document.title = `${model.friendlyName} - video`;
                openedWindow.document.close();
            }
        },
        [model],
    );

    return (
        <div className={classNames('card card-primary card-outline card-outline-tabs')}>
            <div className={classNames('card-header')}>Model: {model.friendlyName}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('table-responsive')}>
                    <table className={classNames('table', styles.tableInfo)}>
                        <tbody>
                            <tr>
                                <th>Id</th>
                                <td>{model.id}</td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{model.name}</td>
                            </tr>
                            <tr>
                                <th>Layout</th>
                                <td>{model.layout}</td>
                            </tr>
                            <tr>
                                <th>Upload</th>
                                <td>{model.upload}</td>
                            </tr>
                            <tr>
                                <th>Quality</th>
                                <td>{modelQuality.getLabel(model.quality)}</td>
                            </tr>
                            <tr>
                                <th>Step</th>
                                <td>{modelStep.getLabel(model.step)}</td>
                            </tr>
                            <tr>
                                <th>Pose</th>
                                <td>{modelPose.getLabel(model.pose)}</td>
                            </tr>
                            <tr>
                                <th>Type</th>
                                <td>{model.type}</td>
                            </tr>
                            <tr>
                                <th>Version</th>
                                <td>{model.version}</td>
                            </tr>
                            <tr>
                                <th>Video</th>
                                <td>
                                    {model.videoUrl ? (
                                        <a href="#" onClick={videoClickHandler}>
                                            View
                                        </a>
                                    ) : (
                                        'No video'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Created at</th>
                                <td>{modelCreatedAt}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default memo(ModelInfo);
