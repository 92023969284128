import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'conditions';

const list = (scanId: string, params: any): AxiosPromise<any> =>
    EndPointService.get(`scans/${scanId}/${ROUTE}`, params);

const create = (scanId: string, payload: any): AxiosPromise<any> =>
    EndPointService.post(`scans/${scanId}/${ROUTE}`, payload);

const remove = (scanId: string, conditionId: string): AxiosPromise<any> =>
    EndPointService.delete(`scans/${scanId}/${ROUTE}/${conditionId}`);

export default {
    list,
    create,
    remove,
};
