import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const conditionStateSelector = (state: StoreState) => state.condition;
export const conditionListSelector = createDraftSafeSelector(conditionStateSelector, (state) => state.data);
export const conditionErrorSelector = createDraftSafeSelector(conditionStateSelector, (state) => state.error);
export const conditionLoadingSelector = createDraftSafeSelector(
    conditionStateSelector,
    (state) => state.loading === 'loading',
);
export const conditionCurrentSelector = createDraftSafeSelector(conditionStateSelector, (state) => state.current);
