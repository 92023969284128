import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faList } from '@fortawesome/free-solid-svg-icons';
import styles from '../assets/actions.module.scss';
import { BodyPart } from 'src/types/body-part';

interface Props {
    bodyPart: BodyPart | null;
}

const Actions: FC<Props> = ({ bodyPart }: Props) => {
    // const deleteCallback = useCallback(() => {
    //     history.push(Router.generate(routes.BODY_PART_LIST));
    // }, []);

    if (!bodyPart) {
        return null;
    }

    return (
        <div className={classNames(styles.actions)}>
            <Link className={classNames('btn', 'btn-primary')} to={Router.generate(routes.BODY_PART_LIST)}>
                <FontAwesomeIcon icon={faList} /> Return to list
            </Link>
            {bodyPart.level === 2 && (
                <Link
                    className={classNames('btn', 'btn-success')}
                    to={Router.generate(routes.BODY_PART_EDIT, { id: bodyPart.id })}
                >
                    <FontAwesomeIcon icon={faEdit} /> Edit
                </Link>
            )}

            {/*<DeleteBodyPartAction bodyPart={bodyParts} successCallback={deleteCallback}>*/}
            {/*    <Button className={classNames('btn-danger')}>*/}
            {/*        <FontAwesomeIcon icon={faTrashAlt} /> Delete*/}
            {/*    </Button>*/}
            {/*</DeleteBodyPartAction>*/}
        </div>
    );
};

export default memo(Actions);
