import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import FieldWrapper from './FieldWrapper';
import { Controller } from 'react-hook-form';
import { DropdownList } from 'react-widgets';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Props as BaseProps } from './FieldDropdown';
import List, { ListProps } from 'react-widgets/List';
import styles from '../assets/field-loading-dropdown.module.scss';

interface Props<T extends any = any> extends BaseProps<T> {
    fetchData: (page: number, searchTerm?: string) => any;
    page: number;
    maxPage: number;
    fetchOnSearch?: boolean;
}

type CustomListProps<T> = ListProps<T> &
    Pick<Props<T>, 'page' | 'maxPage'> & {
        onLoadMore: () => any;
    };

const CustomList = React.forwardRef<any, CustomListProps<any>>(
    ({ page, maxPage, busy, onLoadMore, ...props }: CustomListProps<any>, ref) => {
        return (
            <div>
                <List {...props} ref={ref} />
                {page < maxPage && (
                    <div
                        className={classNames('rw-list-option', styles.loadMore, busy ? styles.disabled : '')}
                        onClick={onLoadMore}
                    >
                        <b>Load more</b>
                    </div>
                )}
            </div>
        );
    },
);

const FieldFetchingDropdown: React.FunctionComponent<Props<any>> = <T extends any = any>({
    control,
    wrapperProps,
    error,
    name,
    placeholder,
    inputClassName,
    invalidInputClassName = 'border-danger',
    filter = 'contains',
    busy = false,
    data,
    disabled,
    textField = 'name',
    valueField = 'value',
    defaultValue,
    groupBy,
    children,
    fetchData,
    page,
    maxPage,
    fetchOnSearch,
}: PropsWithChildren<Props<T>>) => {
    const [prevData, setPrevData] = useState<T[]>(data);
    const [searchTerm, setSearchTerm] = useState('');
    const [isWaitingForSearch, setIsWaitingForSearch] = useState(false);

    const onLoadMore = useCallback(() => {
        fetchData(page + 1, searchTerm);
    }, [page, searchTerm, fetchData]);

    useEffect(() => {
        setPrevData((prev) => (page === 1 ? [...data] : [...prev, ...data]));
    }, [data]);

    useEffect(() => {
        fetchOnSearch && setIsWaitingForSearch(true);
        const shouldFetch = fetchOnSearch; //&& page < maxPage;

        const timer = setTimeout(() => {
            if (shouldFetch) {
                setPrevData([]);
                fetchData(1, searchTerm);

                setIsWaitingForSearch(false);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [searchTerm, fetchOnSearch]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => {
                field = {
                    ...field,
                    selectIcon: <FontAwesomeIcon className={classNames(error && 'text-danger')} icon={faChevronDown} />,
                } as any;

                return (
                    <FieldWrapper {...wrapperProps} name={name} error={error}>
                        <DropdownList<T>
                            containerClassName={classNames(inputClassName, error && invalidInputClassName)}
                            placeholder={placeholder}
                            filter={filter}
                            busy={busy}
                            data={prevData}
                            disabled={disabled}
                            dataKey={valueField}
                            textField={textField}
                            groupBy={groupBy}
                            renderValue={({ text }) => (field.value ? text : '')}
                            {...field}
                            // searchTerm={searchTerm}
                            onSearch={(val, event) => {
                                setSearchTerm(event.action !== 'clear' ? val : '');
                            }}
                            messages={{
                                emptyList: busy || isWaitingForSearch ? 'Fetching items...' : undefined,
                                emptyFilter: busy || isWaitingForSearch ? 'Fetching items...' : undefined,
                            }}
                            listProps={{
                                busy: busy || isWaitingForSearch,
                                page,
                                maxPage,
                                fetchData,
                                fetchOnSearch,
                                onLoadMore,
                            }}
                            listComponent={CustomList}
                        />
                        {children}
                    </FieldWrapper>
                );
            }}
        />
    );
};

export default FieldFetchingDropdown;
