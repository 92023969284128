import { EnumerableAbstract } from './enumerable.abstract';

class ModelReviewStatus extends EnumerableAbstract {
    readonly PENDING = 0;
    readonly APPROVED = 1;
    readonly REJECTED = 2;

    getName = () => 'UserRole';

    defaultChoices = () => ({
        [this.PENDING]: 'Pending',
        [this.APPROVED]: 'Approved',
        [this.REJECTED]: 'Rejected',
    });
}

const modelReviewStatus = new ModelReviewStatus();

export default modelReviewStatus;
