import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import ModelReviewApi from 'src/services/end-points/model-review-api';
import { ModelReview } from 'src/types/model-review';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';

export const modelReviewList = createAsyncThunk<Paginated<ModelReview>, QueryParams & { id: string }, ThunkConfig>(
    'modelReview/list',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelReview } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            modelReview,
            async () =>
                ModelReviewApi.list(id, {
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            modelReview.data,
        );
    },
);

export const createModelReview = createAsyncThunk<ModelReview, { id: string; type: string }, ThunkConfig>(
    'modelReview/create',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelReview } = getState();

        return resolveApiCall(
            thunkAPI,
            modelReview,
            async () => {
                const response = await ModelReviewApi.create(id, convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const getModelReview = createAsyncThunk<ModelReview, { modelId: string; reviewId: string }, ThunkConfig>(
    'modelReview/get',
    async ({ modelId, reviewId }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelReview } = getState();

        return resolveApiCall(thunkAPI, modelReview, async () => {
            const response = await ModelReviewApi.get(modelId, reviewId);
            return response.data;
        });
    },
);

export const updateModelReview = createAsyncThunk<
    ModelReview,
    { id: string; reviewId: string } & Partial<ModelReview>,
    ThunkConfig
>('modelReview/update', async ({ id, reviewId, ...payload }, thunkAPI) => {
    const { getState } = thunkAPI;
    const { modelReview } = getState();

    return resolveApiCall(
        thunkAPI,
        modelReview,
        async () => {
            const response = await ModelReviewApi.update(id, reviewId, payload);
            return response.data;
        },
        async (err) => makeFormErrorsFromResponse(err.response.data),
    );
});
