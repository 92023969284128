import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';
import ScanConditionApi from 'src/services/end-points/scan-condition-api';
import { ScanCondition } from 'src/types/scan-condition';

export const scanConditionList = createAsyncThunk<
    Paginated<ScanCondition>,
    { scanId: string } & QueryParams,
    ThunkConfig
>('scan-condition/list', async ({ scanId, ...payload }, thunkAPI) => {
    const { getState } = thunkAPI;
    const { scanCondition } = getState();
    const { filters, sorting, ...rest } = payload;

    return PaginationService.resolveApiCall(
        thunkAPI,
        scanCondition,
        async () =>
            ScanConditionApi.list(scanId, {
                ...filters,
                ...SortingService.makeOrder(sorting),
                ...rest,
            }),
        payload.page,
        scanCondition.data,
    );
});

export const deleteScanCondition = createAsyncThunk<void, { scanId: string; conditionId: string }, ThunkConfig>(
    'scan-condition/delete',
    async ({ scanId, conditionId }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanCondition } = getState();

        return resolveApiCall(thunkAPI, scanCondition, async () => ScanConditionApi.remove(scanId, conditionId));
    },
);

export const createScanCondition = createAsyncThunk<ScanCondition, { id: string }, ThunkConfig>(
    'scan-condition/create',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanCondition } = getState();

        return resolveApiCall(
            thunkAPI,
            scanCondition,
            async () => {
                const response = await ScanConditionApi.create(id, convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);
