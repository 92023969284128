import React, { useCallback, useEffect } from 'react';
import { Point } from 'src/types/points';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import FieldDropdown from 'src/components/common/form/FieldDropdown';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

interface Props {
    point: Point;
}

const PointView: React.FC<Props> = observer(({ point }: Props) => {
    const { bodyParts: bodyPartsStore } = useStores<Stores>();
    const path = bodyPartsStore.getPath(point.bodyPart.id);
    const { control, setValue } = useForm();

    const labelByIndex = useCallback((i: number) => {
        return ['Body Zone', 'Body Part', 'Body Detail'][i];
    }, []);

    useEffect(() => {
        path.forEach((bodyPart, i) => setValue(`bodyParts[${i}]`, bodyPart));
    }, [path]);

    return (
        <div>
            {path.map((bodyPart, i) => (
                <FieldDropdown
                    key={bodyPart.id}
                    name={`bodyParts[${i}]`}
                    wrapperProps={{ label: labelByIndex(i) }}
                    control={control}
                    data={[bodyPart]}
                    valueField={'id'}
                    textField={'name'}
                    disabled={true}
                />
            ))}
        </div>
    );
});

export default PointView;
