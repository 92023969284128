import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import { BodyPartValidation } from 'src/types/body-part-validation';
import { bodyPartValidationLoadingSelector } from 'src/store/selectors/body-part-validation-selectors';
import { deleteBodyPartValidation } from 'src/store/thunks/body-part-validation-thunks';

interface Props {
    bodyPartValidation: BodyPartValidation;
    successCallback?: () => void;
    children: ReactNode;
}

const DeleteBodyPartValidation: FC<Props> = ({ bodyPartValidation, children, successCallback }: Props) => {
    const dispatch = useDispatch();
    const loading = useSelector(bodyPartValidationLoadingSelector);
    const [showModal, setShowModal] = useState<boolean>(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleDelete = useCallback(async () => {
        await dispatch(deleteBodyPartValidation(bodyPartValidation.id)).unwrap();
        setShowModal(false);
        toast.success(`${bodyPartValidation?.bodyPart?.name} was successfully deleted`);
        successCallback && successCallback();
    }, [bodyPartValidation.id]);

    return (
        <>
            <a href="#" onClick={handleShow}>
                {children}
            </a>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {bodyPartValidation?.bodyPart?.name}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classNames('btn-default')} onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        className={classNames('btn-danger')}
                        onClick={handleDelete}
                        disabled={loading}
                        loading={loading}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteBodyPartValidation;
