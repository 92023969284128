import { EnumerableAbstract } from './enumerable.abstract';

class ModelQuality extends EnumerableAbstract {
    public readonly QUALITY_1 = 1;
    public readonly QUALITY_2 = 2;

    public getName = () => 'ModelQuality';

    public defaultChoices = () => ({
        [this.QUALITY_1]: 'Quality 1',
        [this.QUALITY_2]: 'Quality 2',
    });
}

const modelQuality = new ModelQuality();

export default modelQuality;
