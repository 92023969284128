import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import userRole from 'src/enumerables/user-role';
import Authenticated from 'src/components/auth/Authenticated';
import Restricted from 'src/components/auth/Restricted';
import { ScanListScreen } from 'src/screens/Scan/screens/List';
import { ScanViewScreen } from 'src/screens/Scan/screens/View';
import { ModelListScreen } from 'src/screens/Scan/screens/Model';

const scanRoutes = [
    <Route
        exact
        key={routes.SCAN_LIST}
        path={routes.SCAN_LIST}
        render={() => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <ScanListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.SCAN_VIEW}
        path={routes.SCAN_VIEW}
        render={(props) => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <ScanViewScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.SCAN_MODEL_LIST}
        path={routes.SCAN_MODEL_LIST}
        render={() => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <ModelListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default scanRoutes;
