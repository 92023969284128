import React from 'react';
import { EventHandlers } from 'react-three-fiber';
import { Mesh } from 'three';

export interface Props {
    x: number;
    y: number;
    z: number;
    config?: Partial<{
        outerSphereRadius: number;
        outerSphereWidthSegments: number;
        outerSphereHeightSegments: number;
        outerSphereOpacity: number;
        outerSphereColor: number;
        innerSphereRadius: number;
        innerSphereWidthSegments: number;
        innerSphereHeightSegments: number;
        innerSphereColor: number;
    }>;
    onClick?: EventHandlers['onClick'];
    ref?: React.Ref<Mesh>;
}

const OUTER_SPHERE_RADIUS = 0.008;
const OUTER_SPHERE_WIDTH_SEGMENTS = 16;
const OUTER_SPHERE_HEIGHT_SEGMENTS = 8;
const OUTER_SPHERE_OPACITY = 0.4;
const OUTER_SPHERE_COLOR = 0xff0000;

const INNER_SPHERE_RADIUS = 0.004;
const INNER_SPHERE_WIDTH_SEGMENTS = 12;
const INNER_SPHERE_HEIGHT_SEGMENTS = 6;
const INNER_SPHERE_COLOR = 0xffffff;

const Point: React.FC<Props> = ({ x, y, z, ref, onClick, config = {} }: Props) => {
    const {
        outerSphereRadius = OUTER_SPHERE_RADIUS,
        outerSphereWidthSegments = OUTER_SPHERE_WIDTH_SEGMENTS,
        outerSphereHeightSegments = OUTER_SPHERE_HEIGHT_SEGMENTS,
        outerSphereOpacity = OUTER_SPHERE_OPACITY,
        outerSphereColor = OUTER_SPHERE_COLOR,
        innerSphereRadius = INNER_SPHERE_RADIUS,
        innerSphereWidthSegments = INNER_SPHERE_WIDTH_SEGMENTS,
        innerSphereHeightSegments = INNER_SPHERE_HEIGHT_SEGMENTS,
        innerSphereColor = INNER_SPHERE_COLOR,
    } = config;
    return (
        <mesh onClick={onClick} ref={ref}>
            <mesh position={[x, y, z]}>
                <sphereBufferGeometry args={[outerSphereRadius, outerSphereWidthSegments, outerSphereHeightSegments]} />
                <meshBasicMaterial color={outerSphereColor} transparent={true} opacity={outerSphereOpacity} />
            </mesh>
            <mesh position={[x, y, z]}>
                <sphereBufferGeometry args={[innerSphereRadius, innerSphereWidthSegments, innerSphereHeightSegments]} />
                <meshBasicMaterial color={innerSphereColor} wireframe={true} />
            </mesh>
        </mesh>
    );
};

export default Point;
