import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const scanReviewStateSelector = (state: StoreState) => state.scanReview;
export const scanReviewErrorSelector = createDraftSafeSelector(scanReviewStateSelector, (state) => state.error);
export const scanReviewLoadingSelector = createDraftSafeSelector(
    scanReviewStateSelector,
    (state) => state.loading === 'loading',
);
export const scanReviewCurrentSelector = createDraftSafeSelector(scanReviewStateSelector, (state) => state.current);
