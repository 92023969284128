import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { createScanCondition, deleteScanCondition, scanConditionList } from 'src/store/thunks/scan-condition-thunks';
import { crudHelpers, takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { ScanCondition } from 'src/types/scan-condition';

export interface ScanConditionState extends ReducerState {
    data: Paginated<ScanCondition>;
    current: ScanCondition | null;
}

const initialState: ScanConditionState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'scanCondition',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(scanConditionList.pending, takeEvery.pendingActionCase)
            .addCase(scanConditionList.rejected, takeEvery.rejectedActionCase)
            .addCase(scanConditionList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(createScanCondition.pending, takeOne.pendingActionCase)
            .addCase(createScanCondition.rejected, takeOne.rejectedActionCase)
            .addCase(createScanCondition.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
            })
            .addCase(deleteScanCondition.pending, takeOne.pendingActionCase)
            .addCase(deleteScanCondition.rejected, takeOne.rejectedActionCase)
            .addCase(deleteScanCondition.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, (store, action) => {
                    const deletedId = action.meta.arg.conditionId;
                    if (store.current && store.current.id === deletedId) {
                        store.current = null;
                    }
                    const filtered = store.data?.list.filter(({ id }) => id !== deletedId);

                    if (store.data && filtered && store.data.list.length > filtered.length) {
                        store.data.list = filtered;
                        store.data.totalCount = (store.data.totalCount as number) - 1;
                    }
                });
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
