import React, { useCallback } from 'react';
import PointComponent from './Point';
import useStores from '../../../../hooks/use-store';
import { Stores } from 'src/stores';
import { Point } from 'src/types/points';
import { CreatedPoint } from 'src/stores/abstract-scene-store';
import { observer } from 'mobx-react';

interface Props {}

const CreatedPoints: React.FC<Props> = observer((props: Props) => {
    const { imageScene } = useStores<Stores>();

    const pointClickHandler = useCallback((point: CreatedPoint<Point>) => {
        imageScene.removeCreatedPoint(point);
    }, []);

    return (
        <React.Fragment>
            {imageScene.createdPoints.map((point) => (
                <PointComponent key={point.id} {...point.coordinates} onClick={() => pointClickHandler(point)} />
            ))}
        </React.Fragment>
    );
});

export default CreatedPoints;
