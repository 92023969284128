import React, { useMemo } from 'react';
import securityService from '../../security/security';

interface Props {
    actions: string[];
    children: any;
}

const Authorized: React.FC<Props> = ({ actions, children }: Props) => {
    const isGranted = useMemo<boolean>(() => {
        for (const action of actions) {
            if (securityService.isGranted(action)) {
                return true;
            }
        }

        return false;
    }, [actions]);

    return isGranted ? children : null;
};

export default Authorized;
