import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'scans';

const getDeviceInfo = (id: string): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/${id}/device-info`);

const updateDeviceInfo = (id: string, payload: any): AxiosPromise<any> =>
    EndPointService.patch(`/${ROUTE}/${id}/device-info`, payload);

export default {
    updateDeviceInfo,
    getDeviceInfo,
};
