import React, { useEffect } from 'react';
import { useThree } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { PerspectiveCamera } from 'three';

interface Props {
    coordinates?: [number, number, number];
    position?: [number, number, number];
    fov?: number;
    aspect?: number;
    near?: number;
    far?: number;
}

const COORDINATES = [0, 1, 0] as [number, number, number];
const POSITION = [0, 1.6, 2] as [number, number, number];
const FOV = 50;
const ASPECT = 1;
const NEAR = 0.1;
const FAR = 2000;

const Camera: React.FC<Props> = (props: Props) => {
    const {
        coordinates = COORDINATES,
        position = POSITION,
        far = FAR,
        fov = FOV,
        near = NEAR,
        aspect = ASPECT,
    } = props;
    const { camera, gl } = useThree();

    useEffect(() => {
        Object.assign(camera as PerspectiveCamera, { far, fov, near, aspect });
        camera.position.set(...position);

        const controls = new OrbitControls(camera, gl.domElement);
        controls.target.set(...coordinates);
        controls.update();

        return () => {
            controls.dispose();
        };
    }, [camera, gl]);

    return null;
};

export default Camera;
