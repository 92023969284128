import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import {
    modelPointList,
    modelPointDelete,
    modelPointUpdate,
    modelPointCreate,
} from 'src/store/thunks/model-point-thunks';
import { crudHelpers, takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { Point } from 'src/types/model-point';

export interface ModelPointState extends ReducerState {
    data: Paginated<Point>;
    current: Point | null;
}

const initialState: ModelPointState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'modelPoint',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(modelPointList.pending, takeEvery.pendingActionCase)
            .addCase(modelPointList.rejected, takeEvery.rejectedActionCase)
            .addCase(modelPointList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })
            .addCase(modelPointCreate.pending, takeOne.pendingActionCase)
            .addCase(modelPointCreate.rejected, takeOne.rejectedActionCase)
            .addCase(modelPointCreate.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
            })
            .addCase(modelPointUpdate.pending, takeOne.pendingActionCase)
            .addCase(modelPointUpdate.rejected, takeOne.rejectedActionCase)
            .addCase(modelPointUpdate.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            })
            .addCase(modelPointDelete.pending, takeOne.pendingActionCase)
            .addCase(modelPointDelete.rejected, takeOne.rejectedActionCase)
            .addCase(modelPointDelete.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterDelete);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
