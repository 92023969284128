import React, { FC, memo } from 'react';
import { CellProps } from 'react-table';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import styles from '../assets/model-point-actions-column.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import routes from 'src/navigation/routes';
import { Model } from 'src/types/model';

type Action = 'points';

interface Props extends CellProps<Model> {
    actions: Action[];
}

const ModelActionsColumn: FC<Props> = memo(({ cell: { row }, actions }: Props) => {
    return (
        <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
            {actions.includes('points') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="model-points">Points</Tooltip>}>
                    <Link
                        to={Router.generate(routes.MODEL_POINT_LIST, { id: row.original?.id })}
                        className={classNames(['text-decoration-none', styles.actionLink])}
                    >
                        <FontAwesomeIcon icon={faDotCircle} color="#0d6efd" /> Points
                    </Link>
                </OverlayTrigger>
            )}
        </div>
    );
});

export default ModelActionsColumn;
