import { action, IObservableArray, makeObservable, observable } from 'mobx';
import { Point } from '../types/points';
import ConfigStore from './config.store';

export type CreatedPoint<T extends Point> = Pick<T, 'id' | 'coordinates'>;

export abstract class AbstractSceneStore<T extends Point = Point> {
    @observable
    public config: Record<string, any> = {};
    @observable
    public createMode = false;
    @observable
    public activePoint: Point | null = null;
    @observable
    readonly createdPoints: IObservableArray<CreatedPoint<T>> = observable([]);
    @observable
    public highlightCriteria: Partial<Record<keyof Point, any>> = {};

    constructor(config: ConfigStore) {
        this.config = config;
        makeObservable(this);
    }

    @action
    public setCreateMode(value: boolean) {
        this.createMode = value;
    }

    @action
    public setActivePoint(point: Point | null) {
        this.activePoint = point;
    }

    @action
    public addCreatedPoint(point: CreatedPoint<T>) {
        console.log('POINT ADDED', point);
        this.createdPoints.push(point);
    }

    @action
    public removeCreatedPoint(point: CreatedPoint<T>) {
        this.createdPoints.remove(point as CreatedPoint<T>);
    }

    @action
    public setHighlightCriteria<T extends keyof Point>(name: T, value: Point[T]) {
        this.highlightCriteria[name] = value;
    }

    @action
    public clearHighlightCriteria<T extends keyof Point>(name?: T) {
        if (name) {
            this.highlightCriteria[name] = null;
        } else {
            this.highlightCriteria = {};
        }
    }

    public isHighlighted(point: Point | CreatedPoint<T> | any) {
        if (!Object.keys(this.highlightCriteria).length) {
            return false;
        }

        for (const key in this.highlightCriteria) {
            const typedKey = key as keyof Point;
            if (this.highlightCriteria[typedKey] !== point[typedKey]) {
                return false;
            }
        }

        return true;
    }

    @action
    public clearCreatedPoints() {
        this.createdPoints.clear();
    }

    @action
    public reset() {
        this.activePoint = null;
        this.createMode = false;
        this.clearCreatedPoints();
        this.clearHighlightCriteria();
    }
}
