import React from 'react';
import BaseChangePasswordForm, {
    BaseChangePasswordFormData,
} from 'src/screens/Profile/components/BaseChangePasswordForm';
import UserAuthService from 'src/services/user-auth';
import { useDispatch } from 'src/hooks/dispatch';
import { updateStaff } from 'src/store/thunks/staff-thunks';

interface Props {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChangePasswordForm: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const handleSubmit = async (data: BaseChangePasswordFormData) => {
        await dispatch(updateStaff({ id: UserAuthService.getId() as string, ...data })).unwrap();
    };

    return <BaseChangePasswordForm onSubmit={handleSubmit} />;
};

export default ChangePasswordForm;
