import React from 'react';
import classNames from 'classnames';
import { Staff } from 'src/types/staff';
import staffRole from 'src/enumerables/user-role';
import moment from 'moment';
import { DATETIME_FORMAT } from 'src/config/env';
import styles from '../assets/staff-info.module.scss';

interface Props {
    staff: Staff;
}

const StaffInfo: React.FunctionComponent<Props> = ({ staff }: Props) => {
    return (
        <div className={classNames('table-responsive')}>
            <table className={classNames('table', styles.tableInfo)}>
                <tbody>
                    <tr>
                        <th>ID:</th>
                        <td>{staff.id}</td>
                    </tr>
                    <tr>
                        <th>First Name:</th>
                        <td>{staff.firstName}</td>
                    </tr>
                    <tr>
                        <th>Last Name:</th>
                        <td>{staff.lastName}</td>
                    </tr>
                    <tr>
                        <th>Email:</th>
                        <td>{staff.email}</td>
                    </tr>
                    <tr>
                        <th>Role:</th>
                        <td>{staff.roles.map((val) => staffRole.getLabel(val)).join(', ')}</td>
                    </tr>
                    <tr>
                        <th>Created At:</th>
                        <td>{moment(staff.createdAt).format(DATETIME_FORMAT)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default React.memo(StaffInfo);
