import React from 'react';

interface Props {
    if: any;
    children: React.ReactNode;
}

const Visible: React.FC<Props> = ({ if: condition, children }: Props) => {
    if (!Boolean(condition)) {
        return null;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export default Visible;
