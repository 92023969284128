import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const statisticStateSelector = (state: StoreState) => state.statistic;
export const scanStatisticSelector = createDraftSafeSelector(statisticStateSelector, (state) => state.scanStatistic);
export const modelsStatisticSelector = createDraftSafeSelector(
    statisticStateSelector,
    (state) => state.modelsStatistic,
);
export const statisticErrorSelector = createDraftSafeSelector(statisticStateSelector, (state) => state.error);
export const statisticLoadingSelector = createDraftSafeSelector(
    statisticStateSelector,
    (state) => state.loading === 'loading',
);
