import React, { ChangeEvent, FC, memo, useCallback, useRef, useState } from 'react';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../assets/editable-row.module.scss';
import { Form, Overlay, Popover } from 'react-bootstrap';
import classNames from 'classnames';

interface Props {
    value: number;
    text?: string;
    placement:
        | 'auto-start'
        | 'auto'
        | 'auto-end'
        | 'top-start'
        | 'top'
        | 'top-end'
        | 'right-start'
        | 'right'
        | 'right-end'
        | 'bottom-end'
        | 'bottom'
        | 'bottom-start'
        | 'left-end'
        | 'left'
        | 'left-start';
    onSubmit: (data: number) => Promise<boolean>;
    data: SelectableItem[];
}

const EditableSelectColumn: FC<Props> = ({ value, text, placement, onSubmit, data }: Props) => {
    const [selectedValue, setSelectedValue] = useState<number>(value);
    const [show, setShow] = useState<boolean>(false);
    const target = useRef(null);

    const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(Number(e.target.value));
    }, []);

    const onCancel = useCallback(() => setShow(false), []);

    const onSelectSubmit = async () => {
        try {
            const res = await onSubmit(selectedValue);
            if (res) setShow(false);
        } catch (e: any) {
            console.error(e);
        }
    };

    return (
        <div>
            <div onClick={() => setShow(true)} ref={target} className={classNames(styles.cursorPointerRow)}>
                {text || value}
            </div>
            <Overlay show={show} target={target.current} placement={placement} rootClose onHide={onCancel}>
                <Popover id="popover-contained" className={styles.popover}>
                    <Popover.Content className="d-flex align-items-center justify-content-space-evenly">
                        <Form.Group>
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedValue}
                                className={classNames(styles.formControl)}
                                onChange={onChange}
                            >
                                {data.map(({ value, name }) => (
                                    <option key={value} value={value}>
                                        {name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <div className={classNames('mt-3 ml-2', styles.btnWrap)}>
                            <button
                                type={'button'}
                                className={classNames('btn', 'btn-sm', 'btn-success', styles.btn)}
                                onClick={onSelectSubmit}
                            >
                                <FontAwesomeIcon color="#fff" icon={faCheck} />
                            </button>
                            <button
                                type={'button'}
                                className={classNames('btn', 'btn-sm', 'btn-danger', styles.btn)}
                                onClick={onCancel}
                            >
                                <FontAwesomeIcon icon={faTimes} color="#fff" />
                            </button>
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
};

export default memo(EditableSelectColumn);
