import React, { FC, useCallback, useEffect, useState } from 'react';
import Content from 'src/components/layout/main/Content';
import DashboardModelsChart from 'src/screens/Dashboard/components/DashboardModelsChart';
import DashboardInfoBox from 'src/screens/Dashboard/components/DashboardInfoBox';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import styles from './assets/dashboard-card.module.scss';
import MainLayout from 'src/components/layout/MainLayout';
import classNames from 'classnames';
import { useDispatch } from 'src/hooks/dispatch';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { scanStatisticSelector, statisticLoadingSelector } from 'src/store/selectors/statistic-selectors';
import { getModelsStatistic, getScanStatistic } from 'src/store/thunks/statistic-thunks';
import moment from 'moment';
import { DATE_ISO_8601_FORMAT, MONTH_DAY_YEAR_FORMAT } from 'src/config/env';
import { ModelsStatistic } from 'src/types/statistic';

interface Props {}

const DashboardScreen: FC<Props> = ({}: Props) => {
    const dispatch = useDispatch();
    const scanStatistic = useSelector(scanStatisticSelector);
    const loading = useSelector(statisticLoadingSelector);
    const [modelsStatistic, setModelsStatistic] = useState<ModelsStatistic[]>([]);
    const [approvedModelsStatistic, setApprovedModelsStatistic] = useState<ModelsStatistic[]>([]);
    const endDate = moment().format(DATE_ISO_8601_FORMAT);
    const startDate = moment().subtract(3, 'months').format(DATE_ISO_8601_FORMAT);

    const fetchStatistic = useCallback(async () => {
        const modelsStatisticData = await dispatch(getModelsStatistic({ startDate, endDate, isApproved: 0 })).unwrap();
        const approvedModelsStatisticData = await dispatch(
            getModelsStatistic({ startDate, endDate, isApproved: 1 }),
        ).unwrap();
        setModelsStatistic(
            modelsStatisticData.map((statistic) => ({
                ...statistic,
                createdAt: moment(statistic.createdAt).format(MONTH_DAY_YEAR_FORMAT),
            })),
        );
        setApprovedModelsStatistic(
            approvedModelsStatisticData.map((statistic) => ({
                ...statistic,
                createdAt: moment(statistic.createdAt).format(MONTH_DAY_YEAR_FORMAT),
            })),
        );
    }, []);

    useEffect(() => {
        fetchStatistic();
    }, []);

    useEffect(() => {
        dispatch(getScanStatistic());
    }, []);

    return (
        <MainLayout>
            <ContentHeader title="Dashboard" />
            <Content>
                <div className={classNames('row justify-content-center')}>
                    <DashboardInfoBox
                        amountText={'New Approved Models'}
                        amount={String(scanStatistic?.newApprovedModels)}
                        variant="info"
                        helpText={'Amount of newly point-picked and approved models for today'}
                        loading={loading}
                    />
                    <DashboardInfoBox
                        amountText={'New sessions'}
                        amount={String(scanStatistic?.newSessions)}
                        variant="success"
                        loading={loading}
                        helpText={'Amount of uploaded sessions from Aemass for today'}
                    />
                    <DashboardInfoBox
                        amountText={'Approved Sessions'}
                        amount={String(scanStatistic?.reviewedSessions)}
                        variant="warning"
                        loading={loading}
                        helpText={'Amount of fully approved sessions (with scores) for today'}
                    />
                </div>
                {(modelsStatistic.length > 0 || approvedModelsStatistic.length > 0) && (
                    <div className="card">
                        <div className={classNames(`card-body`, styles.dashboardCard)}>
                            <>
                                <DashboardModelsChart chartTitle={'New Models'} statisticData={modelsStatistic} />
                                <DashboardModelsChart
                                    chartTitle={'Approved models'}
                                    statisticData={approvedModelsStatistic}
                                />
                            </>
                            {loading && <Loader />}
                        </div>
                    </div>
                )}
            </Content>
        </MainLayout>
    );
};

export default DashboardScreen;
