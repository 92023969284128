import general from './routes/general';
import auth from './routes/auth';
import staff from './routes/staff';
import bodyPart from './routes/body-part';
import bodyPartValidation from './routes/body-part-validation';
import scanScore from './routes/scan-score';
import scan from './routes/scan';
import model from './routes/model';
import conditions from './routes/condition';
import scanConditions from './routes/scan-condition';
import phyScore from './routes/phy-score';
import routes from './routes';
import phoneVerification from './routes/phone-verification';

const routesList = [
    ...general,
    ...auth,
    ...staff,
    ...bodyPart,
    ...bodyPartValidation,
    ...scanConditions,
    ...conditions,
    ...scan,
    ...model,
    ...scanScore,
    ...phyScore,
    ...phoneVerification,
];

export { routesList };
export { routes };
export { default as history } from './history';
