import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginFormData } from 'src/screens/Auth/screens/Login/components/LoginForm';
import { ThunkConfig } from 'src/store/configure-store';
import { AuthApi } from 'src/services/end-points';
import UserAuthService from 'src/services/user-auth';
import { push } from 'connected-react-router';
import Router from 'src/navigation/router';
import routes from 'src/navigation/routes';
import { makeFormErrors, makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { resolveApiCall, toastErrorCallback } from '../../services/api-handlers/api-resolver';
import { ResourceCallbacks } from '../../components/Resource';
import { VerifyFormData } from '../../screens/Auth/screens/VerifyCode/components/VerifyCodeForm';

export const login = createAsyncThunk<void, LoginFormData, ThunkConfig>('auth/login', async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
        const { data } = await AuthApi.login(payload.username, payload.password);
        await UserAuthService.login(data.token, data.refreshToken);
        dispatch(push(Router.generate(routes.HOME)));
    } catch (err) {
        const { response } = err as any;

        return thunkAPI.rejectWithValue(
            makeFormErrors<LoginFormData>({
                _error: response?.status < 500 ? 'Incorrect email or password' : 'Something went wrong',
            }),
        );
    }
});

export const logout = createAsyncThunk<void, void, ThunkConfig>('auth/logout', async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    await UserAuthService.logout();
    dispatch(push(Router.generate(routes.HOME)));
});

export const sendCode = createAsyncThunk<void, ResourceCallbacks, ThunkConfig>(
    'auth/send-code',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { auth } = getState();
        const { success, failure } = payload;

        return resolveApiCall(
            thunkAPI,
            auth,
            async () => {
                const { data } = await AuthApi.sendCode();
                success && (await success(data));

                return data;
            },
            async (err) => failure && (await failure(err)),
        );
    },
);

export const verifyCode = createAsyncThunk<void, VerifyFormData, ThunkConfig>(
    'auth/verify-code',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { auth } = getState();

        return resolveApiCall(
            thunkAPI,
            auth,
            async () => {
                const {
                    data: { token, refreshToken },
                } = await AuthApi.verifyCode(payload);
                await UserAuthService.login(token, refreshToken);
            },
            async (err) => {
                if (err.response?.status === 401) {
                    await UserAuthService.logout();
                    push(Router.generate(routes.HOME));
                    toastErrorCallback(err);

                    return;
                }
                return makeFormErrorsFromResponse(err.response.data);
            },
        );
    },
);
