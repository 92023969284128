import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import Authenticated from '../../components/auth/Authenticated';
import ConfirmPhoneScreen from 'src/screens/PhoneVerification/screens/ConfirmPhoneScreen';
import VerifyPhoneScreen from 'src/screens/PhoneVerification/screens/VerifyPhoneScreen';

const phoneVerificationRoutes = [
    <Route
        exact
        key={routes.PHONE_CONFIRM}
        path={routes.PHONE_CONFIRM}
        render={() => (
            <Authenticated strategy={'guest'}>
                <ConfirmPhoneScreen />
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.PHONE_VERIFY}
        path={routes.PHONE_VERIFY}
        render={() => (
            <Authenticated strategy={'guest'}>
                <VerifyPhoneScreen />
            </Authenticated>
        )}
    />,
];

export default phoneVerificationRoutes;
