import React, { FC } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { AppDispatch } from 'src/store/configure-store';
import ConditionForm, { ConditionsFormData } from 'src/screens/Condition/components/ConditionForm';
import { createCondition } from 'src/store/thunks/condition-thunks';
import { conditionLoadingSelector } from 'src/store/selectors/condition-selectors';

interface Props {}

const ConditionCreateScreen: FC<Props> = () => {
    const loading = useSelector(conditionLoadingSelector);
    const handleSubmit = async (data: ConditionsFormData, dispatch: AppDispatch) => {
        await dispatch(createCondition(data)).unwrap();
    };

    return (
        <MainLayout>
            <ContentHeader
                title="Create condition"
                breadcrumbs={[{ title: 'Condition', link: Router.generate(routes.CONDITION_LIST) }]}
            />
            <Content>
                <ConditionForm title="Create" onSubmit={handleSubmit} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default ConditionCreateScreen;
