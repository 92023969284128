import React, { FC, memo } from 'react';
import classNames from 'classnames';
import styles from 'src/screens/Condition/screens/View/assets/condition-info.module.scss';
import { DATETIME_FORMAT } from 'src/config/env';
import { Scan } from 'src/types/scan';
import moment from 'moment';

interface Props {
    scan: Scan;
}

const ScanInfo: FC<Props> = ({ scan }: Props) => {
    const scanCreatedAt = moment(scan.createdAt).format(DATETIME_FORMAT);

    return (
        <div className={classNames('card card-primary card-outline card-outline-tabs')}>
            <div className={classNames('card-header')}>Session: {scan.name}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('table-responsive')}>
                    <table className={classNames('table', styles.tableInfo)}>
                        <tbody>
                            <tr>
                                <th>Id</th>
                                <td>{scan.id}</td>
                            </tr>
                            <tr>
                                <th>User</th>
                                <td>{scan.patient}</td>
                            </tr>
                            <tr>
                                <th>Operator</th>
                                <td>{scan.operator}</td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{scan.name}</td>
                            </tr>
                            <tr>
                                <th>Created at</th>
                                <td>{scanCreatedAt}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default memo(ScanInfo);
