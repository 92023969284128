import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { getScan, scanList, updateScan } from 'src/store/thunks/scan-thunks';
import { crudHelpers, takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { Scan } from 'src/types/scan';

export interface ScanState extends ReducerState {
    data: Paginated<Scan>;
    current: Scan | null;
}

const initialState: ScanState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'scan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(scanList.pending, takeEvery.pendingActionCase)
            .addCase(scanList.rejected, takeEvery.rejectedActionCase)
            .addCase(scanList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(getScan.pending, takeOne.pendingActionCase)
            .addCase(getScan.rejected, takeOne.rejectedActionCase)
            .addCase(getScan.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterGet);
            })

            .addCase(updateScan.pending, takeOne.pendingActionCase)
            .addCase(updateScan.rejected, takeOne.rejectedActionCase)
            .addCase(updateScan.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
