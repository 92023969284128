import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface Props {
    sidebarControl?: boolean;
}

const Navigation: React.FunctionComponent<Props> = React.memo(({ sidebarControl = true }: Props) => (
    <ul className={classNames('navbar-nav')}>
        {sidebarControl && (
            <li className={classNames('nav-item')}>
                <a className={classNames('px-3', 'nav-link')} data-widget="pushmenu" href="#">
                    <FontAwesomeIcon icon={faBars as any} />
                </a>
            </li>
        )}
    </ul>
));

export default Navigation;
