import React from 'react';
import { FormCheck } from 'react-bootstrap';
import { FormCheckProps } from 'react-bootstrap/FormCheck';
import styles from './assets/toggle.module.scss';
import classNames from 'classnames';

export interface Props extends FormCheckProps {
    customSize?: 'sm' | 'md' | 'lg' | 'xl' | any;
    id: string;
    ref?: React.Ref<any>;
}

const Toggle: React.FC<Props> = React.forwardRef(({ className, customSize = 'sm', ...props }: Props | any, ref) => {
    return (
        <FormCheck
            {...props}
            checked={!!props?.value}
            type={'switch'}
            className={classNames(className, styles.customSwitch, styles[customSize])}
            ref={ref}
        />
    );
});

export default Toggle;
