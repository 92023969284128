import { Paginated } from 'src/services/api-handlers/pagination';

type CallbackReturn<T> = Required<Pick<Paginated<T>, 'page' | 'totalPages' | 'list'>>;
type Callback<T> = (page: number) => Promise<CallbackReturn<T>>;

export const fetchAll = async <T extends any = any>(callback: Callback<T>) => {
    const data: T[] = [];
    const fetchedData = await callback(1);
    data.push(...fetchedData.list);

    for (let i = 2; i <= fetchedData.totalPages; i++) {
        const fetchedData = await callback(i);
        data.push(...fetchedData.list);
    }

    return data;
};
