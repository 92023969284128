import React, { FC, memo } from 'react';
import { CellProps } from 'react-table';
import { faEye, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import classNames from 'classnames';
import styles from '../assets/scan-actions-column.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import routes from 'src/navigation/routes';
import { Scan } from 'src/types/scan';

type Action = 'show' | 'edit';

interface Props extends CellProps<Scan> {
    actions: Action[];
}

const ScanActionsColumn: FC<Props> = memo(({ cell: { value }, actions }: Props) => (
    <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
        {actions.includes('show') && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-scan-view">View</Tooltip>}>
                <Link
                    to={Router.generate(routes.SCAN_VIEW, { id: value })}
                    className={classNames(['text-decoration-none', styles.actionLink])}
                >
                    <FontAwesomeIcon icon={faEye} color="#0d6efd" />
                </Link>
            </OverlayTrigger>
        )}
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-scan-view">Models</Tooltip>}>
            <Link
                to={Router.generate(routes.SCAN_MODEL_LIST, { id: value })}
                className={classNames(['text-decoration-none', styles.actionLink])}
            >
                <FontAwesomeIcon icon={faList} color="#0d6efd" />
            </Link>
        </OverlayTrigger>
    </div>
));

export default memo(ScanActionsColumn);
