import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'scans';

const create = (id: string): AxiosPromise<any> => EndPointService.post(`/${ROUTE}/${id}/review`);

export default {
    create,
};
