import React, { FC, memo } from 'react';
import classNames from 'classnames';
import styles from 'src/screens/BodyPart/screens/View/assets/body-part-info.module.scss';
import { BodyPart } from 'src/types/body-part';
import { DATETIME_FORMAT } from 'src/config/env';
import moment from 'moment';

interface Props {
    bodyPart: BodyPart;
}

const BodyPartsInfo: FC<Props> = ({ bodyPart }: Props) => {
    const bodyPartCreatedAt = moment(bodyPart.createdAt).format(DATETIME_FORMAT);
    const bodyPartUpdatedAt = moment(bodyPart.updatedAt).format(DATETIME_FORMAT);

    return (
        <div className={classNames('table-responsive')}>
            <table className={classNames('table', styles.tableInfo)}>
                <tbody>
                    <tr>
                        <th>Id</th>
                        <td>{bodyPart.id}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>{bodyPart.name}</td>
                    </tr>
                    <tr>
                        <th>Note</th>
                        <td>{bodyPart.note}</td>
                    </tr>
                    <tr>
                        <th>Created at</th>
                        <td>{bodyPartCreatedAt}</td>
                    </tr>
                    <tr>
                        <th>Updated at</th>
                        <td>{bodyPartUpdatedAt}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default memo(BodyPartsInfo);
