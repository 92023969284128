import * as React from 'react';
import { DropdownList } from 'react-widgets';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import styles from '../assets/dropdown-filter.module.scss';
import { FilterProps } from './types';

function dropdownFilter<T extends Record<any, any>>(data: SelectableItem[]): React.FunctionComponent<FilterProps<T>> {
    return React.memo(({ column: { filterValue, setFilter } }: FilterProps<T>) => {
        const handleChange = (item: SelectableItem) => {
            setFilter(item.value);
        };

        // react table ignores zero number, so replace it with string
        const preparedData = data.map((item) => {
            return item.value === 0 ? { ...item, value: '0' } : item;
        });

        return (
            <DropdownList
                data={[{ name: '', value: null }, ...preparedData]}
                textField="name"
                dataKey="value"
                containerClassName={styles.dropdownFilter}
                onChange={handleChange}
                value={filterValue}
            />
        );
    });
}

export default dropdownFilter;
