import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { Paginated } from 'src/services/api-handlers/pagination';
import { modelPointListSelector, modelPointLoadingSelector } from 'src/store/selectors/model-point-selectors';
import ModelPointList from 'src/screens/Model/screens/Point/components/ModelPointList';
import { modelPointList } from 'src/store/thunks/model-point-thunks';
import { Point } from 'src/types/model-point';
import { useParams } from 'react-router';
import Router from '../../../../../../navigation/router';
import { routes } from '../../../../../../navigation';

const PointListScreen: FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const loading: boolean = useSelector(modelPointLoadingSelector);
    const data: Paginated<Point> = useSelector(modelPointListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(modelPointList({ ...params, filters: { ...params.filters, scanBodyModel: id } }));
    }, []);

    return (
        <MainLayout>
            <ContentHeader
                title="Model Points"
                breadcrumbs={[{ title: 'Models', link: Router.generate(routes.MODEL_LIST) }]}
            />
            <Content>
                <ModelPointList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default PointListScreen;
