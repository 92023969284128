import React, { FC, memo, useCallback } from 'react';
import { Scan } from 'src/types/scan';
import { useSelector } from 'react-redux';
import { scanReviewLoadingSelector } from 'src/store/selectors/scan-review-selectors';
import { useDispatch } from 'src/hooks/dispatch';
import { createScanReview } from 'src/store/thunks/scan-review-thunks';
import classNames from 'classnames';
import { getScan } from 'src/store/thunks/scan-thunks';
import { DATETIME_FORMAT } from 'src/config/env';
import Button from 'src/components/Button';
import moment from 'moment';
import { toast } from 'react-toastify';
import { extractFirstError } from 'src/components/react-hook-form/utils/make-form-errors';

type ScansReviewProps = {
    scan: Scan;
};

const ScansReview: FC<ScansReviewProps> = ({ scan }: ScansReviewProps) => {
    const loading = useSelector(scanReviewLoadingSelector);
    const dispatch = useDispatch();

    const createScanReviewHandler = useCallback(async () => {
        if (!confirm('Are you sure that you want to approve this scan?')) {
            return;
        }

        try {
            await dispatch(createScanReview({ scanId: scan.id })).unwrap();
            await dispatch(getScan(scan?.id)).unwrap();
            toast.success('Scan was approved');
        } catch (e) {
            toast.error(extractFirstError(e as any, 'Something went wrong.'));
        }
    }, [scan?.id]);

    return (
        <div>
            {scan?.review ? (
                <div className="card">
                    <div className="card-header d-flex align-center">
                        <div className="d-flex align-center">
                            <div className="mr-1">Status:</div>
                            <span className="badge badge-success">Approved</span>
                        </div>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            Approved By: {`${scan?.review?.createdBy?.firstName} ${scan?.review?.createdBy?.lastName}`}
                        </li>
                        <li className="list-group-item">
                            Approved At: {`${moment(scan?.review?.createdAt).format(DATETIME_FORMAT)}`}
                        </li>
                    </ul>
                </div>
            ) : (
                <Button
                    type={'button'}
                    onClick={createScanReviewHandler}
                    className={classNames('btn-success', 'btn-sm', 'mr-3')}
                    loading={loading}
                    disabled={loading}
                >
                    Approve
                </Button>
            )}
        </div>
    );
};

export default memo(ScansReview);
