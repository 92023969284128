import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import classNames from 'classnames';

type Props = {
    value: boolean;
    trueText?: string;
    falseText?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const BooleanBadge: React.FC<Props> = ({ value, trueText = 'Yes', falseText = 'No', ...props }: Props) => {
    return (
        <span className={classNames('badge', value ? 'badge-success' : 'badge-danger')} {...props}>
            {value ? trueText : falseText}
        </span>
    );
};

export default BooleanBadge;
