import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import Loader from '../../Loader';

interface Props {
    loading?: boolean;
}

const Content: React.FunctionComponent<Props> = ({ children, loading = false }: PropsWithChildren<Props>) => {
    if (loading) {
        return <Loader />;
    }

    return (
        <section className={classNames('content', 'position-relative')}>
            <div className={classNames('container-fluid')}>{children}</div>
        </section>
    );
};

export default Content;
