import React, { useCallback } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import { bodyPartCurrentSelector, bodyPartLoadingSelector } from 'src/store/selectors/body-part-selectors';
import BodyPartForm, { BodyPartFormData } from 'src/screens/BodyPart/components/BodyPartForm';
import { getBodyPart, updateBodyPart } from 'src/store/thunks/body-part-thunks';
import { makeBreadcrumbs } from 'src/screens/BodyPart/helpers/breadcrumb';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

interface RouteParams {
    id: string;
}

interface Props {}

const EditScreen: React.FunctionComponent<Props> = () => {
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const bodyPart = useSelector(bodyPartCurrentSelector);
    const loading = useSelector(bodyPartLoadingSelector);
    const history = useHistory();

    const handleSubmit = async (data: BodyPartFormData, dispatch: AppDispatch) => {
        try {
            await dispatch(updateBodyPart({ id, ...data })).unwrap();
            toast.success('Data was saved');
            history.push(routes.BODY_PART_LIST);
        } catch (e: any) {
            const message = e.pop().error?.message || 'Something went wrong';
            toast.error(message);
        }
    };

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getBodyPart(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                {bodyPart && (
                    <>
                        <ContentHeader
                            title="Edit body part"
                            breadcrumbs={[
                                { title: 'Body Parts', link: Router.generate(routes.BODY_PART_LIST) },
                                ...makeBreadcrumbs(bodyPart),
                            ]}
                        />
                        <Content loading={!bodyPart || bodyPart.id !== id}>
                            <BodyPartForm title={`${bodyPart?.name}`} bodyPart={bodyPart} onSubmit={handleSubmit} />
                        </Content>
                    </>
                )}
                {loading && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default EditScreen;
