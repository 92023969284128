import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const modelReviewStateSelector = (state: StoreState) => state.modelReview;
export const modelReviewListSelector = createDraftSafeSelector(modelReviewStateSelector, (state) => state.data);
export const modelReviewErrorSelector = createDraftSafeSelector(modelReviewStateSelector, (state) => state.error);
export const modelReviewLoadingSelector = createDraftSafeSelector(
    modelReviewStateSelector,
    (state) => state.loading === 'loading',
);
export const modelReviewCurrentSelector = createDraftSafeSelector(modelReviewStateSelector, (state) => state.current);
