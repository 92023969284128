import { createSlice } from '@reduxjs/toolkit';
import { crudHelpers, takeOne } from 'src/helpers/store';
import { getScanDeviceInfo, updateScanDeviceInfo } from 'src/store/thunks/scan-device-info-thunks';
import { ReducerState } from 'src/store/configure-store';
import { ScanDeviceInfo } from 'src/types/scan-device-info';

export interface ScanDeviceInfoState extends ReducerState {
    current: ScanDeviceInfo | null;
}

const initialState: ScanDeviceInfoState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
};

export const slice = createSlice({
    name: 'scan/device-info',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(updateScanDeviceInfo.pending, takeOne.pendingActionCase)
            .addCase(updateScanDeviceInfo.rejected, takeOne.rejectedActionCase)
            .addCase(updateScanDeviceInfo.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            })

            .addCase(getScanDeviceInfo.pending, takeOne.pendingActionCase)
            .addCase(getScanDeviceInfo.rejected, takeOne.rejectedActionCase)
            .addCase(getScanDeviceInfo.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterGet);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
