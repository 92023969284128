import React, { FC, memo } from 'react';
import { CellProps } from 'react-table';
import { faEye, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteBodyPartAction from 'src/screens/BodyPart/components/DeleteBodyPartAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import classNames from 'classnames';
import styles from 'src/screens/BodyPart/assets/body-parts-actions-column.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BodyPart } from 'src/types/body-part';
import routes from 'src/navigation/routes';

type Action = 'view' | 'edit' | 'delete' | 'children';

interface Props extends CellProps<BodyPart> {
    actions: Action[];
}

const ActionsColumn: FC<Props> = memo(
    ({
        cell: {
            row: { original },
            value,
        },
        actions,
    }: Props) => {
        return (
            <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
                {actions.includes('view') && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-body-part-view">View</Tooltip>}>
                        <Link
                            to={Router.generate(routes.BODY_PART_VIEW, { id: value })}
                            className={classNames(['text-decoration-none', styles.actionLink])}
                        >
                            <FontAwesomeIcon icon={faEye} color="#0d6efd" />
                        </Link>
                    </OverlayTrigger>
                )}
                {actions.includes('edit') && original.level === 2 && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-body-part-edit">Edit</Tooltip>}>
                        <Link
                            to={Router.generate(routes.BODY_PART_EDIT, { id: value })}
                            className={classNames(['text-decoration-none', styles.actionLink])}
                        >
                            <FontAwesomeIcon icon={faEdit} color="#0d6efd" />
                        </Link>
                    </OverlayTrigger>
                )}
                {actions.includes('children') && original.childrenCount > 0 && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-body-part-children">Children</Tooltip>}
                    >
                        <Link
                            to={Router.generate(routes.BODY_PART_CHILDREN, { id: value })}
                            className={classNames(['text-decoration-none', styles.actionLink])}
                        >
                            Children ({original.childrenCount})
                        </Link>
                    </OverlayTrigger>
                )}
                {actions.includes('delete') && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-body-part-delete">Delete</Tooltip>}>
                        <div>
                            <DeleteBodyPartAction bodyPart={original}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </DeleteBodyPartAction>
                        </div>
                    </OverlayTrigger>
                )}
            </div>
        );
    },
);

export default ActionsColumn;
