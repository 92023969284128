import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import { QueryParams } from 'src/types/grid';
import { Point } from 'src/types/model-point';
import React, { FC, memo } from 'react';

type Props = {
    data: Paginated<Point>;
    getData: (params: QueryParams) => void;
};

const modelPointColumns: Column<Point>[] = [
    {
        Header: 'Id',
        accessor: (value) => value.id,
        disableSortBy: true,
    },
    {
        Header: 'Body Part',
        accessor: 'bodyPart',
        Cell: ({ value: { name } }) => name,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Coordinates',
        accessor: 'coordinates',
        Cell: ({ value }) => JSON.stringify(value),
        disableFilters: true,
        disableSortBy: true,
    },
];

const ModelList: FC<Props> = ({ data, getData }: Props) => {
    return <Grid<Point> columns={modelPointColumns} data={data} getData={getData} defaultSorting={{ id: 'DESC' }} />;
};

export default memo(ModelList);
