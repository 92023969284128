import React from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import StaffForm, { StaffFormData } from '../../components/StaffForm';
import { useSelector } from 'react-redux';
import { staffLoadingSelector } from 'src/store/selectors/staff-selectors';
import Loader from 'src/components/Loader';
import { createStaff } from 'src/store/thunks/staff-thunks';
import { AppDispatch } from 'src/store/configure-store';

interface Props {}

const CreateScreen: React.FunctionComponent<Props> = () => {
    const loading = useSelector(staffLoadingSelector);
    const handleSubmit = async (data: StaffFormData, dispatch: AppDispatch) => {
        await dispatch(createStaff(data)).unwrap();
    };

    return (
        <MainLayout>
            <ContentHeader
                title="Create staff"
                breadcrumbs={[{ title: 'Staff', link: Router.generate(routes.STAFF_LIST) }]}
            />
            <Content>
                <StaffForm title="Create" onSubmit={handleSubmit} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default CreateScreen;
