import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import ModelPointApi from 'src/services/end-points/model-point-api';
import SortingService from '../../services/sorting';
import { QueryParams } from 'src/types/grid';
import { Point } from 'src/types/model-point';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';

export const modelPointList = createAsyncThunk<Paginated<Point>, QueryParams, ThunkConfig>(
    'modelPoint/list',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelPoint } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            modelPoint,
            async () =>
                ModelPointApi.list({
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            modelPoint.data,
        );
    },
);

export const modelPointCreate = createAsyncThunk<Point, { [key: string]: any }, ThunkConfig>(
    'modelPoint/create',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelPoint } = getState();

        return resolveApiCall(
            thunkAPI,
            modelPoint,
            async () => {
                const response = await ModelPointApi.create(convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const modelPointUpdate = createAsyncThunk<Point, { id: string; [key: string]: any }, ThunkConfig>(
    'modelPoint/update',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelPoint } = getState();

        return resolveApiCall(
            thunkAPI,
            modelPoint,
            async () => {
                const response = await ModelPointApi.update(id, convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const modelPointDelete = createAsyncThunk<void, string, ThunkConfig>(
    'modelPoint/delete',
    async (id, thunkAPI) => {
        const { getState } = thunkAPI;
        const { modelPoint } = getState();

        return resolveApiCall(thunkAPI, modelPoint, async () => {
            await ModelPointApi.remove(id);
        });
    },
);
