export default {
    HOME: '/',
    VERIFY_CODE: '/login/verify',
    PHONE_CONFIRM: '/phone-verification/confirm',
    PHONE_VERIFY: '/phone-verification/verify',
    LOGOUT: '/logout',
    DASHBOARD: '/dashboard',
    PROFILE: '/profile',
    STAFF_LIST: '/staff/list',
    BODY_PART_LIST: '/body-parts/list',
    BODY_PART_CHILDREN: '/body-parts/children/:id',
    BODY_PART_VIEW: '/body-parts/view/:id',
    BODY_PART_CREATE: '/body-parts/create',
    BODY_PART_EDIT: '/body-parts/edit/:id',
    STAFF_VIEW: '/staff/view/:id',
    STAFF_CREATE: '/staff/create',
    STAFF_EDIT: '/staff/edit/:id',
    BODY_PART_VALIDATION_CREATE: '/body-part-validation/:type/create',
    BODY_PART_VALIDATION_LIST: '/body-part-validation/:type/list',
    BODY_PART_VALIDATION_VIEW: '/body-part-validation/:type/view/:id',
    BODY_PART_VALIDATION_EDIT: '/body-part-validation/:type/edit/:id',
    CONDITION_LIST: '/condition/list',
    CONDITION_EDIT: '/condition/edit/:id',
    CONDITION_VIEW: '/condition/view/:id',
    CONDITION_CREATE: '/condition/create',
    SCAN_LIST: '/scan/list',
    SCAN_VIEW: '/scan/view/:id',
    SCAN_SCORES_LIST: '/scan/:id/scores/list',
    SCAN_SCORES_CREATE: '/scan/:id/scores/create',
    SCAN_SCORES_EDIT: '/scan/:id/scores/:scoreId',
    SCAN_MODEL_LIST: '/scan/:id/model/list',
    SCAN_CONDITION_CREATE: '/scans/:id/conditions/create',
    SCAN_CONDITION_LIST: '/scans/:id/conditions',
    SCAN_CONDITION_DELETE: '/scans/:id/conditions/:conditionId',
    MODEL_POINT_LIST: '/model/:id/point/list',
    SCAN_PHY_SCORES: '/scan/:id/phy-scores',
    MODEL_LIST: '/models/list',
    MODEL_REVIEW_LIST: '/models/:id/reviews',
    MODEL_REVIEW_VIEW: '/models/:id/reviews/:reviewId/view',
    MODEL_REVIEW_EDIT: '/models/:id/reviews/:reviewId/edit',
};
