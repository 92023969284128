import { EnumerableAbstract } from './enumerable.abstract';

class ScanDeviceType extends EnumerableAbstract {
    readonly IOS = 'ios';
    readonly LIDAR = 'lidar';

    getName = () => 'ScanDeviceType';

    defaultChoices = () => ({
        [this.IOS]: 'IOS',
        [this.LIDAR]: 'LiDAR',
    });
}

const scanDeviceType = new ScanDeviceType();

export default scanDeviceType;
