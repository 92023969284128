import React from 'react';
import classNames from 'classnames';
import Button from 'src/components/Button';
import styles from '../assets/confirm-form.module.scss';
import { Staff } from 'src/types/staff';

interface Props {
    user: Staff;
    yesBtnHandler: () => void;
    noBtnHandler: () => void;
}

const ConfirmPhone: React.FC<Props> = ({ user, yesBtnHandler, noBtnHandler }: Props) => {
    return (
        <div className={classNames('card')}>
            <div className={classNames('card-body', 'login-card-body')}>
                <p className={classNames('login-box-msg', 'pb-2')}>Do you want to use this phone number?</p>
                <p className={classNames('login-box-msg', styles.phoneNumber)}>{user?.phoneNumber}</p>

                <div className={classNames(styles.btnWrap)}>
                    <Button
                        type="button"
                        className={classNames('btn', 'btn-primary', 'btn-block', styles.btn)}
                        onClick={yesBtnHandler}
                    >
                        Yes
                    </Button>
                    <a href="#" onClick={noBtnHandler} className={classNames(styles.text)}>
                        No, change number
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPhone;
