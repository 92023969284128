import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import styles from '../assets/body-parts-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import { BodyPart } from 'src/types/body-part';
import bodyPartSchema from 'src/screens/BodyPart/schema/body-part-schema';
import Button from 'src/components/Button';
import FieldCheckbox from 'src/components/react-hook-form/fields/FieldCheckbox';
import { routes } from 'src/navigation';

export interface BodyPartFormData {
    decisionTree: boolean;
}

interface BodyPartFormProps {
    title: string;
    bodyPart?: BodyPart | null;
    onSubmit: (data: BodyPartFormData, dispatch: AppDispatch) => Promise<any>;
}

const BodyPartForm: FC<BodyPartFormProps> = ({ title, bodyPart, onSubmit }: BodyPartFormProps) => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, errors },
    } = useForm<BodyPartFormData>({
        resolver: yupResolver(bodyPartSchema),
        defaultValues: { ...bodyPart },
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const navigateBack = useCallback(() => {
        history.push(routes.BODY_PART_LIST);
    }, []);

    const submit = async (data: BodyPartFormData) => {
        await withErrors<BodyPartFormData>(onSubmit(data, dispatch), setError);
    };
    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldCheckbox
                            control={control}
                            name="decisionTree"
                            defaultChecked={bodyPart?.decisionTree}
                            id="decisionTreeCheckbox"
                            error={errors?.decisionTree?.message}
                        >
                            <label className="form-check-label mx-1" htmlFor="decisionTreeCheckbox">
                                Decision Tree
                            </label>
                        </FieldCheckbox>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
                <Button className={classNames('btn', 'btn-danger')} onClick={navigateBack} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faUndoAlt} /> Cancel
                </Button>
            </div>
        </form>
    );
};

export default BodyPartForm;
