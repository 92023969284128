import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { staffLoadingSelector } from '../../../store/selectors/staff-selectors';
import Button from '../../../components/Button';
import { Staff } from 'src/types/staff';
import { toast } from 'react-toastify';
import { useDispatch } from 'src/hooks/dispatch';
import { deleteStaff } from 'src/store/thunks/staff-thunks';

interface Props {
    staff: Staff;
    successCallback?: () => void;
}

const DeleteStaffAction: React.FunctionComponent<Props> = ({
    staff,
    children,
    successCallback,
}: PropsWithChildren<Props>) => {
    const dispatch = useDispatch();
    const loading = useSelector(staffLoadingSelector);
    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = useCallback(async () => {
        await dispatch(deleteStaff(staff.id)).unwrap();
        setShow(false);
        toast.success('Staff was deleted');
        successCallback && successCallback();
    }, [staff.id]);

    return (
        <>
            <a href="#" onClick={handleShow}>
                {children}
            </a>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this staff?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classNames('btn-default')} onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        className={classNames('btn-danger')}
                        onClick={handleDelete}
                        disabled={loading}
                        loading={loading}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteStaffAction;
