import React, { FC, memo } from 'react';
import classNames from 'classnames';
import styles from 'src/screens/Condition/screens/View/assets/condition-info.module.scss';
import { Condition } from 'src/types/condition';
import { DATETIME_FORMAT } from 'src/config/env';
import moment from 'moment';

interface Props {
    condition: Condition;
}

const ConditionInfo: FC<Props> = ({ condition }: Props) => {
    const conditionsCreatedAt = moment(condition.createdAt).format(DATETIME_FORMAT);
    const conditionsUpdatedAt = moment(condition.updatedAt).format(DATETIME_FORMAT);

    return (
        <div className={classNames('table-responsive')}>
            <table className={classNames('table', styles.tableInfo)}>
                <tbody>
                    <tr>
                        <th>Id</th>
                        <td>{condition.id}</td>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>{condition.name}</td>
                    </tr>
                    <tr>
                        <th>Body Part</th>
                        <td>{condition.bodyPart.name}</td>
                    </tr>

                    <tr>
                        <th>Created at</th>
                        <td>{conditionsCreatedAt}</td>
                    </tr>
                    <tr>
                        <th>Updated at</th>
                        <td>{conditionsUpdatedAt}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default memo(ConditionInfo);
