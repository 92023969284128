import { AbstractSceneStore } from './abstract-scene-store';
import ConfigStore from './config.store';
import { makeObservable } from 'mobx';

export class ImageSceneStore extends AbstractSceneStore {
    constructor(config: ConfigStore) {
        super(config);
        makeObservable(this);
    }
}

export default ImageSceneStore;
