import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import userRole from 'src/enumerables/user-role';
import Authenticated from 'src/components/auth/Authenticated';
import Restricted from 'src/components/auth/Restricted';
import { ConditionListScreen } from 'src/screens/Condition/screens/List';
import { ConditionViewScreen } from 'src/screens/Condition/screens/View';
import { ConditionCreateScreen } from 'src/screens/Condition/screens/Create';
import { ConditionEditScreen } from 'src/screens/Condition/screens/Edit';

const staffRoutes = [
    <Route
        exact
        key={routes.CONDITION_LIST}
        path={routes.CONDITION_LIST}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ConditionListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.CONDITION_VIEW}
        path={routes.CONDITION_VIEW}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ConditionViewScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.CONDITION_CREATE}
        path={routes.CONDITION_CREATE}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ConditionCreateScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.CONDITION_EDIT}
        path={routes.CONDITION_EDIT}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ConditionEditScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default staffRoutes;
