import React, { FC, memo, useCallback } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faList, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteConditionAction from 'src/screens/Condition/components/DeleteConditionAction';
import styles from '../assets/actions.module.scss';
import { Condition } from 'src/types/condition';
import Button from 'src/components/Button';

interface Props {
    condition: Condition | null;
}

const Actions: FC<Props> = ({ condition }: Props) => {
    const history = useHistory();
    const deleteCallback = useCallback(() => {
        history.push(Router.generate(routes.CONDITION_LIST));
    }, []);

    if (!condition) {
        return null;
    }

    return (
        <div className={classNames(styles.actions)}>
            <Link className={classNames('btn', 'btn-primary')} to={Router.generate(routes.CONDITION_LIST)}>
                <FontAwesomeIcon icon={faList} /> Return to list
            </Link>
            <Link
                className={classNames('btn', 'btn-success')}
                to={Router.generate(routes.CONDITION_EDIT, { id: condition.id })}
            >
                <FontAwesomeIcon icon={faEdit} /> Edit
            </Link>

            <DeleteConditionAction condition={condition} successCallback={deleteCallback}>
                <Button className={classNames('btn-danger')}>
                    <FontAwesomeIcon icon={faTrashAlt} /> Delete
                </Button>
            </DeleteConditionAction>
        </div>
    );
};

export default memo(Actions);
