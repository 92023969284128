import React, { FC, memo, useCallback } from 'react';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import classNames from 'classnames';
import securityService from 'src/security/security';
import userRole from 'src/enumerables/user-role';

type RouteParams = {
    id: string;
};

const ScanTabs: FC = () => {
    const { id } = useParams<RouteParams>();

    const scanViewActions = [
        { title: 'Show', route: routes.SCAN_VIEW },
        { title: 'Models', route: routes.SCAN_MODEL_LIST },
        securityService.isGranted(userRole.ROLE_STAFF) ? false : { title: 'Scores', route: routes.SCAN_SCORES_LIST },
        securityService.isGranted(userRole.ROLE_STAFF)
            ? false
            : { title: 'Conditions', route: routes.SCAN_CONDITION_LIST },
        { title: 'PHY Scores', route: routes.SCAN_PHY_SCORES },
    ].filter((item) => item) as any[];

    const checkRoute = useCallback(
        (route: string) => {
            return Router.isCurrentRoute(route, { id }) ? 'active' : '';
        },
        [id],
    );

    const scanActions = scanViewActions.map(({ title, route }) => (
        <li className="nav-item" key={title}>
            <Link to={Router.generate(route, { id })} className={classNames(`nav-link text-black`, checkRoute(route))}>
                {title}
            </Link>
        </li>
    ));
    return <ul className="nav nav-tabs">{scanActions}</ul>;
};

export default memo(ScanTabs);
