import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { crudHelpers, takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { Condition } from 'src/types/condition';
import {
    createCondition,
    deleteCondition,
    getCondition,
    conditionList,
    updateCondition,
} from 'src/store/thunks/condition-thunks';

export interface ConditionsState extends ReducerState {
    data: Paginated<Condition>;
    current: Condition | null;
}

const initialState: ConditionsState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'condition',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(conditionList.pending, takeEvery.pendingActionCase)
            .addCase(conditionList.rejected, takeEvery.rejectedActionCase)
            .addCase(conditionList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(getCondition.pending, takeOne.pendingActionCase)
            .addCase(getCondition.rejected, takeOne.rejectedActionCase)
            .addCase(getCondition.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterGet);
            })

            .addCase(createCondition.pending, takeOne.pendingActionCase)
            .addCase(createCondition.rejected, takeOne.rejectedActionCase)
            .addCase(createCondition.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
            })

            .addCase(updateCondition.pending, takeOne.pendingActionCase)
            .addCase(updateCondition.rejected, takeOne.rejectedActionCase)
            .addCase(updateCondition.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            })

            .addCase(deleteCondition.pending, takeOne.pendingActionCase)
            .addCase(deleteCondition.rejected, takeOne.rejectedActionCase)
            .addCase(deleteCondition.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterDelete);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
