import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import Restricted from 'src/components/auth/Restricted';
import userRole from 'src/enumerables/user-role';
import Authenticated from 'src/components/auth/Authenticated';
import BodyPartListScreen from 'src/screens/BodyPart/screens/List/BodyPartListScreen';
import BodyPartViewScreen from 'src/screens/BodyPart/screens/View/BodyPartViewScreen';
import BodyPartEditScreen from 'src/screens/BodyPart/screens/Edit/BodyPartEditScreen';
import { BodyPartChildrenScreen } from 'src/screens/BodyPart/screens/Children';

const bodyPartRoutes = [
    <Route
        exact
        key={routes.BODY_PART_LIST}
        path={routes.BODY_PART_LIST}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <BodyPartListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.BODY_PART_VIEW}
        path={routes.BODY_PART_VIEW}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <BodyPartViewScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.BODY_PART_EDIT}
        path={routes.BODY_PART_EDIT}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <BodyPartEditScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.BODY_PART_CHILDREN}
        path={routes.BODY_PART_CHILDREN}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <BodyPartChildrenScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default bodyPartRoutes;
