import React, { FC } from 'react';
import {
    Area,
    Brush,
    CartesianGrid,
    ComposedChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import styles from '../assets/dashboard-models-chart.module.scss';
import classNames from 'classnames';
import { ModelsStatistic } from 'src/types/statistic';

type DashboardModelsChartProps = {
    statisticData: ModelsStatistic[];
    chartTitle: string;
};

const DashboardModelsChart: FC<DashboardModelsChartProps> = ({
    statisticData,
    chartTitle,
}: DashboardModelsChartProps) => {
    if (statisticData?.length === 0) {
        return null;
    }
    return (
        <div className={classNames(styles.gridContainer)}>
            <div className={classNames(styles.composedChart)}>
                <h5 className={classNames('alert alert-primary', styles.chartTitle)}>{chartTitle}</h5>
                <ResponsiveContainer width="100%" height={600}>
                    <ComposedChart data={statisticData}>
                        <XAxis dataKey="createdAt" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Area type="monotone" dataKey="count" fill="#3B85B5" stroke="#000" />
                        <Brush dataKey="createdAt" height={30} stroke="#3B85B5" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DashboardModelsChart;
