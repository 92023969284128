import React, { useEffect } from 'react';
import styles from './assets/login-screen.module.scss';
import LoginForm from './components/LoginForm';
import classNames from 'classnames';
import GuestLayout from 'src/components/layout/GuestLayout';
import { useDispatch } from 'react-redux';
import { useUser } from 'src/hooks/user';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../../store/thunks/auth-thunks';

export interface Props {}

const LoginScreen: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useUser();

    const onLogin = () => {
        if (user?.phoneVerified) {
            history.push(Router.generate(routes.VERIFY_CODE));
            return;
        }

        history.push(Router.generate(routes.PHONE_CONFIRM));
    };

    useEffect(() => {
        dispatch(logout());
    }, []);

    return (
        <GuestLayout>
            <div className={classNames(styles.login, 'login-page')}>
                <LoginForm onSuccess={onLogin} />
            </div>
        </GuestLayout>
    );
};

export default LoginScreen;
