import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import CreateForm from './components/CreateForm';
import useStores from '../../../hooks/use-store';
import { observer } from 'mobx-react';
import EditForm from './components/EditForm';
import { Stores } from 'src/stores';
import { DropdownList } from 'react-widgets';
import { Model } from 'src/types/models';
import FilterForm from './components/FilterForm';
import Visible from 'src/components/common/Visible';
import PointView from './components/PointView';
import { Point } from 'src/types/points';
import LayersForm from './components/LayersForm';
import useSceneStore from 'src/hooks/use-scene-store';

interface Props {
    disabled: boolean;
}

const Controls = observer(({ disabled = false }: Props) => {
    const { modelScene, imageScene, models: modelsStore, config: configStore } = useStores<Stores>();
    const canvasStore = useSceneStore();

    const modelChangeHandler = useCallback((value: Model) => {
        modelsStore.setActiveModel(value);
    }, []);

    const toggleWireframe = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        modelScene.setWireframeMode(e.target.checked);
    }, []);

    const toggleImage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        modelsStore.setActiveImage(e.target.checked);
    }, []);

    // staff cant edit if there is pending or approved review, PT cant edit if there is approved review
    const canEdit = useMemo(() => {
        return modelsStore.activeImage ? modelsStore.activeModel?.canEditImage : modelsStore.activeModel?.canEditModel;
    }, [modelsStore.activeModel, modelsStore.activeImage]);

    useEffect(() => {
        modelScene.reset();
        imageScene.reset();
    }, [modelsStore.activeModel?.id, modelsStore.activeImage]);

    return (
        <Visible if={modelsStore.activeModel}>
            <div className={classNames('canvas-controls')}>
                <div className={classNames('block-controls', 'model-controls')}>
                    <div className={classNames('block-controls-files')}>
                        <label>Model file: </label>
                        <div className={classNames('wireframe-toggle')}>
                            <label htmlFor={'wireframe-toggle'}>Wireframe</label>
                            <input
                                disabled={disabled}
                                name={'wireframe'}
                                id={'wireframe-toggle'}
                                type={'checkbox'}
                                onChange={toggleWireframe}
                                checked={modelScene.wireframeMode}
                                data-sonata-icheck="false"
                            />
                            <Visible if={modelsStore.activeModel?.imageUrl}>
                                <label htmlFor={'image-toggle'}>Image</label>
                                <input
                                    disabled={disabled}
                                    name={'image'}
                                    id={'image-toggle'}
                                    type={'checkbox'}
                                    onChange={toggleImage}
                                    checked={modelsStore.activeImage}
                                />
                            </Visible>
                        </div>
                        <DropdownList
                            disabled={disabled}
                            value={modelsStore.activeModel}
                            data={modelsStore.models}
                            onChange={modelChangeHandler}
                            dataKey={'id'}
                            textField={'friendlyName'}
                        />
                    </div>
                </div>

                <Visible if={canEdit || canvasStore.activePoint}>
                    <div className={classNames('block-controls', 'point-controls')}>
                        <Visible if={canEdit}>
                            <CreateForm />
                            <EditForm />
                        </Visible>
                        <Visible if={!canEdit && canvasStore.activePoint}>
                            <PointView point={canvasStore.activePoint as Point} />
                        </Visible>
                    </div>
                </Visible>

                <Visible if={configStore.showFilters}>
                    <div className={classNames('block-controls', 'filter-controls')}>
                        <FilterForm />
                    </div>
                </Visible>
                <Visible if={!modelsStore.activeImage && configStore.showLayers}>
                    <div className={classNames('block-controls', 'layers-controls')}>
                        <LayersForm />
                    </div>
                </Visible>
            </div>
        </Visible>
    );
});

export default Controls;
