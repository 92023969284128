import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import userRole from 'src/enumerables/user-role';
import Authenticated from 'src/components/auth/Authenticated';
import Restricted from 'src/components/auth/Restricted';
import { PhyScoreScreen } from 'src/screens/Scan/screens/PhyScore';

const phyScoreRoutes = [
    <Route
        exact
        key={routes.SCAN_PHY_SCORES}
        path={routes.SCAN_PHY_SCORES}
        render={() => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <PhyScoreScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default phyScoreRoutes;
