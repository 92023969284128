import BodyPartValidationActionsColumn from 'src/screens/BodyPartValidation/components/BodyPartValidationActionsColumn';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import { BodyPartValidation } from 'src/types/body-part-validation';
import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import { QueryParams } from 'src/types/grid';
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-list.module.scss';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

type Props = {
    data: Paginated<BodyPartValidation>;
    getData: (params: QueryParams) => void;
    type: string;
};

const bodyPartValidationColumns: Column<BodyPartValidation>[] = [
    {
        Header: 'Body Part',
        accessor: 'bodyPart',
        Cell: ({ value: { name } }) => name,
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <BodyPartValidationActionsColumn {...props} actions={['delete']} />,
        disableSortBy: true,
        disableFilters: true,
    },
];

const BodyPartValidationList: FC<Props> = ({ data, getData, type }: Props) => {
    return (
        <Grid<BodyPartValidation>
            columns={bodyPartValidationColumns}
            data={data}
            getData={getData}
            title={
                <div className={classNames(styles.heading, 'd-flex', 'justify-content-end')}>
                    <Link
                        className={classNames('text-decoration-none')}
                        to={Router.generate(routes.BODY_PART_VALIDATION_CREATE, { type })}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Create
                    </Link>
                </div>
            }
            defaultSorting={{ createdAt: 'DESC' }}
        />
    );
};

export default BodyPartValidationList;
