import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { Group } from 'three';
import { v4 as uuid } from 'uuid';
import useStores from 'src/hooks/use-store';
import ThreeStore from 'src/stores/model-scene-store';
import { Stores } from 'src/stores';
import { observer } from 'mobx-react';
import ActivePoint from './ActivePoint';
import { CreatedPoint } from 'src/stores/abstract-scene-store';
import { Point } from 'src/types/points';
import { BODY_OBJECT_NAME } from './LoadedModel';

interface Props {}

type MouseCords = { x: number; y: number };

const MOUSE_SIGNIFICANT_MOVE = 5; // pixels

const CreatedPoints: React.FC<Props> = observer((props: Props) => {
    const modelScene = useStores<Stores>('modelScene') as ThreeStore;
    const pointsRef = useRef<Group>(null);
    const { scene, raycaster, camera, gl } = useThree();
    const vector = useMemo(() => new THREE.Vector2(), []);
    const pointerDownPosRef = useRef<MouseCords>({ x: 0, y: 0 });

    const isMouseSignificallyMoved = useCallback(({ x, y }: MouseCords) => {
        return (
            Math.abs(pointerDownPosRef.current.x - x) > MOUSE_SIGNIFICANT_MOVE ||
            Math.abs(pointerDownPosRef.current.y - y) > MOUSE_SIGNIFICANT_MOVE
        );
    }, []);

    // add points
    const sceneClickHandler = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            console.log('sceneClickHandler');

            if (isMouseSignificallyMoved({ x: event.x, y: event.y })) {
                return;
            }

            const canvas = event.currentTarget as HTMLCanvasElement;

            vector.x = ((event.clientX - canvas.getBoundingClientRect().left) / canvas.width) * 2 - 1;
            vector.y = -((event.clientY - canvas.getBoundingClientRect().top) / canvas.height) * 2 + 1;
            raycaster.setFromCamera(vector, camera);

            const bodyOuterObj = scene.getObjectByName(BODY_OBJECT_NAME) as THREE.Object3D;
            const intersectsBody = raycaster.intersectObjects(bodyOuterObj.children, true); // check if we click on body model
            const intersectsPoints = raycaster.intersectObjects(pointsRef.current!.children, true); // check if we click on existing point

            if (intersectsPoints.length > 0) {
                return; // if clicked on existing point, then do not create a new one, so return
            }

            // add new point
            if (intersectsBody.length > 0) {
                const { x, y, z } = intersectsBody[0].point;
                modelScene.addCreatedPoint({ id: uuid(), coordinates: { x, y, z } });
            }
        },
        [vector, raycaster, camera, scene],
    );

    const pointClickHandler = useCallback((event: any, point: CreatedPoint<Point>) => {
        event.stopPropagation();
        modelScene.removeCreatedPoint(point);
    }, []);

    const pointerDownHandler = useCallback((e: PointerEvent) => {
        pointerDownPosRef.current.x = e.x;
        pointerDownPosRef.current.y = e.y;
    }, []);

    useEffect(() => {
        const canvas = gl.getContext().canvas;
        canvas.addEventListener('pointerdown', pointerDownHandler as any);
        canvas.addEventListener('pointerup', sceneClickHandler as any);

        return () => {
            canvas.removeEventListener('pointerup', sceneClickHandler as any);
            canvas.removeEventListener('pointerdown', pointerDownHandler as any);
        };
    }, []);

    useEffect(() => {
        console.log('LENGTJ', modelScene.createdPoints.length);
    }, [modelScene.createdPoints.length]);

    return (
        <group ref={pointsRef}>
            {modelScene.createdPoints.map((point) => (
                <ActivePoint
                    {...point.coordinates}
                    z={point.coordinates.z as number}
                    key={point.id}
                    onClick={(e) => pointClickHandler(e, point)}
                />
            ))}
        </group>
    );
});

export default CreatedPoints;
