import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as yup from 'yup';
import styles from '../assets/staff-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Staff } from 'src/types/staff';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import FieldInput from '../../../components/react-hook-form/fields/FieldInput';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import FieldDropdown from '../../../components/react-hook-form/fields/FieldDropdown';
import Button from '../../../components/Button';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import userRole from 'src/enumerables/user-role';
import securityService from 'src/security/security';
import FieldInternationalPhoneNumber from '../../../components/react-hook-form/fields/FieldInternationalPhoneNumber';
import {
    InternationalPhoneNumberFormData,
    useInternationalPhoneNumber,
} from '../../../hooks/international-phone-number';

export interface StaffFormData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    repeatPassword: string;
    role: SelectableItem | string;
    phoneNumber: string;
}

interface FormData extends StaffFormData, InternationalPhoneNumberFormData {}

interface StaffFormProps {
    title: string;
    staff?: Staff | null;
    onSubmit: (data: StaffFormData, dispatch: AppDispatch) => Promise<any>;
}

const transformEmptyString = (value: string): string | undefined => (value === '' ? undefined : value);

const StaffForm: React.FunctionComponent<StaffFormProps> = ({ title, staff, onSubmit }: StaffFormProps) => {
    const {
        validationSchema: phoneNumberValidationSchema,
        defaultValues: defaultPhoneNumberValues,
        withPreparedData: withPreparedPhoneData,
    } = useInternationalPhoneNumber({
        fieldName: 'phoneNumber',
        initialValue: staff?.phoneNumber,
    });

    const validationSchema = yup.object().shape({
        firstName: yup.string().required().max(255).label('First Name'),
        lastName: yup.string().required().max(255).label('Last Name'),
        email: yup.string().required().email().max(255).label('Email'),
        password: yup.string().transform(transformEmptyString).notRequired().min(6).max(255).label('Password'),
        repeatPassword: yup
            .string()
            .when('password', {
                is: (value: any) => !!value,
                then: yup
                    .string()
                    .transform(transformEmptyString)
                    .required()
                    .min(6)
                    .max(255)
                    .equals([yup.ref('password')], 'Passwords must match')
                    .label('Repeat Password'),
            })
            .label('Repeat Password'),
        role: yup.mixed().optional().label('Role'),
        phoneNumber: phoneNumberValidationSchema.label('Phone Number'),
    });

    const {
        control,
        clearErrors,
        handleSubmit,
        watch,
        setError,
        formState: { isSubmitting, submitCount, isSubmitSuccessful, errors },
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...staff,
            role: staff ? { name: userRole.getLabel(staff.roles[0]), value: staff.roles[0] } : undefined,
            ...defaultPhoneNumberValues,
        },
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const navigateBack = useCallback(() => {
        history.goBack();
    }, []);

    useEffect(() => {
        if (isSubmitSuccessful) {
            toast.success('Staff data was saved');
            navigateBack();
        } else {
            if ((errors as any).company) {
                toast.error((errors as any).company.message);
            }
        }
    }, [submitCount]);

    const submit = async (data: FormData) => {
        data = withPreparedPhoneData(data);
        await withErrors<StaffFormData>(onSubmit(data, dispatch), setError);
    };

    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldInput
                            control={control}
                            name="firstName"
                            wrapperProps={{ label: validationSchema.fields.firstName.spec.label }}
                            error={errors.firstName?.message}
                        />
                        <FieldInput
                            control={control}
                            name="lastName"
                            wrapperProps={{ label: validationSchema.fields.lastName.spec.label }}
                            error={errors.lastName?.message}
                        />
                        <FieldInput
                            control={control}
                            name="email"
                            wrapperProps={{ label: validationSchema.fields.email.spec.label }}
                            error={errors.email?.message}
                            disabled={!!staff}
                        />
                        <FieldInput
                            control={control}
                            name="password"
                            type="password"
                            wrapperProps={{ label: validationSchema.fields.password.spec.label }}
                            error={errors.password?.message}
                        />
                        <FieldInput
                            control={control}
                            name="repeatPassword"
                            type="password"
                            wrapperProps={{ label: validationSchema.fields.repeatPassword.spec.label }}
                            error={errors.repeatPassword?.message}
                        />
                        {securityService.isGranted(userRole.ROLE_ADMIN) ? (
                            <FieldDropdown
                                control={control}
                                data={userRole.mapData()}
                                name="role"
                                wrapperProps={{ label: validationSchema.fields.role.spec.label }}
                                error={(errors.role as any)?.message}
                                disabled={!!staff}
                            />
                        ) : (
                            <FieldInput
                                control={control}
                                name={'role'}
                                type={'hidden'}
                                defaultValue={userRole.ROLE_STAFF}
                            />
                        )}
                        <FieldInternationalPhoneNumber
                            watch={watch}
                            name="phoneNumber"
                            clearErrors={clearErrors}
                            control={control}
                            label={validationSchema.fields.phoneNumber.spec.label}
                            error={errors.phoneNumber?.message}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
                <Button className={classNames('btn', 'btn-danger')} onClick={navigateBack} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faUndoAlt} /> Cancel
                </Button>
            </div>
        </form>
    );
};

export default StaffForm;
