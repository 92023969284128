import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import StatisticApi from 'src/services/end-points/statistic-api';
import { ModelsStatistic, ScanStatistic } from 'src/types/statistic';

export const getScanStatistic = createAsyncThunk<ScanStatistic, void, ThunkConfig>(
    'statistic/scan-get',
    async (_, thunkAPI) => {
        const { getState } = thunkAPI;
        const { statistic } = getState();

        return resolveApiCall(thunkAPI, statistic, async () => {
            const response = await StatisticApi.scanStatistic();
            return response.data;
        });
    },
);

export const getModelsStatistic = createAsyncThunk<
    ModelsStatistic[],
    { startDate: string; endDate: string; isApproved?: 0 | 1 },
    ThunkConfig
>('statistic/models-get', async (params, thunkAPI) => {
    const { getState } = thunkAPI;
    const { statistic } = getState();

    return resolveApiCall(thunkAPI, statistic, async () => {
        const response = await StatisticApi.modelsStatistic(params);
        return response.data;
    });
});
