import { FC } from 'react';
import dropdownFilter from './dropdown-filter';
import { FilterProps } from 'src/components/react-table/filters/types';

const booleanFilter: () => FC<FilterProps<Record<any, any>>> = () => {
    const filterOptions = [
        { name: 'No', value: 0 },
        { name: 'Yes', value: 1 },
    ];
    return dropdownFilter(filterOptions);
};

export default booleanFilter;
