import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Canvas } from 'react-three-fiber';
import Camera from './components/Camera';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import { Provider } from 'mobx-react';
import CreatedPoints from './components/CreatedPoints';
import ExistingPoints from './components/ExistingPoints';
import classNames from 'classnames';
import Loader from 'src/components/common/Loader';
import Light from './components/Light';
import LoadedModel from './components/LoadedModel';

export interface Props {
    onModelLoading?: (value: boolean) => void;
}

const ModelScene: React.FunctionComponent<Props> = observer(({ onModelLoading }: Props) => {
    const stores = useStores<Stores>();
    const { models: modelsStore, modelScene } = stores;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (modelsStore.activeModel?.fileUrl) {
            onModelLoading && onModelLoading(true);
            setLoading(true);
        }
    }, [modelsStore.activeModel?.fileUrl]);

    const onLoaded = () => {
        onModelLoading && onModelLoading(false);
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Canvas className={classNames('model-canvas')}>
                {/* need to use context again because it loses inside canvas */}
                <Provider {...stores}>
                    <Camera />
                    <Light />
                    <scene>
                        <axesHelper args={[10]} />
                        {modelScene.createMode && <CreatedPoints />}
                        <ExistingPoints display={!loading} />
                        <LoadedModel path={modelsStore.activeModel?.fileUrl} onLoaded={onLoaded} />
                    </scene>
                </Provider>
            </Canvas>
            {loading && <Loader />}
        </React.Fragment>
    );
});

export default ModelScene;
