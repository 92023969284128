import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { useHistory, useParams } from 'react-router-dom';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import scanConditionSchema from '../schema/condition-schema';
import { Paginated } from 'src/services/api-handlers/pagination';
import { useSelector } from 'react-redux';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import FieldFetchingDropdown from 'src/components/react-hook-form/fields/FieldFetchingDropdown';
import { Condition } from 'src/types/condition';
import { conditionListSelector, conditionLoadingSelector } from 'src/store/selectors/condition-selectors';
import { conditionList } from 'src/store/thunks/condition-thunks';
import router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { toast } from 'react-toastify';
import { fetchAll } from 'src/helpers/fetch-all';
import { ScanCondition } from 'src/types/scan-condition';
import { scanConditionList } from 'src/store/thunks/scan-condition-thunks';

export type ScanConditionFormData = {
    scanId: string;
    condition: SelectableItem | string;
};

type Params = {
    id: string;
};

interface ScanConditionFormProps {
    title: string;
    condition?: Condition | null;
    onSubmit: (data: ScanConditionFormData, dispatch: AppDispatch) => Promise<any>;
}

const ScanConditionForm: FC<ScanConditionFormProps> = ({ title, onSubmit }: ScanConditionFormProps) => {
    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<ScanConditionFormData>({
        resolver: yupResolver(scanConditionSchema),
        defaultValues: {},
    });
    const dispatch = useDispatch();
    const { id } = useParams<Params>();
    const history = useHistory();
    const conditionData: Paginated<Condition> = useSelector(conditionListSelector);
    const [existingIds, setExistingIds] = useState<string[]>([]);

    const conditionLoading = useSelector(conditionLoadingSelector);
    const conditionMappedData: SelectableItem[] = conditionData.list
        .filter((value) => !existingIds.includes(value.id))
        .map(({ name, id }) => ({
            name,
            value: id,
        }));

    const submit = (shouldRedirect: boolean) => async (data: ScanConditionFormData) => {
        const res = await withErrors<ScanConditionFormData>(onSubmit(data, dispatch), setError);
        if (res !== false) {
            if (shouldRedirect) {
                history.push(router.generate(routes.SCAN_CONDITION_LIST, { id }));
                return;
            }
            reset();
            fetchExistingScanConditions(id);
        }
    };

    const fetchScanConditions = useCallback((page: number, searchTerm?: string) => {
        return dispatch(conditionList({ filters: { name: searchTerm }, limit: -1, page })).unwrap();
    }, []);

    const navigateBack = useCallback(() => {
        history.goBack();
    }, []);

    const fetchExistingScanConditions = useCallback(async (scanId: string) => {
        const data = await fetchAll<ScanCondition>((page) => {
            return dispatch(scanConditionList({ scanId, limit: -1, page })).unwrap() as any;
        });

        setExistingIds(data.map(({ condition }) => condition.id));
    }, []);

    useEffect(() => {
        fetchExistingScanConditions(id);
    }, []);

    useEffect(() => {
        if ((errors as any)?._error?.message) {
            toast.error((errors as any)._error.message);
        }
    }, [(errors as any)?._error]);

    return (
        <form className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldFetchingDropdown
                            busy={conditionLoading}
                            disabled={conditionLoading}
                            control={control}
                            data={conditionMappedData}
                            fetchData={fetchScanConditions}
                            name="condition"
                            wrapperProps={{ label: scanConditionSchema.fields.condition.spec.label }}
                            textField={'name'}
                            valueField={'value'}
                            error={(errors?.condition as any)?.message}
                            page={conditionData.page}
                            maxPage={conditionData.totalPages as number}
                            fetchOnSearch
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                <Button
                    type="button"
                    onClick={handleSubmit(submit(true))}
                    className={classNames('btn-success')}
                    disabled={isSubmitting}
                >
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
                <Button
                    type="button"
                    onClick={handleSubmit(submit(false))}
                    className={classNames('btn-success')}
                    disabled={isSubmitting}
                >
                    <FontAwesomeIcon icon={faSave} />
                    <FontAwesomeIcon icon={faList} /> Save and create new
                </Button>
                <Button className={classNames('btn', 'btn-danger')} onClick={navigateBack} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faUndoAlt} /> Cancel
                </Button>
            </div>
        </form>
    );
};

export default memo(ScanConditionForm);
