import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import React, { FC } from 'react';
import { QueryParams } from 'src/types/grid';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import ScanActionsColumn from 'src/screens/Scan/components/ScanActionsColumn';
import { Scan } from 'src/types/scan';

type Props = {
    data: Paginated<Scan>;
    getData: (params: QueryParams) => void;
};

const scanColumns: Column<Scan>[] = [
    {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
    },
    {
        Header: 'Id',
        accessor: 'id',
    },
    {
        Header: 'Operator',
        accessor: 'operator',
    },
    {
        Header: 'User',
        accessor: 'patient',
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: ({ id }) => id,
        Cell: (props: any) => <ScanActionsColumn {...props} actions={['show']} />,
        disableSortBy: true,
        disableFilters: true,
        width: '10%',
    },
];

const ScanList: FC<Props> = ({ data, getData }: Props) => {
    return <Grid<Scan> columns={scanColumns} data={data} getData={getData} defaultSorting={{ createdAt: 'DESC' }} />;
};

export default ScanList;
