import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import { QueryParams } from 'src/types/grid';
import { Model } from 'src/types/model';
import React, { FC, memo } from 'react';
import modelPose from 'src/enumerables/model-pose';
import ModelActionsColumn from 'src/screens/Scan/screens/Model/components/ModelActionsColumn';
import dropdownFilter from 'src/components/react-table/filters/dropdown-filter';
import textFilter from 'src/components/react-table/filters/text-filter';
import BooleanBadge from 'src/components/BooleanBadge';
import booleanFilter from 'src/components/react-table/filters/boolean-filter';
import dateTimeRangeFilter from 'src/components/react-table/filters/datetime-range-filter';

type Props = {
    data: Paginated<Model>;
    getData: (params: QueryParams) => void;
};

const modelColumns: Column<Model>[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Pose',
        id: 'pose',
        accessor: ({ pose }) => modelPose.getLabel(pose),
        Filter: dropdownFilter<Model>(modelPose.mapData()),
        disableSortBy: true,
    },
    {
        Header: 'Is Approved',
        accessor: 'isApproved',
        Filter: booleanFilter(),
        Cell: ({ value }: { value: boolean }) => <BooleanBadge value={value} />,
        id: 'isApproved',
        width: '8%',
        disableSortBy: true,
    },
    {
        Header: 'Version',
        id: 'version',
        accessor: 'version',
        Filter: textFilter('number'),
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Filter: dateTimeRangeFilter({ nameFrom: 'createdFrom', nameTo: 'createdTo' }),
        Cell: DateTimeColumn,
    },
    {
        Header: 'Actions',
        id: 'actions',
        Cell: (props: any) => <ModelActionsColumn {...props} actions={['points']} />,
        disableSortBy: true,
        disableFilters: true,
        width: '10%',
    },
];

const ModelList: FC<Props> = ({ data, getData }: Props) => {
    return <Grid<Model> columns={modelColumns} data={data} getData={getData} />;
};

export default memo(ModelList);
