import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import CountUp from 'src/components/CountUp';
import classNames from 'classnames';
import styles from '../../../components/layout/sidebar/assets/sidebar-item-tree.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface DashboardInfoBoxProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'white';
    amount: string;
    helpText?: string;
    amountText: string;
    duration?: number;
    loading?: boolean;
}

const DashboardInfoBox: FC<DashboardInfoBoxProps> = ({
    variant = 'primary',
    amount,
    duration,
    loading,
    className,
    amountText,
    helpText,
    ...args
}: DashboardInfoBoxProps) => {
    return (
        <div className={'col-lg-3 col-6'} {...args}>
            <div className={classNames(`small-box bg-${variant}`, className)}>
                <div className="inner">
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <div>
                            <CountUp amount={amount} duration={duration} className={'h4'} />
                            <p>
                                {amountText}{' '}
                                {!!helpText && (
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={<Tooltip id={`id-${+new Date()}`}>{helpText}</Tooltip>}
                                    >
                                        <FontAwesomeIcon
                                            className={classNames(styles.icon, 'nav-icon')}
                                            icon={faQuestionCircle}
                                        />
                                    </OverlayTrigger>
                                )}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardInfoBox;
