import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Grid, { Props as GridProps } from './Grid';
import { DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd';
import { Column, Row as RowType } from 'react-table';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './assets/sortable-grid.module.scss';
import { QueryParams, SortDirection } from 'src/types/grid';
import { isEqual } from 'lodash';

interface RowProps<T extends Record<any, any>> {
    row: RowType<T>;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    onDrop?: (item: T, index: number) => void;
    canDrag: boolean;
}

const Row = <T extends Record<any, any>>({ row, index, moveRow, onDrop, canDrag }: RowProps<T>) => {
    const dropRef = useRef<HTMLTableRowElement>(null);
    const dragRef = useRef<HTMLTableCellElement>(null);

    const [, drop] = useDrop<any>({
        accept: 'row',
        drop: (item) => onDrop && onDrop(item.original, item.index),
        hover: (item, monitor) => {
            // @see https://react-table.tanstack.com/docs/examples/row-dnd
            if (!dropRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset() as XYCoord;
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
            // but it's good here for the sake of performance to avoid expensive index searches.

            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: { original: row.original, index },
        type: 'row',
        collect: (monitor) => ({
            isDragging: row.original.id === monitor.getItem()?.original.id,
        }),
    });

    preview(drop(dropRef));
    drag(dragRef);

    return (
        <tr
            ref={dropRef}
            style={{
                opacity: isDragging ? 0.01 : 1,
            }}
        >
            {row.cells.map((cell, i) => {
                return i === 0 ? (
                    canDrag ? (
                        <td className={styles.dragCell} ref={dragRef} {...cell.getCellProps()}>
                            <span />
                        </td>
                    ) : (
                        <td {...cell.getCellProps()} />
                    )
                ) : (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
            })}
        </tr>
    );
};

interface Props<T> extends GridProps<T> {
    onDrop?: (item: T, index: number) => void;
    refetchOnDrop?: boolean;
    defaultSorting: Partial<{ [x in keyof T]: SortDirection }>;
}

function SortableGrid<T extends Record<any, any>>({
    data,
    columns,
    onDrop,
    getData,
    refetchOnDrop,
    ...props
}: Props<T>) {
    const [records, setRecords] = useState(data);
    const [lastFetchParams, setLastFetchParams] = useState<QueryParams | null>(null);

    const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
        setRecords((prev) => {
            const dragRecord = prev.list[dragIndex];
            const list = [...prev.list];
            list.splice(dragIndex, 1);
            list.splice(hoverIndex, 0, dragRecord);

            return { ...prev, list };
        });
    }, []);

    const preparedColumns = useMemo<Column<T>[]>(() => {
        return [
            {
                Header: () => '',
                id: 'draggable',
                disableFilters: true,
                disableSortBy: true,
                width: 15,
            },
            ...columns,
        ];
    }, [columns]);

    const canDrag = isEqual(props.defaultSorting, lastFetchParams?.sorting);

    const getDataWrap = useCallback(
        (params: QueryParams) => {
            setLastFetchParams(params);
            return getData(params);
        },
        [getData],
    );

    const onDropWrap = useCallback(
        async (item: T, index: number) => {
            onDrop && (await onDrop(item, index));
            refetchOnDrop && lastFetchParams && (await getData(lastFetchParams));
        },
        [onDrop, lastFetchParams],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setRecords(data);
        }, 150);

        return () => clearTimeout(timer);
    }, [data]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid
                {...props}
                columns={preparedColumns}
                data={records}
                getData={getDataWrap}
                renderRow={({ row, index }) => (
                    <Row row={row} index={index} moveRow={moveRow} onDrop={onDrop && onDropWrap} canDrag={canDrag} />
                )}
            />
        </DndProvider>
    );
}

export default SortableGrid;
