import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'staffs';

function get(id: string): AxiosPromise<any> {
    return EndPointService.get(`/${ROUTE}/${id}`);
}

function list(params: any): AxiosPromise<any> {
    return EndPointService.get(`/${ROUTE}`, params);
}

function create(payload: any): AxiosPromise<any> {
    return EndPointService.post(`/${ROUTE}`, payload);
}

function update(id: string, payload: any): AxiosPromise<any> {
    return EndPointService.patch(`/${ROUTE}/${id}`, payload);
}

function remove(id: string): AxiosPromise<any> {
    return EndPointService.delete(`/${ROUTE}/${id}`);
}

function updatePhone(payload: any): AxiosPromise<any> {
    return EndPointService.patch(`/${ROUTE}/phone`, payload);
}

function verifyPhone(payload: any): AxiosPromise<any> {
    return EndPointService.post(`/${ROUTE}/verify-phone-code`, payload);
}

function sendPhoneCode(): AxiosPromise<any> {
    return EndPointService.post(`/${ROUTE}/send-phone-code`);
}

export default {
    get,
    list,
    create,
    update,
    remove,
    updatePhone,
    verifyPhone,
    sendPhoneCode,
};
