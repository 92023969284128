import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';
import { Condition } from 'src/types/condition';
import { ConditionsApi } from 'src/services/end-points';
import { ConditionsFormData } from 'src/screens/Condition/components/ConditionForm';

export const conditionList = createAsyncThunk<Paginated<Condition>, QueryParams, ThunkConfig>(
    'condition/list',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { condition } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            condition,
            async () =>
                ConditionsApi.list({
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            condition.data,
        );
    },
);

export const getCondition = createAsyncThunk<Condition, Condition['id'], ThunkConfig>(
    'condition/get',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { condition } = getState();

        return resolveApiCall(thunkAPI, condition, async () => {
            const response = await ConditionsApi.get(payload);
            return response.data;
        });
    },
);

export const deleteCondition = createAsyncThunk<void, Condition['id'], ThunkConfig>(
    'condition/delete',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { condition } = getState();

        return resolveApiCall(thunkAPI, condition, async () => ConditionsApi.remove(payload));
    },
);

export const createCondition = createAsyncThunk<Condition, { [key: string]: any }, ThunkConfig>(
    'condition/create',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { condition } = getState();

        return resolveApiCall(
            thunkAPI,
            condition,
            async () => {
                const response = await ConditionsApi.create(convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const updateCondition = createAsyncThunk<Condition, { id: string } & ConditionsFormData, ThunkConfig>(
    'condition/update',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { condition } = getState();

        return resolveApiCall(
            thunkAPI,
            condition,
            async () => {
                const response = await ConditionsApi.update(id, payload);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);
