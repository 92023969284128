import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../../navigation/history';
import app from './app-reducer';
import auth from './auth-reducer';
import scan from './scan-reducer';
import model from './model-reducer';
import modelPoint from './model-point-reducer';
import staff from './staff-reducer';
import bodyPart from './body-part-reducer';
import condition from './condition-reducer';
import scanCondition from './scan-condition-reducer';
import scanDeviceInfo from './scan-device-info-reducer';
import scanScore from './scan-score-reducer';
import bodyPartValidation from './body-part-validation-reducer';
import scanPhyScore from './scan-phy-score-reducer';
import statistic from './statistic-reducer';
import modelReview from './model-review-reducer';
import scanReview from './scan-review-reducer';

export default combineReducers({
    app,
    auth,
    scan,
    model,
    staff,
    bodyPart,
    condition,
    scanScore,
    modelPoint,
    modelReview,
    scanCondition,
    statistic,
    scanReview,
    scanPhyScore,
    scanDeviceInfo,
    bodyPartValidation,
    router: connectRouter(history),
});
