import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import React, { FC } from 'react';
import { QueryParams } from 'src/types/grid';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import { ScanScore } from 'src/types/scan-score';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import EditableScoreValueColumn from './EditableScoreValueColumn';
import dropdownFilter from 'src/components/react-table/filters/dropdown-filter';
import scanScoreValue from 'src/enumerables/scan-score-value';
import bodyPartFilter from 'src/components/react-table/filters/body-part-filter';

type Props = {
    data: Paginated<ScanScore>;
    getData: (params: QueryParams) => void;
};

type Params = {
    id: string;
};

const scanColumns: Column<ScanScore>[] = [
    {
        Header: 'Body Part',
        accessor: 'bodyPart',
        Cell: ({ value: { name } }) => name,
        Filter: bodyPartFilter<ScanScore>({ filters: { decisionTree: 1 }, fetchingDropdown: false }),
    },
    {
        Header: 'Value',
        accessor: 'value',
        Cell: (props) => <EditableScoreValueColumn props={props} />,
        Filter: dropdownFilter<ScanScore>(scanScoreValue.mapData()),
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
];

const ScoreList: FC<Props> = ({ data, getData }: Props) => {
    const { id } = useParams<Params>();
    return (
        <Grid<ScanScore>
            columns={scanColumns}
            title={
                <div className={classNames('d-flex', 'justify-content-end')}>
                    <Link
                        className={classNames('text-decoration-none')}
                        to={Router.generate(routes.SCAN_SCORES_CREATE, { id })}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Create
                    </Link>
                </div>
            }
            data={data}
            getData={getData}
            defaultSorting={{ createdAt: 'DESC' }}
        />
    );
};

export default ScoreList;
