import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import StaffListScreen from 'src/screens/Staff/screens/List';
import Restricted from 'src/components/auth/Restricted';
import userRole from 'src/enumerables/user-role';
import StaffViewScreen from '../../screens/Staff/screens/View';
import StaffCreateScreen from 'src/screens/Staff/screens/Create';
import StaffEditScreen from 'src/screens/Staff/screens/Edit';
import Authenticated from 'src/components/auth/Authenticated';

const staffRoutes = [
    <Route
        exact
        key={routes.STAFF_LIST}
        path={routes.STAFF_LIST}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <StaffListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.STAFF_VIEW}
        path={routes.STAFF_VIEW}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <StaffViewScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.STAFF_CREATE}
        path={routes.STAFF_CREATE}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <StaffCreateScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.STAFF_EDIT}
        path={routes.STAFF_EDIT}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <StaffEditScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default staffRoutes;
