import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'points';

const remove = (id: string): AxiosPromise<any> => EndPointService.delete(`/${ROUTE}/${id}`);

const list = (params: any): AxiosPromise<any> => EndPointService.get(`/${ROUTE}`, params);

const create = (params: any): AxiosPromise<any> => EndPointService.post(`/${ROUTE}`, params);

const update = (id: string, params: any): AxiosPromise<any> => EndPointService.patch(`/${ROUTE}/${id}`, params);

export default {
    remove,
    list,
    update,
    create,
};
