import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import FieldInput from 'src/components/react-hook-form/fields/FieldInput';
import conditionSchema from 'src/screens/Condition/schema/condition-schema';
import FieldDropdown from 'src/components/react-hook-form/fields/FieldDropdown';
import { useSelector } from 'react-redux';
import { Paginated } from 'src/services/api-handlers/pagination';
import { Condition } from 'src/types/condition';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import { bodyPartListSelector } from 'src/store/selectors/body-part-selectors';
import { bodyPartList } from 'src/store/thunks/body-part-thunks';
import { BodyPart } from 'src/types/body-part';

export type ConditionsFormData = {
    name: string;
    bodyPart: SelectableItem | string;
};

interface ConditionsFormProps {
    title: string;
    condition?: Condition | null;
    onSubmit: (data: ConditionsFormData, dispatch: AppDispatch) => Promise<any>;
}

const ConditionForm: FC<ConditionsFormProps> = ({ title, onSubmit, condition }: ConditionsFormProps) => {
    const { list }: Paginated<BodyPart> = useSelector(bodyPartListSelector);
    const bodyPartSelectableList: SelectableItem[] = list.map(({ name, id }) => ({ name, value: id }));
    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, submitCount, isSubmitSuccessful, errors },
    } = useForm<ConditionsFormData>({
        resolver: yupResolver(conditionSchema),
        defaultValues: { ...(condition || {}) },
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const submit = async (data: ConditionsFormData) => {
        await withErrors<ConditionsFormData>(onSubmit(data, dispatch), setError);
    };

    const navigateBack = useCallback(() => {
        history.goBack();
    }, []);

    useEffect(() => {
        dispatch(bodyPartList({ filters: { topLevel: 1 } }));
    }, []);

    useEffect(() => {
        if (isSubmitSuccessful) {
            toast.success('Condition data was saved');
            navigateBack();
        } else {
            if ((errors as any).company) {
                toast.error((errors as any).company.message);
            }
        }
    }, [submitCount]);

    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldInput
                            control={control}
                            name="name"
                            wrapperProps={{ label: conditionSchema.fields.name.spec.label }}
                            error={errors.name?.message}
                        />
                        <FieldDropdown
                            control={control}
                            data={bodyPartSelectableList}
                            name="bodyPart"
                            textField={'name'}
                            valueField={'value'}
                            wrapperProps={{ label: conditionSchema.fields.bodyPart.spec.label }}
                            error={(errors.bodyPart as any)?.message}
                            disabled={!!condition?.bodyPart}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
                <Button className={classNames('btn', 'btn-danger')} onClick={navigateBack} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faUndoAlt} /> Cancel
                </Button>
            </div>
        </form>
    );
};

export default ConditionForm;
