import { MobXProviderContext } from 'mobx-react';
import React, { Context } from 'react';

function useStores<T extends Record<string, any>>(): T;
function useStores<T extends Record<string, any>>(name: keyof T): T[keyof T];
function useStores<T extends Record<string, any>>(name?: keyof T): T | T[keyof T] {
    const stores = React.useContext<T>(MobXProviderContext as Context<T>);

    return name ? stores[name] : stores;
}

export default useStores;
