import React, { FC, useCallback } from 'react';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { bodyPartList, getBodyPart } from 'src/store/thunks/body-part-thunks';
import { Paginated } from 'src/services/api-handlers/pagination';
import { BodyPart } from 'src/types/body-part';
import { useSelector } from 'react-redux';
import {
    bodyPartCurrentSelector,
    bodyPartListSelector,
    bodyPartLoadingSelector,
} from 'src/store/selectors/body-part-selectors';
import BodyPartList from 'src/screens/BodyPart/components/BodyPartList';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import MainLayout from 'src/components/layout/MainLayout';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useParams } from 'react-router';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { makeBreadcrumbs } from 'src/screens/BodyPart/helpers/breadcrumb';
import Resource, { ResourceCallbacks } from 'src/components/Resource';

type RouteParams = {
    id: string;
};

interface Props {}

const BodyPartChildrenScreen: FC<Props> = () => {
    const dispatch = useDispatch();
    const { id } = useParams<RouteParams>();
    const loading: boolean = useSelector(bodyPartLoadingSelector);
    const childrenData: Paginated<BodyPart> = useSelector(bodyPartListSelector);
    const bodyPart = useSelector(bodyPartCurrentSelector);

    const getChildrenData = useCallback(
        (params: QueryParams) => {
            dispatch(bodyPartList({ ...params, filters: { ...params.filters, parent: id } }));
        },
        [id],
    );

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getBodyPart(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title={bodyPart?.name || ''}
                    breadcrumbs={
                        bodyPart
                            ? [
                                  { title: 'Body parts', link: Router.generate(routes.BODY_PART_LIST) },
                                  ...makeBreadcrumbs(bodyPart),
                              ]
                            : []
                    }
                />
                <Content>
                    <BodyPartList data={childrenData} getData={getChildrenData} />
                </Content>
                {loading && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default BodyPartChildrenScreen;
