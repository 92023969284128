import React, { FC, memo, useEffect } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { AppDispatch } from 'src/store/configure-store';
import { useParams } from 'react-router';
import { scanScoreLoadingSelector } from 'src/store/selectors/scan-score-selectors';
import { createScanScore } from 'src/store/thunks/scan-score-thunks';
import ScoreForm, { ScoreFormData } from 'src/screens/Scan/screens/Score/components/ScoreForm';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';
import { useDispatch } from 'src/hooks/dispatch';
import { getScan } from 'src/store/thunks/scan-thunks';

interface Props {}

const ScoreCreateScreen: FC<Props> = () => {
    const dispatch = useDispatch();
    const loading = useSelector(scanScoreLoadingSelector);
    const scan = useSelector(scanCurrentSelector);
    const { id } = useParams<{ id: string }>();
    const handleSubmit = async (data: ScoreFormData, dispatch: AppDispatch) => {
        await dispatch(createScanScore({ scanId: id, ...data })).unwrap();
    };

    useEffect(() => {
        if (scan?.id !== id) {
            dispatch(getScan(id));
        }
    }, [id]);

    return (
        <MainLayout>
            <ContentHeader
                title="Create score"
                breadcrumbs={[
                    { title: 'Sessions', link: Router.generate(routes.SCAN_LIST) },
                    { title: `${scan?.name}`, link: Router.generate(routes.SCAN_VIEW, { id }) },
                    { title: `Scores`, link: Router.generate(routes.SCAN_SCORES_LIST, { id }) },
                ]}
            />
            <Content>
                <ScoreForm title={`Create score`} onSubmit={handleSubmit} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default memo(ScoreCreateScreen);
