import { createSlice } from '@reduxjs/toolkit';
import {
    listStaff,
    deleteStaff,
    getStaff,
    createStaff,
    updateStaff,
    updatePhone,
    verifyPhone,
    sendPhoneCode,
} from 'src/store/thunks/staff-thunks';
import { ReducerState } from 'src/store/configure-store';
import { crudHelpers, takeOne, takeEvery } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { Staff } from 'src/types/staff';

export interface StaffState extends ReducerState {
    data: Paginated<Staff>;
    current: Staff | null;
}

const initialState: StaffState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'staff',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listStaff.pending, takeEvery.pendingActionCase)
            .addCase(listStaff.rejected, takeEvery.rejectedActionCase)
            .addCase(listStaff.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(getStaff.pending, takeEvery.pendingActionCase)
            .addCase(getStaff.rejected, takeEvery.rejectedActionCase)
            .addCase(getStaff.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterGet);
            })

            .addCase(createStaff.pending, takeOne.pendingActionCase)
            .addCase(createStaff.rejected, takeOne.rejectedActionCase)
            .addCase(createStaff.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
            })

            .addCase(updateStaff.pending, takeOne.pendingActionCase)
            .addCase(updateStaff.rejected, takeOne.rejectedActionCase)
            .addCase(updateStaff.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            })

            .addCase(deleteStaff.pending, takeOne.pendingActionCase)
            .addCase(deleteStaff.rejected, takeOne.rejectedActionCase)
            .addCase(deleteStaff.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterDelete);
            })

            .addCase(updatePhone.pending, takeOne.pendingActionCase)
            .addCase(updatePhone.fulfilled, takeOne.fulfilledActionCase)
            .addCase(updatePhone.rejected, takeOne.rejectedActionCase)

            .addCase(verifyPhone.pending, takeOne.pendingActionCase)
            .addCase(verifyPhone.fulfilled, takeOne.fulfilledActionCase)
            .addCase(verifyPhone.rejected, takeOne.rejectedActionCase)

            .addCase(sendPhoneCode.pending, takeOne.pendingActionCase)
            .addCase(sendPhoneCode.fulfilled, takeOne.fulfilledActionCase)
            .addCase(sendPhoneCode.rejected, takeOne.rejectedActionCase);
    },
});

export const {} = slice.actions;

export default slice.reducer;
