import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import styles from '../assets/scan-device-form.module.scss';
import { AppDispatch } from 'src/store/configure-store';
import scanDeviceFormSchema from 'src/screens/Scan/screens/View/schema/scanDeviceFormSchema';
import { getScanDeviceInfo, updateScanDeviceInfo } from 'src/store/thunks/scan-device-info-thunks';
import { scanDeviceInfoCurrentSelector } from 'src/store/selectors/scan-device-info-selectors';
import FieldInput from 'src/components/react-hook-form/fields/FieldInput';
import FieldDropdown from 'src/components/react-hook-form/fields/FieldDropdown';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import scanDeviceType from 'src/enumerables/scan-device-type';
import { ScanDeviceInfo } from 'src/types/scan-device-info';
import { Scan } from 'src/types/scan';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export interface ScanDeviceFormData {
    device: SelectableItem | string;
    version: string;
}

type ScanDeviceFormProps = {
    scan: Scan;
};

const ScanDeviceForm: FC<ScanDeviceFormProps> = ({ scan }: ScanDeviceFormProps) => {
    const dispatch = useDispatch();
    const scanDeviceInfo = useSelector(scanDeviceInfoCurrentSelector);

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<ScanDeviceFormData>({
        resolver: yupResolver(scanDeviceFormSchema),
    });

    const onSubmit = async (data: ScanDeviceFormData, dispatch: AppDispatch) => {
        await dispatch(
            updateScanDeviceInfo({ ...data, scanId: scan.id } as { scanId: string } & Partial<ScanDeviceInfo>),
        ).unwrap();
        toast.success('Data was saved');
    };

    const submit = async (data: ScanDeviceFormData) => {
        await withErrors<ScanDeviceFormData>(onSubmit(data, dispatch), setError);
    };

    useEffect(() => {
        dispatch(getScanDeviceInfo(scan.id));
    }, [scan.id]);

    useEffect(() => {
        scanDeviceInfo && reset(scanDeviceInfo);
    }, [scanDeviceInfo]);

    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('form')}>
            <div className={classNames('row d-flex align-items-center')}>
                <div className={classNames('col-md-5')}>
                    <FieldDropdown
                        control={control}
                        data={scanDeviceType.mapData()}
                        name="device"
                        wrapperProps={{ label: scanDeviceFormSchema.fields.device.spec.label }}
                        error={(errors.device as any)?.message}
                    />
                </div>
                <div className={classNames('col-md-4')}>
                    <FieldInput
                        control={control}
                        name="version"
                        wrapperProps={{ label: scanDeviceFormSchema.fields.version.spec.label }}
                        error={errors.version?.message}
                    />
                </div>
                <div className={classNames('col-md-3', styles.saveButton)}>
                    <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                        Save
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default ScanDeviceForm;
