import React, { FC, memo } from 'react';
import classNames from 'classnames';
import styles from '../assets/model-review-info.module.scss';
import { ModelReview } from 'src/types/model-review';
import { DATETIME_FORMAT } from 'src/config/env';
import moment from 'moment';
import modelReviewStatus from '../../../../../../../enumerables/model-review-status';

interface Props {
    modelReview: ModelReview;
}

const ModelReviewInfo: FC<Props> = ({ modelReview }: Props) => {
    const modelReviewCreatedAt = moment(modelReview.createdAt).format(DATETIME_FORMAT);
    const modelReviewUpdatedAt = moment(modelReview.updatedAt).format(DATETIME_FORMAT);

    return (
        <div className={classNames('table-responsive')}>
            <table className={classNames('table', styles.tableInfo)}>
                <tbody>
                    <tr>
                        <th>Id</th>
                        <td>{modelReview.id}</td>
                    </tr>
                    <tr>
                        <th>Comment</th>
                        <td>{modelReview.comment}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{modelReviewStatus.getLabel(modelReview.status)}</td>
                    </tr>
                    <tr>
                        <th>Created At</th>
                        <td>{modelReviewCreatedAt}</td>
                    </tr>
                    <tr>
                        <th>Updated At</th>
                        <td>{modelReviewUpdatedAt}</td>
                    </tr>
                    <tr>
                        <th>Approved At</th>
                        <td>{modelReview.approvedAt}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default memo(ModelReviewInfo);
