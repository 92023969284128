import React, { PropsWithChildren } from 'react';
import styles from './assets/base-layout.module.scss';
import classNames from 'classnames';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Main from './main/Main';
import Footer from './footer/Footer';

interface Props {}

const MainLayout: React.FunctionComponent<Props> = ({ children }: PropsWithChildren<Props>) => (
    <div className={classNames(styles.layout, 'wrapper')}>
        <Header />
        <Sidebar />
        <Main className={classNames('content-wrapper', 'position-relative')}>{children}</Main>
        <Footer />
    </div>
);

export default MainLayout;
