import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const scanScoreStateSelector = (state: StoreState) => state.scanScore;
export const scanScoreListSelector = createDraftSafeSelector(scanScoreStateSelector, (state) => state.data);
export const scanScoreErrorSelector = createDraftSafeSelector(scanScoreStateSelector, (state) => state.error);
export const scanScoreLoadingSelector = createDraftSafeSelector(
    scanScoreStateSelector,
    (state) => state.loading === 'loading',
);
export const scanScoreCurrentSelector = createDraftSafeSelector(scanScoreStateSelector, (state) => state.current);
