import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import BodyPartApi from 'src/services/end-points/body-part-api';
import { BodyPart, BodyPartWithChildren } from 'src/types/body-part';

export const bodyPartList = createAsyncThunk<Paginated<BodyPart>, QueryParams, ThunkConfig>(
    'bodyPart/list',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { bodyPart } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            bodyPart,
            async () =>
                BodyPartApi.list({
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            bodyPart.data,
        );
    },
);

export const getBodyPart = createAsyncThunk<BodyPart, BodyPart['id'], ThunkConfig>(
    'bodyPart/get',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { bodyPart } = getState();

        return resolveApiCall(thunkAPI, bodyPart, async () => {
            const response = await BodyPartApi.get(payload);
            return response.data;
        });
    },
);

export const deleteBodyPart = createAsyncThunk<void, BodyPart['id'], ThunkConfig>(
    'bodyPart/delete',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { bodyPart } = getState();

        return resolveApiCall(thunkAPI, bodyPart, async () => BodyPartApi.remove(payload));
    },
);

export const updateBodyPart = createAsyncThunk<BodyPart, { id: string } & Partial<BodyPart>, ThunkConfig>(
    'bodyPart/update',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { bodyPart } = getState();

        return resolveApiCall(
            thunkAPI,
            bodyPart,
            async () => {
                const response = await BodyPartApi.update(id, payload);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const bodyPartTree = createAsyncThunk<BodyPartWithChildren[], void, ThunkConfig>(
    'bodyPart/tree',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { bodyPart } = getState();

        return resolveApiCall(thunkAPI, bodyPart, async () => {
            const response = await BodyPartApi.tree();
            return response.data;
        });
    },
);
