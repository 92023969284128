import React from 'react';
import { Route } from 'react-router';
import routes from '../routes';
import Authenticated from 'src/components/auth/Authenticated';
import ProfileScreen from 'src/screens/Profile';
import { DashboardScreen } from 'src/screens/Dashboard';

const generalRoutes: any[] = [
    <Route
        exact
        key={routes.DASHBOARD}
        path={routes.DASHBOARD}
        render={() => (
            <Authenticated>
                <DashboardScreen />
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.PROFILE}
        path={routes.PROFILE}
        render={() => (
            <Authenticated>
                <ProfileScreen />
            </Authenticated>
        )}
    />,
];

export default generalRoutes;
