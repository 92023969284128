import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const scanDeviceInfoStateSelector = (state: StoreState) => state.scanDeviceInfo;
export const scanScoreErrorSelector = createDraftSafeSelector(scanDeviceInfoStateSelector, (state) => state.error);
export const scanScoreLoadingSelector = createDraftSafeSelector(
    scanDeviceInfoStateSelector,
    (state) => state.loading === 'loading',
);

export const scanDeviceInfoCurrentSelector = createDraftSafeSelector(
    scanDeviceInfoStateSelector,
    (state) => state.current,
);
