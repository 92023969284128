import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { Coordinates, Point, PointCreateData } from '../types/points';
import { convertValuesToObject } from 'src/utils/convert-values';
import ConfigStore from 'src/stores/config.store';
import { User } from 'src/types/user';
import { isEqual } from 'lodash';
import { SelectableItem } from 'src/utils/selectable-item';

export type PointsFilter<T extends Point> = Partial<Record<keyof T, any>>;

type PointsApiListArgs = {
    model?: string;
    type?: string;
    sort?: { bodyPart?: 'ASC' | 'DESC' };
};

export type PointsApiCreateArgs = {
    bodyPart: SelectableItem | string;
    coordinates: Coordinates;
    model: string;
    type: string;
};

export interface PointsApi {
    list: (args: Partial<PointsApiListArgs>) => Promise<Point[]>;
    create: (payload: PointsApiCreateArgs) => Promise<Point>;
    update: (id: Point['id'], payload: Partial<PointsApiCreateArgs>) => Promise<Point>;
    remove: (id: Point['id']) => Promise<void>;
}

export class PointsStore {
    @observable
    public points: IObservableArray<Point> = observable([]);
    @observable
    public pointsLoading = false;
    @observable
    public pointsError: string | null = null;
    @observable
    public config: ConfigStore;
    @observable
    public filter: PointsFilter<Point> = {};
    @computed
    get filteredPoints(): IObservableArray<Point> | Point[] {
        if (Object.keys(this.filter).length === 0) {
            return this.points;
        }
        const filterKeys = Object.keys(this.filter) as Array<keyof PointsFilter<Point>>;

        return this.points.filter((point) => {
            for (const key of filterKeys) {
                if (!isEqual(point[key as keyof Point], this.filter[key as keyof Point])) {
                    return false;
                }
            }

            return true;
        });
    }
    @computed
    get lastPoint(): Point | null {
        return this.points.length ? this.points[this.points.length - 1] : null;
    }
    @computed
    get users(): User[] {
        const ids: any[] = [];

        return this.points
            .filter(({ createdBy }) => {
                if (ids.includes(createdBy?.id)) {
                    return false;
                }
                ids.push(createdBy?.id);

                return createdBy;
            })
            .map(({ createdBy }) => createdBy as User);
    }

    constructor(config: ConfigStore) {
        this.config = config;
        makeObservable(this);
    }

    @action
    public setPoints = (points: Point[]) => {
        this.points = observable(points);
    };

    @action
    public createPoint = async (data: PointCreateData) => {
        this.pointsLoading = true;
        this.pointsError = null;

        await this.config.triggerEvent('onPointCreate', [convertValuesToObject(data) as PointCreateData]);
        this.pointsLoading = false;
    };

    @action
    public createPoints = async (data: { point: PointCreateData; coordinates: Coordinates[]; type: string }) => {
        this.pointsLoading = true;
        this.pointsError = null;

        const promises = data.coordinates.map((coordinates) => {
            return this.config.triggerEvent('onPointCreate', [
                convertValuesToObject({ ...data.point, type: data.type, coordinates }) as PointCreateData,
            ]);
        });

        await Promise.all(promises);
        this.pointsLoading = false;
    };

    @action
    public updatePoint = async (id: Point['id'], data: PointCreateData) => {
        this.pointsLoading = true;
        this.pointsError = null;

        await this.config.triggerEvent('onPointUpdate', [id, convertValuesToObject(data) as PointCreateData]);
        this.pointsLoading = false;
    };

    @action
    public removePoint = async (id: Point['id']) => {
        this.pointsLoading = true;
        this.pointsError = null;

        await this.config.triggerEvent('onPointDelete', [id]);
        this.pointsLoading = false;
    };

    @action
    public setFilter = (filter: PointsFilter<Point>) => {
        this.filter = filter;
    };
}

export default PointsStore;
