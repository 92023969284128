import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { modelListSelector, modelLoadingSelector } from 'src/store/selectors/model-selectors';
import { Paginated } from 'src/services/api-handlers/pagination';
import ModelList from 'src/screens/Model/components/ModelList';
import { modelList } from 'src/store/thunks/model-thunks';
import { Model } from 'src/types/model';

const ModelListScreen: FC = () => {
    const dispatch = useDispatch();
    const loading: boolean = useSelector(modelLoadingSelector);
    const data: Paginated<Model> = useSelector(modelListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(modelList(params));
    }, []);

    return (
        <MainLayout>
            <ContentHeader title="Models" />
            <Content>
                <ModelList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default memo(ModelListScreen);
