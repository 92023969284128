import React from 'react';
import { CellProps } from 'react-table';
import { faEye, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { routes } from '../../../../../navigation';
import Router from '../../../../../navigation/router';
import classNames from 'classnames';
import styles from '../assets/actions-column.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Staff } from 'src/types/staff';
import DeleteStaffAction from '../../../components/DeleteStaffAction';

interface Props extends CellProps<Staff> {
    actions: Array<'view' | 'edit' | 'delete'>;
}

const ActionsColumn: React.FunctionComponent<Props> = React.memo(
    ({
        cell: {
            row: { original },
            value,
        },
        actions,
    }: Props) => (
        <div className={classNames(styles.container, 'btn-group')}>
            {actions.includes('view') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-patient-view">View</Tooltip>}>
                    <Link to={Router.generate(routes.STAFF_VIEW, { id: value })}>
                        <FontAwesomeIcon icon={faEye} />
                    </Link>
                </OverlayTrigger>
            )}
            {actions.includes('edit') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-patient-edit">Edit</Tooltip>}>
                    <Link to={Router.generate(routes.STAFF_EDIT, { id: value })}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Link>
                </OverlayTrigger>
            )}
            {actions.includes('delete') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-patient-delete">Delete</Tooltip>}>
                    <div>
                        <DeleteStaffAction staff={original}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </DeleteStaffAction>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    ),
);

export default ActionsColumn;
