import React, { useCallback } from 'react';
import { DropdownList } from 'react-widgets';
import { DropdownProps } from 'react-widgets/cjs/DropdownList';

type Props = DropdownProps<any> & {
    allowClear?: boolean;
    onClear?: () => void;
};

const ClearableDropdownList: React.FC<Props> = ({ allowClear = false, onClear, ...restProps }: Props) => {
    const clearCallback = useCallback(
        (e: any) => {
            e.stopPropagation();

            onClear && onClear();
        },
        [onClear],
    );

    const valueWithClearButton = (text: string) => {
        return (
            <span className={'clearable-dropdown-value'}>
                {text} <i className={'fa fa-close'} onClick={clearCallback} />
            </span>
        );
    };
    return (
        <DropdownList
            {...restProps}
            renderValue={({ item }) => {
                if (!item) return null;

                if (!restProps.textField) {
                    return valueWithClearButton(item);
                }

                if (restProps.textField instanceof Function) {
                    return valueWithClearButton(restProps.textField(item));
                }

                return valueWithClearButton(item[restProps.textField]);
            }}
        />
    );
};

export default ClearableDropdownList;
