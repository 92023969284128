import React, { InputHTMLAttributes, useEffect } from 'react';
import FieldToggle, { Props as FieldToggleProps } from './FieldToggle';
import FieldMaskedInput from './FieldMaskedInput';
import styles from '../assets/field-international-phone-number.module.scss';
import { Controller } from 'react-hook-form';

type Props = {
    watch: any;
    control: any;
    clearErrors: any;
    error?: string;
    label?: string;
    name: string;
    toggleProps?: Partial<FieldToggleProps>;
    type?: InputHTMLAttributes<any>['type'];
};

const FieldInternationalPhoneNumber: React.FunctionComponent<Props> = ({
    watch,
    control,
    error,
    clearErrors,
    name,
    label = 'Phone Number',
    toggleProps,
    type,
}: Props) => {
    const internationalPhoneFormat = watch('internationalPhoneFormat');
    useEffect(() => {
        clearErrors(name);
    }, [internationalPhoneFormat]);

    return (
        <Controller
            name={name}
            control={control}
            render={() => (
                <>
                    <FieldToggle
                        control={control}
                        name="internationalPhoneFormat"
                        id="internationalPhoneFormat"
                        label="Use international phone number"
                        customSize={'md'}
                        {...toggleProps}
                    />
                    <FieldMaskedInput
                        disabled={!internationalPhoneFormat}
                        control={control}
                        name={'phoneNumberInt'}
                        mask="+99-999-999-9999"
                        wrapperProps={{
                            label,
                            classNames: { wrapperContainer: !internationalPhoneFormat ? styles.hidden : undefined },
                        }}
                        error={error}
                        type={type}
                    />
                    <FieldMaskedInput
                        disabled={internationalPhoneFormat}
                        control={control}
                        name={'phoneNumberLocal'}
                        mask="999-999-9999"
                        wrapperProps={{
                            label,
                            classNames: { wrapperContainer: internationalPhoneFormat ? styles.hidden : undefined },
                        }}
                        error={error}
                        type={type}
                    />
                </>
            )}
        />
    );
};

export default FieldInternationalPhoneNumber;
