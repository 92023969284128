import { EnumerableAbstract } from './enumerable.abstract';

class ModelPose extends EnumerableAbstract {
    public readonly POSE_0 = 0;
    public readonly POSE_1 = 1;
    public readonly POSE_2 = 2;
    public readonly POSE_3 = 3;
    public readonly POSE_4 = 4;
    public readonly POSE_5 = 5;

    public getName = () => 'UserRole';

    public defaultChoices = () => ({
        [this.POSE_0]: 'Skip',
        [this.POSE_1]: 'A-pose',
        [this.POSE_2]: 'Relaxed pose',
        [this.POSE_3]: 'Hands above head',
        [this.POSE_4]: 'Squat',
        [this.POSE_5]: 'AtoRelax1',
    });
}

const modelPose = new ModelPose();

export default modelPose;
