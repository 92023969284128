import React, { useCallback } from 'react';
import MainLayout from '../../../../components/layout/MainLayout';
import { useSelector } from 'react-redux';
import { QueryParams } from 'src/types/grid';
import { Paginated } from 'src/services/api-handlers/pagination';
import ContentHeader from '../../../../components/layout/main/ContentHeader';
import Content from '../../../../components/layout/main/Content';
import Router from '../../../../navigation/router';
import { routes } from '../../../../navigation';
import Loader from '../../../../components/Loader';
import { Staff } from 'src/types/staff';
import { staffLoadingSelector, staffListSelector } from 'src/store/selectors/staff-selectors';
import Grid, { Column } from '../../../../components/react-table/Grid';
import classNames from 'classnames';
import styles from './assets/list-screen.module.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ActionsColumn from './components/ActionsColumn';
import { useDispatch } from 'src/hooks/dispatch';
import { listStaff } from 'src/store/thunks/staff-thunks';
import userRole from 'src/enumerables/user-role';
import dropdownFilter from 'src/components/react-table/filters/dropdown-filter';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';

interface Props {}
const columns: Column<Staff>[] = [
    {
        Header: 'First name',
        accessor: 'firstName',
    },
    {
        Header: 'Last name',
        accessor: 'lastName',
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Role',
        id: 'roles',
        accessor: (item) => {
            return item.roles.map((val) => userRole.getLabel(val)).join(', ');
        },
        Filter: dropdownFilter<Staff>(userRole.mapData()),
        disableSortBy: true,
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <ActionsColumn {...props} actions={['view', 'edit', 'delete']} />,
        disableSortBy: true,
        disableFilters: true,
    },
];

const ListScreen: React.FunctionComponent<Props> = ({}: Props) => {
    const dispatch = useDispatch();
    const loading: boolean = useSelector(staffLoadingSelector);
    const data: Paginated<Staff> = useSelector(staffListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(listStaff(params));
    }, []);

    return (
        <MainLayout>
            <ContentHeader title="Staff" />
            <Content>
                <Grid<Staff>
                    columns={columns}
                    data={data}
                    getData={getData}
                    title={
                        <div className={classNames(styles.heading, 'd-flex', 'justify-content-end')}>
                            <Link
                                className={classNames('text-decoration-none')}
                                to={Router.generate(routes.STAFF_CREATE)}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Create
                            </Link>
                        </div>
                    }
                    defaultSorting={{ createdAt: 'DESC' }}
                />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default ListScreen;
