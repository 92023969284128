import React, { useState } from 'react';
import classNames from 'classnames';
import Visible from 'src/components/common/Visible';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import ModelScene from './model-scene/ModelScene';
import ImageScene from './image-scene/ImageScene';
import Controls from './сontrols/Controls';
import { observer } from 'mobx-react';

interface Props {}

const SceneContainer: React.FC<Props> = observer((props: Props) => {
    const { models: modelsStore } = useStores<Stores>();
    const [loading, setLoading] = useState(false);

    return (
        <div className={classNames('scan-preview-container')}>
            <Visible if={!modelsStore.activeImage}>
                <ModelScene onModelLoading={setLoading} />
            </Visible>
            <Visible if={modelsStore.activeImage}>
                <ImageScene />
            </Visible>
            <Controls disabled={loading} />
        </div>
    );
});

export default SceneContainer;
