import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import classNames from 'classnames';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { useDispatch } from 'src/hooks/dispatch';
import { conditionCurrentSelector } from 'src/store/selectors/condition-selectors';
import ConditionsInfo from 'src/screens/Condition/screens/View/components/ConditionInfo';
import { getCondition } from 'src/store/thunks/condition-thunks';
import Actions from './components/Actions';

interface RouteParams {
    id: string;
}

interface Props {}

const ConditionViewScreen: FC<Props> = () => {
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const condition = useSelector(conditionCurrentSelector);

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getCondition(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title={'View conditions'}
                    breadcrumbs={[{ title: 'Condition', link: Router.generate(routes.CONDITION_LIST) }]}
                />
                <Content>
                    <div className={classNames('card card-primary card-outline card-outline-tabs')}>
                        <div className={classNames('card-header')}>{condition ? `${condition.name}` : ''}</div>
                        <div className={classNames('card-body')}>
                            {condition && <ConditionsInfo condition={condition} />}
                        </div>
                        <div className={classNames('card-footer')}>
                            <Actions condition={condition} />
                        </div>
                    </div>
                </Content>
                {!condition && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default ConditionViewScreen;
