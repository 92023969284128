import { AxiosPromise } from 'axios';
import { EndPointService } from 'src/services/api-handlers/axios';

const ROUTE = 'phy-scores';

const get = (id: string): AxiosPromise<any> => EndPointService.get(`scans/${id}/${ROUTE}`);

const update = (id: string, payload: any): AxiosPromise<any> => EndPointService.patch(`/scans/${id}/${ROUTE}`, payload);

export default {
    get,
    update,
};
