import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { createScanScore, scanScoreList, updateScanScore } from 'src/store/thunks/scan-score-thunks';
import { crudHelpers, takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { ScanScore } from 'src/types/scan-score';

export interface ScanScoreState extends ReducerState {
    data: Paginated<ScanScore>;
    current: ScanScore | null;
}

const initialState: ScanScoreState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'scanScore',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(scanScoreList.pending, takeEvery.pendingActionCase)
            .addCase(scanScoreList.rejected, takeEvery.rejectedActionCase)
            .addCase(scanScoreList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(createScanScore.pending, takeOne.pendingActionCase)
            .addCase(createScanScore.rejected, takeOne.rejectedActionCase)
            .addCase(createScanScore.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
            })

            .addCase(updateScanScore.pending, takeOne.pendingActionCase)
            .addCase(updateScanScore.rejected, takeOne.rejectedActionCase)
            .addCase(updateScanScore.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
