import React, { PropsWithChildren } from 'react';
import classNames, { Argument } from 'classnames';
import { CommonFieldProps } from '../types/common';
import FieldWrapper, { FieldWrapperProps } from './FieldWrapper';
import Toggle, { Props as ToggleProps } from 'src/components/Toggle';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

interface FieldToggleProps extends CommonFieldProps {
    control: Control;
    wrapperProps?: FieldWrapperProps;
    errorClassName?: Argument;
    defaultValue?: number;
}

export type Props = FieldToggleProps & ToggleProps;

const FieldToggle: React.FunctionComponent<Props> = ({
    wrapperProps,
    className,
    errorClassName = 'border-danger text-danger',
    control,
    error,
    children,
    ...props
}: PropsWithChildren<Props>) => (
    <Controller
        name={props.name}
        control={control}
        render={({ field }) => {
            return (
                <FieldWrapper {...wrapperProps} classNames={wrapperProps?.classNames} name={props.name} error={error}>
                    <Toggle {...props} {...field} className={classNames(className, error && errorClassName)} />
                    {children}
                </FieldWrapper>
            );
        }}
    />
);

export default FieldToggle;
