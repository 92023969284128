import React, { PropsWithChildren } from 'react';
import SidebarItemLink from './SidebarItemLink';
import SidebarItemTree from './SidebarItemTree';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    title: string;
    icon: IconProp;
    link?: string;
    isActive?: ({ title, icon, link }: any) => boolean;
    items?: Array<{ title: string; to: string }>;
}

const SidebarItem: React.FunctionComponent<Props> = React.memo(
    ({ title, icon, link, items, isActive }: PropsWithChildren<Props>) => {
        if (items && items.length > 0) {
            return <SidebarItemTree title={title} icon={icon} items={items} isActive={isActive} />;
        }

        return <SidebarItemLink title={title} to={link ?? '#'} icon={icon} isActive={isActive} />;
    },
);

export default SidebarItem;
