import React, { FC, useCallback } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import ConditionForm, { ConditionsFormData } from 'src/screens/Condition/components/ConditionForm';
import { getCondition, updateCondition } from 'src/store/thunks/condition-thunks';
import { conditionCurrentSelector, conditionLoadingSelector } from 'src/store/selectors/condition-selectors';

interface RouteParams {
    id: string;
}

interface Props {}

const ConditionEditScreen: FC<Props> = () => {
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const condition = useSelector(conditionCurrentSelector);
    const loading = useSelector(conditionLoadingSelector);

    const handleSubmit = async (data: ConditionsFormData, dispatch: AppDispatch) => {
        await dispatch(updateCondition({ id, ...data })).unwrap();
    };

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getCondition(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title="Edit condition"
                    breadcrumbs={[{ title: 'Condition', link: Router.generate(routes.CONDITION_LIST) }]}
                />
                <Content loading={!condition || condition.id !== id}>
                    <ConditionForm title={`${condition?.name}`} condition={condition} onSubmit={handleSubmit} />
                    {loading && <Loader />}
                </Content>
            </Resource>
        </MainLayout>
    );
};

export default ConditionEditScreen;
