import React, { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Argument as ClassValue } from 'classnames';
import FieldWrapper, { FieldWrapperProps } from './FieldWrapper';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

interface FieldInputProps {
    name: string;
    error?: string;
    register: UseFormRegister<any>;
    wrapperProps?: FieldWrapperProps;
    errorClassName?: ClassValue;
}

type Props = FieldInputProps & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FieldInput: React.FunctionComponent<Props> = ({
    wrapperProps,
    className = 'form-control',
    errorClassName = 'border-danger text-danger',
    register,
    error,
    children,
    name,
    ...props
}: PropsWithChildren<Props>) => (
    <FieldWrapper {...wrapperProps} classNames={wrapperProps?.classNames} name={name} error={error}>
        <input
            id={props.id || name}
            {...props}
            className={classNames(className, error && errorClassName)}
            placeholder={props.placeholder}
            {...register(name)}
        />
        {children}
    </FieldWrapper>
);

export default FieldInput;
