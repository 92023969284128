import React, { useCallback } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { staffCurrentSelector, staffLoadingSelector } from 'src/store/selectors/staff-selectors';
import StaffForm, { StaffFormData } from '../../components/StaffForm';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { AppDispatch } from 'src/store/configure-store';
import { getStaff, updateStaff } from 'src/store/thunks/staff-thunks';
import { useDispatch } from 'src/hooks/dispatch';

interface RouteParams {
    id: string;
}

interface Props {}

const EditScreen: React.FunctionComponent<Props> = () => {
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const staff = useSelector(staffCurrentSelector);
    const loading = useSelector(staffLoadingSelector);

    const handleSubmit = async (data: StaffFormData, dispatch: AppDispatch) => {
        await dispatch(updateStaff({ id, ...data })).unwrap();
    };

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getStaff(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title="Edit staff"
                    breadcrumbs={[{ title: 'Staff', link: Router.generate(routes.STAFF_LIST) }]}
                />
                <Content loading={!staff || staff.id !== id}>
                    <StaffForm title={`${staff?.firstName} ${staff?.lastName}`} staff={staff} onSubmit={handleSubmit} />
                    {loading && <Loader />}
                </Content>
            </Resource>
        </MainLayout>
    );
};

export default EditScreen;
