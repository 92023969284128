import { EnumerableAbstract } from './enumerable.abstract';

class ScanScoreValue extends EnumerableAbstract {
    public readonly ONE = 1;
    public readonly TWO = 2;
    public readonly THREE = 3;

    public getName = () => 'ScanScoreValue';

    public defaultChoices = () => ({
        [this.ONE]: '1 - Needs Attention',
        [this.TWO]: '2 - Moderately Vulnerable',
        [this.THREE]: '3 - Neutral Balance',
    });
}

const scanScoreValue = new ScanScoreValue();

export default scanScoreValue;
