import React from 'react';
import Point, { Props as PointProps } from './Point';

interface Props extends PointProps {}

const OUTER_SPHERE_RADIUS = 0.008;
const OUTER_SPHERE_OPACITY = 0.8;
const OUTER_SPHERE_COLOR = 0x4e92e5;
const INNER_SPHERE_RADIUS = 0.004;

const ActivePoint: React.FC<Props> = (props: Props) => {
    return (
        <Point
            {...props}
            config={{
                outerSphereRadius: OUTER_SPHERE_RADIUS,
                outerSphereOpacity: OUTER_SPHERE_OPACITY,
                outerSphereColor: OUTER_SPHERE_COLOR,
                innerSphereRadius: INNER_SPHERE_RADIUS,
                ...props.config,
            }}
        />
    );
};

export default ActivePoint;
