import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import PointForm, { PointFormData } from './PointForm';
import classNames from 'classnames';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import Visible from 'src/components/common/Visible';
import { PointType as PointTypeEnum, PointType } from 'src/types/points';
import useSceneStore from 'src/hooks/use-scene-store';

export interface Props {}

const CreateForm: React.FunctionComponent<Props> = observer((props: Props) => {
    const { points: pointsStore, models: modelsStore, config } = useStores<Stores>();
    const sceneStore = useSceneStore();
    const visible = Boolean(sceneStore.createMode && modelsStore.activeModel);
    const showCreateModeBtn = Boolean(!visible && !sceneStore.activePoint);

    const submitHandler = async (data: PointFormData) => {
        if (!sceneStore.createdPoints.length) {
            config.triggerEvent('onNotification', ['error', 'You must add at least one point on the model']);
            return;
        }

        await pointsStore.createPoints({
            type: modelsStore.activeImage ? PointType.Image : PointType.Model,
            point: data,
            coordinates: sceneStore.createdPoints.map(({ coordinates }) => coordinates),
        });
        if (pointsStore.pointsError) {
            config.triggerEvent('onNotification', ['error', pointsStore.pointsError]);
            return false;
        }

        sceneStore.clearCreatedPoints();
        config.triggerEvent('onNotification', ['success', 'Data was saved']);

        return true;
    };

    const defaultValues = useMemo(() => {
        return {
            model: modelsStore.activeModel?.id,
            bodyPart: pointsStore.lastPoint?.bodyPart.id,
        };
    }, [modelsStore.activeModel?.id, pointsStore.lastPoint]);

    const modeClickHandler = useCallback(() => {
        sceneStore.setCreateMode(true);
    }, [sceneStore]);

    const cancelHandler = useCallback(() => {
        sceneStore.reset();
    }, [sceneStore]);

    return (
        <React.Fragment>
            {showCreateModeBtn && (
                <button
                    type="button"
                    className={classNames('btn', 'btn-primary', 'create-mode-btn')}
                    onClick={modeClickHandler}
                >
                    Create mode
                </button>
            )}
            <Visible if={visible}>
                <div className={classNames('create-form')}>
                    <PointForm onSubmit={submitHandler} defaultValues={defaultValues}>
                        <button
                            type="button"
                            className={classNames('btn', 'btn-warning', 'cancel-btn')}
                            onClick={cancelHandler}
                        >
                            Cancel
                        </button>
                    </PointForm>
                </div>
            </Visible>
        </React.Fragment>
    );
});

export default CreateForm;
