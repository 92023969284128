import { action, makeObservable, observable, computed } from 'mobx';
import { Events, InitData } from '../types/config';

export class ConfigStore {
    @observable
    private config: InitData | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    public setConfig(config: InitData) {
        this.config = {
            ...{
                showFilters: true,
                showLayers: true,
            },
            ...config,
        };
    }

    @computed
    public get isConfigInitiated() {
        return this.config !== null;
    }

    // @action
    // public setEvents(events: Partial<Events>): void {
    //     this.events = events;
    // }

    @computed
    public get showFilters() {
        return this.config?.showFilters;
    }

    @computed
    public get showLayers() {
        return this.config?.showLayers;
    }

    public triggerEvent<E extends keyof Events>(event: E, data: Parameters<Events[E]>): Promise<ReturnType<Events[E]>> {
        const cb = this.config?.events ? this.config?.events[event] : null;

        // eslint-disable-next-line prefer-spread
        return cb && (cb as any).apply(null, data);
    }
}

export default ConfigStore;
