import React from 'react';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import classNames from 'classnames';
import MainLayout from 'src/components/layout/MainLayout';
import { Nav, Tab } from 'react-bootstrap';
import ChangePasswordForm from './components/ChangePasswordForm';

interface Props {}

const ProfileScreen: React.FC<Props> = ({}: Props) => {
    return (
        <MainLayout>
            <ContentHeader title="Profile" />
            <Content loading={false}>
                <Tab.Container defaultActiveKey="password">
                    <div className={classNames('card card-primary card-outline card-outline-tabs')}>
                        <div className={classNames('card-header p-0 border-bottom-0')}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="password">Change password</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className={classNames('card-body')}>
                            <Tab.Content>
                                <Tab.Pane eventKey="password">
                                    <ChangePasswordForm />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </Content>
        </MainLayout>
    );
};

export default ProfileScreen;
