import React, { FC, memo, useCallback } from 'react';
import { FilterProps } from './types';
import moment from 'moment';
import classNames from 'classnames';
import styles from '../assets/date-time-range.module.scss';
import { DatePicker, Localization } from 'react-widgets';
import MomentLocalizer from 'react-widgets-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DATE_ISO_8601_FORMAT } from '../../../config/env';

moment.locale('en');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const localizer = new MomentLocalizer(moment);

type Args = {
    nameFrom: string;
    nameTo: string;
};

const dateTimeRangeFilter = <T extends Record<string, unknown>>({ nameFrom, nameTo }: Args): FC<FilterProps<T>> => {
    return memo(({ column: { setFilter, filterValue } }: FilterProps<T>) => {
        const handleChange = useCallback(
            (keyName: string, date?: Date | null) => {
                setFilter({
                    ...filterValue,
                    [keyName]: date ? moment(date).utcOffset(0).format(DATE_ISO_8601_FORMAT) : date,
                });
            },
            [filterValue],
        );

        return (
            <Localization date={localizer}>
                <div className={classNames('d-flex flex-row justify-content-around align-items-center')}>
                    <div className={classNames('d-flex align-items-center')}>
                        <DatePicker
                            placeholder={'From'}
                            onChange={(val) => {
                                handleChange(nameFrom, val);
                            }}
                            containerClassName={classNames(styles.dateTimeRangePicker)}
                        />
                    </div>
                    <div className={'mx-1'}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    <div className={classNames('d-flex align-items-center')}>
                        <DatePicker
                            placeholder={'To'}
                            onChange={(val) => {
                                handleChange(nameTo, val);
                            }}
                            containerClassName={classNames(styles.dateTimeRangePicker)}
                        />
                    </div>
                </div>
            </Localization>
        );
    });
};

export default dateTimeRangeFilter;
