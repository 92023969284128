import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import PointForm, { PointFormData } from './PointForm';
import classNames from 'classnames';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import Visible from 'src/components/common/Visible';
import useSceneStore from 'src/hooks/use-scene-store';

export interface Props {}

const EditForm: React.FunctionComponent<Props> = observer((props: Props) => {
    const { points: pointsStore, config } = useStores<Stores>();
    const sceneStore = useSceneStore();

    const submitHandler = async (data: PointFormData) => {
        const point = sceneStore.activePoint!;
        await pointsStore.updatePoint(point!.id, data);
        if (pointsStore.pointsError) {
            return config.triggerEvent('onNotification', ['error', pointsStore.pointsError]);
        }
        sceneStore.setActivePoint(null);
        config.triggerEvent('onNotification', ['success', 'Data was saved']);
    };

    const deleteHandler = useCallback(async () => {
        if (confirm('Are you sure you want delete this point?')) {
            const point = sceneStore.activePoint!;
            await pointsStore.removePoint(point.id);
            if (pointsStore.pointsError) {
                return config.triggerEvent('onNotification', ['error', pointsStore.pointsError]);
            }
            sceneStore.setActivePoint(null);
            config.triggerEvent('onNotification', ['success', 'Point was removed']);
        }
    }, [sceneStore]);

    const cancelHandler = useCallback(() => {
        sceneStore.setCreateMode(false);
        sceneStore.setActivePoint(null);
        sceneStore.clearCreatedPoints();
    }, [sceneStore]);

    return (
        <Visible if={sceneStore.activePoint && !sceneStore.createMode}>
            {sceneStore.activePoint && (
                <div className={classNames('create-form')}>
                    <PointForm onSubmit={submitHandler} point={sceneStore.activePoint} key={sceneStore.activePoint.id}>
                        <button type="button" className={classNames('btn', 'btn-danger')} onClick={deleteHandler}>
                            Delete
                        </button>
                        <button
                            type="button"
                            className={classNames('btn', 'btn-warning', 'cancel-btn')}
                            onClick={cancelHandler}
                        >
                            Cancel
                        </button>
                    </PointForm>
                </div>
            )}
        </Visible>
    );
});

export default EditForm;
