import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { takeEvery } from 'src/helpers/store';
import { getModelsStatistic, getScanStatistic } from 'src/store/thunks/statistic-thunks';
import { ModelsStatistic, ScanStatistic } from 'src/types/statistic';

export interface StatisticState extends ReducerState {
    scanStatistic: ScanStatistic | null;
    modelsStatistic: ModelsStatistic[];
}

const initialState: StatisticState = {
    error: '',
    loading: 'none',
    requestIds: [],
    scanStatistic: null,
    modelsStatistic: [],
};

export const slice = createSlice({
    name: 'statistic',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScanStatistic.pending, takeEvery.pendingActionCase)
            .addCase(getScanStatistic.rejected, takeEvery.rejectedActionCase)
            .addCase(getScanStatistic.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, (state, { payload }) => {
                    state.scanStatistic = payload;
                });
            })

            .addCase(getModelsStatistic.pending, takeEvery.pendingActionCase)
            .addCase(getModelsStatistic.rejected, takeEvery.rejectedActionCase)
            .addCase(getModelsStatistic.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, (state, action) => {
                    state.modelsStatistic = action.payload;
                });
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
