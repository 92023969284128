import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useQuery = <T extends Record<any, any> = Record<any, any>>(): T => {
    const { search } = useLocation();

    return useMemo(() => {
        return Object.fromEntries(new URLSearchParams(search).entries()) as T;
    }, [search]);
};
