import { CellProps } from 'react-table';
import React, { FC, memo } from 'react';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-actions-column.module.scss';
import DeleteConditionAction from 'src/screens/Condition/components/DeleteConditionAction';
import { faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import routes from 'src/navigation/routes';
import { Condition } from 'src/types/condition';

type Action = 'view' | 'edit' | 'delete';

interface Props extends CellProps<Condition> {
    actions: Action[];
}

const ConditionActionsColumn: FC<Props> = memo(
    ({
        cell: {
            row: { original },
            value,
        },
        actions,
    }: Props) => (
        <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
            {actions.includes('view') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="conditions-view">View</Tooltip>}>
                    <Link
                        to={Router.generate(routes.CONDITION_VIEW, { id: value })}
                        className={classNames(['text-decoration-none', styles.actionLink])}
                    >
                        <FontAwesomeIcon icon={faEye} color="#0d6efd" />
                    </Link>
                </OverlayTrigger>
            )}
            {actions.includes('edit') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="conditions-edit">Edit</Tooltip>}>
                    <Link
                        to={Router.generate(routes.CONDITION_EDIT, { id: value })}
                        className={classNames(['text-decoration-none', styles.actionLink])}
                    >
                        <FontAwesomeIcon icon={faEdit} color="#0d6efd" />
                    </Link>
                </OverlayTrigger>
            )}
            {actions.includes('delete') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="conditions-delete">Delete</Tooltip>}>
                    <div>
                        <DeleteConditionAction condition={original}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </DeleteConditionAction>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    ),
);

export default ConditionActionsColumn;
