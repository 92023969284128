import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { Paginated } from 'src/services/api-handlers/pagination';
import { scanConditionList } from 'src/store/thunks/scan-condition-thunks';
import { useParams } from 'react-router';
import { scanConditionListSelector, scanConditionLoadingSelector } from 'src/store/selectors/scan-condition-selectors';
import { ScanCondition } from 'src/types/scan-condition';
import ScanTabs from 'src/screens/Scan/components/ScanTabs';
import ScanConditionList from 'src/screens/Scan/screens/Condition/components/ScanConditionList';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { getScan } from 'src/store/thunks/scan-thunks';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';

const ConditionListScreen: FC = () => {
    const dispatch = useDispatch();
    const { id: scanId } = useParams<{ id: string }>();
    const loading: boolean = useSelector(scanConditionLoadingSelector);
    const data: Paginated<ScanCondition> = useSelector(scanConditionListSelector);
    const scan = useSelector(scanCurrentSelector);

    const getData = useCallback(
        (params: QueryParams) => {
            dispatch(scanConditionList({ ...params, filters: { ...params.filters }, scanId }));
        },
        [scanId],
    );

    useEffect(() => {
        if (scan?.id !== scanId) {
            dispatch(getScan(scanId));
        }
    }, [scanId]);

    return (
        <MainLayout>
            <ContentHeader
                title="Conditions"
                breadcrumbs={[
                    { title: 'Sessions', link: Router.generate(routes.SCAN_LIST) },
                    { title: `${scan?.name}`, link: Router.generate(routes.SCAN_VIEW, { id: scanId }) },
                ]}
            />
            <Content>
                <ScanTabs />
                <ScanConditionList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default ConditionListScreen;
