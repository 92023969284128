import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import { useSelector } from 'react-redux';
import { Paginated } from 'src/services/api-handlers/pagination';
import bodyPartValidationSchema from 'src/screens/BodyPartValidation/schema/body-part-validation-schema';
import { bodyPartListSelector, bodyPartLoadingSelector } from 'src/store/selectors/body-part-selectors';
import { bodyPartList } from 'src/store/thunks/body-part-thunks';
import { BodyPart } from 'src/types/body-part';
import FieldInput from 'src/components/react-hook-form/fields/FieldInput';
import { SelectableItem } from 'src/enumerables/enumerable.abstract';
import FieldFetchingDropdown from 'src/components/react-hook-form/fields/FieldFetchingDropdown';
import { BodyPartValidation } from 'src/types/body-part-validation';
import { bodyPartValidationList } from 'src/store/thunks/body-part-validation-thunks';
import { fetchAll } from 'src/helpers/fetch-all';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';

export type BodyPartValidationFormData = {
    name: string;
    type: string;
    bodyPart: SelectableItem | string;
};

interface BodyPartValidationFormProps {
    title: string;
    onSubmit: (data: BodyPartValidationFormData, dispatch: AppDispatch) => Promise<any>;
    type: string;
}

const BodyPartValidationForm: FC<BodyPartValidationFormProps> = ({
    title,
    onSubmit,
    type,
}: BodyPartValidationFormProps) => {
    const bodyPartsData: Paginated<BodyPart> = useSelector(bodyPartListSelector);
    const bodyPartsLoading = useSelector(bodyPartLoadingSelector);
    const [existingBodyPartIds, setExistingBodyPartIds] = useState<string[]>([]);
    const bodyPartsMappedData: SelectableItem[] = bodyPartsData.list
        .filter((bodyPart) => !existingBodyPartIds.includes(bodyPart.id))
        .map(({ name, id }) => ({
            name,
            value: id,
        }));
    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, submitCount, isSubmitSuccessful, errors },
    } = useForm<BodyPartValidationFormData>({
        resolver: yupResolver(bodyPartValidationSchema),
        defaultValues: { type },
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const submit = async (data: BodyPartValidationFormData) => {
        await withErrors<BodyPartValidationFormData>(onSubmit(data, dispatch), setError);
    };

    const navigateBack = useCallback(() => {
        history.push(Router.generate(routes.BODY_PART_VALIDATION_LIST, { type }));
    }, []);

    const fetchBodyParts = useCallback((page: number, searchTerm?: string) => {
        return dispatch(bodyPartList({ filters: { deepest: 1, name: searchTerm }, limit: -1, page })).unwrap();
    }, []);

    useEffect(() => {
        (async () => {
            const data = await fetchAll<BodyPartValidation>((page) => {
                return dispatch(bodyPartValidationList({ filters: { type }, limit: -1, page })).unwrap() as any;
            });

            setExistingBodyPartIds(data.map(({ bodyPart }) => bodyPart.id));
        })();
    }, [type]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            toast.success(`Data was saved`);
            navigateBack();
        } else {
            if ((errors as any).company) {
                toast.error((errors as any).company.message);
            }
        }
    }, [submitCount]);

    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldInput control={control} name={'type'} type={'hidden'} />

                        <FieldFetchingDropdown
                            busy={bodyPartsLoading}
                            disabled={bodyPartsLoading}
                            control={control}
                            data={bodyPartsMappedData}
                            fetchData={fetchBodyParts}
                            name="bodyPart"
                            wrapperProps={{ label: bodyPartValidationSchema.fields.bodyPart.spec.label }}
                            textField={'name'}
                            valueField={'value'}
                            error={(errors?.bodyPart as any)?.message}
                            page={bodyPartsData.page}
                            maxPage={bodyPartsData.totalPages as number}
                            fetchOnSearch={true}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
                <Button className={classNames('btn', 'btn-danger')} onClick={navigateBack} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faUndoAlt} /> Cancel
                </Button>
            </div>
        </form>
    );
};

export default BodyPartValidationForm;
