import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const scanPhyScoreStateSelector = (state: StoreState) => state.scanPhyScore;
export const scanPhyScoreListSelector = createDraftSafeSelector(scanPhyScoreStateSelector, (state) => state.data);
export const scanPhyScoreErrorSelector = createDraftSafeSelector(scanPhyScoreStateSelector, (state) => state.error);
export const scanPhyScoreLoadingSelector = createDraftSafeSelector(
    scanPhyScoreStateSelector,
    (state) => state.loading === 'loading',
);
export const scanPhyScoreCurrentSelector = createDraftSafeSelector(scanPhyScoreStateSelector, (state) => state.current);
