import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import {
    bodyPartValidationListSelector,
    bodyPartValidationLoadingSelector,
} from 'src/store/selectors/body-part-validation-selectors';
import { Paginated } from 'src/services/api-handlers/pagination';
import { BodyPartValidation } from 'src/types/body-part-validation';
import { QueryParams } from 'src/types/grid';
import { bodyPartValidationList } from 'src/store/thunks/body-part-validation-thunks';
import BodyPartValidationList from 'src/screens/BodyPartValidation/components/BodyPartValidationList';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import bodyPartValidationType from 'src/enumerables/body-part-validation-type';

const BodyPartValidationListScreen: FC = () => {
    const loading: boolean = useSelector(bodyPartValidationLoadingSelector);
    const { type } = useParams<{ type: string }>();
    const dispatch = useDispatch();

    const data: Paginated<BodyPartValidation> = useSelector(bodyPartValidationListSelector);

    const getData = useCallback(
        (params: QueryParams) => {
            dispatch(bodyPartValidationList({ ...params, filters: { ...params.filters, type } }));
        },
        [type],
    );
    const checkParams = (linkType: string) => (type === linkType ? 'active' : '');

    return (
        <MainLayout>
            <ContentHeader title="Body Part Validation" />
            <Content>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link
                            to={Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                                type: bodyPartValidationType.GENERAL,
                            })}
                            className={classNames(`nav-link text-black`, checkParams(bodyPartValidationType.GENERAL))}
                        >
                            General
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                                type: bodyPartValidationType.SQUAT,
                            })}
                            className={classNames(`nav-link text-black`, checkParams(bodyPartValidationType.SQUAT))}
                        >
                            Squat
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to={Router.generate(routes.BODY_PART_VALIDATION_LIST, {
                                type: bodyPartValidationType.IMAGE,
                            })}
                            className={classNames(`nav-link text-black`, checkParams(bodyPartValidationType.IMAGE))}
                        >
                            Image
                        </Link>
                    </li>
                </ul>
                <BodyPartValidationList data={data} getData={getData} type={type} />

                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default BodyPartValidationListScreen;
