import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { bodyPartListSelector, bodyPartLoadingSelector } from 'src/store/selectors/body-part-selectors';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import BodyPartList from 'src/screens/BodyPart/components/BodyPartList';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { bodyPartList } from 'src/store/thunks/body-part-thunks';
import { Paginated } from 'src/services/api-handlers/pagination';
import { BodyPart } from 'src/types/body-part';

const BodyPartListScreen: FC = () => {
    const dispatch = useDispatch();
    const loading: boolean = useSelector(bodyPartLoadingSelector);
    const data: Paginated<BodyPart> = useSelector(bodyPartListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(bodyPartList({ ...params, filters: { ...params.filters, topLevel: 1, limit: 50 } }));
    }, []);

    return (
        <MainLayout>
            <ContentHeader title="Body Parts" />
            <Content>
                <BodyPartList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default BodyPartListScreen;
