import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import classNames from 'classnames';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { useDispatch } from 'src/hooks/dispatch';
import { getModelReview } from 'src/store/thunks/model-review-thunks';
import { modelReviewCurrentSelector } from 'src/store/selectors/mode-review-selectors';
import ModelReviewInfo from 'src/screens/Model/screens/Reviews/screens/View/components/ModelReviewInfo';

interface RouteParams {
    id: string;
    reviewId: string;
}

interface Props {}

const ModelReviewViewScreen: FC<Props> = () => {
    const { id, reviewId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const modelReview = useSelector(modelReviewCurrentSelector);

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getModelReview({ modelId: id, reviewId })).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                {modelReview && (
                    <>
                        <ContentHeader
                            title={'View Review'}
                            breadcrumbs={[{ title: 'Reviews', link: Router.generate(routes.MODEL_REVIEW_LIST) }]}
                        />
                        <Content>
                            <div className={classNames('card card-primary card-outline card-outline-tabs')}>
                                <div className={classNames('card-header')}>
                                    {modelReview ? `View ${modelReview.id}` : ''}
                                </div>
                                <div className={classNames('card-body')}>
                                    {modelReview && <ModelReviewInfo modelReview={modelReview} />}
                                </div>
                            </div>
                        </Content>
                    </>
                )}
                {!modelReview && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default ModelReviewViewScreen;
