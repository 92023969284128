import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { crudHelpers, takeOne } from 'src/helpers/store';
import { createScanReview } from '../thunks/scan-review-thunks';
import { ScanReview } from '../../types/scan-review';

export interface ScanState extends ReducerState {
    current: ScanReview | null;
}

const initialState: ScanState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
};

export const slice = createSlice({
    name: 'scan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createScanReview.pending, takeOne.pendingActionCase)
            .addCase(createScanReview.rejected, takeOne.rejectedActionCase)
            .addCase(createScanReview.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
