import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { crudHelpers, takeEvery } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { Model } from 'src/types/model';
import { getModel, modelList } from 'src/store/thunks/model-thunks';

export interface ModelState extends ReducerState {
    data: Paginated<Model>;
    current: Model | null;
}

const initialState: ModelState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'model',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(modelList.pending, takeEvery.pendingActionCase)
            .addCase(modelList.rejected, takeEvery.rejectedActionCase)
            .addCase(modelList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(getModel.pending, takeEvery.pendingActionCase)
            .addCase(getModel.rejected, takeEvery.rejectedActionCase)
            .addCase(getModel.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterGet);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
