import React from 'react';
import classNames from 'classnames';
import styles from 'src/screens/Scan/screens/View/assets/review-form.module.scss';
import moment from 'moment';
import { DATETIME_FORMAT } from 'src/config/env';
import ModelReviewStatus from 'src/enumerables/model-review-status';
import { ModelReview } from 'src/types/model-review';

interface Props {
    review: ModelReview;
}

const ModelReviewInfo: React.FC<Props> = ({ review }: Props) => {
    return (
        <div className={classNames('table-responsive')}>
            <table className={classNames('table', styles.tableInfo)}>
                <tbody>
                    <tr>
                        <th>Created at:</th>
                        <td>{moment(review.createdAt).format(DATETIME_FORMAT)}</td>
                    </tr>
                    <tr>
                        <th>Created by:</th>
                        <td>
                            {review.createdBy.firstName} {review.createdBy.lastName}
                        </td>
                    </tr>

                    {review.status === ModelReviewStatus.APPROVED && (
                        <>
                            <tr>
                                <th>Approved at:</th>
                                <td>{moment(review.approvedAt).format(DATETIME_FORMAT)}</td>
                            </tr>
                            <tr>
                                <th>Approved by:</th>
                                <td>
                                    {review.approvedBy.firstName} {review.approvedBy.lastName}
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ModelReviewInfo;
