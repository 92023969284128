import React, { useCallback, useEffect } from 'react';
import { Model } from 'src/types/model';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'src/hooks/dispatch';
import { createModelReview, modelReviewList, updateModelReview } from 'src/store/thunks/model-review-thunks';
import { useSelector } from 'react-redux';
import { modelReviewListSelector, modelReviewLoadingSelector } from 'src/store/selectors/mode-review-selectors';
import Button from 'src/components/Button';
import ModelReviewStatus from 'src/enumerables/model-review-status';
import classNames from 'classnames';
import ModelReviewInfo from './ModelReviewInfo';
import Authorized from 'src/components/auth/Authorized';
import userRole from 'src/enumerables/user-role';
import FieldTextArea from 'src/components/react-hook-form/fields/FieldTextArea';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import FormError from 'src/components/react-hook-form/FormError';

interface Props {
    model: Model;
    type: 'model' | 'image';
    onSubmitted?: () => void;
}

type ModelReviewForm = {
    comment?: string;
    status?: number;
};

const validationSchema = yup.object().shape({
    comment: yup.mixed().optional().label('Comment'),
});

const ModelReviewForm: React.FC<Props> = ({ model, type, onSubmitted }: Props) => {
    const dispatch = useDispatch();
    const { list: modelReviews } = useSelector(modelReviewListSelector);
    const loading = useSelector(modelReviewLoadingSelector);
    const review = modelReviews.find(({ status }) => {
        return [ModelReviewStatus.APPROVED, ModelReviewStatus.PENDING].includes(status);
    });

    const {
        control,
        setValue,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm<ModelReviewForm>({ resolver: yupResolver(validationSchema) });

    const updateReview = useCallback(
        (reviewId: string, status: number, comment?: string) => {
            return dispatch(updateModelReview({ id: model.id, reviewId: reviewId, status, comment })).unwrap();
        },
        [model.id],
    );

    const approveModelHandler = useCallback(
        async (data: ModelReviewForm) => {
            const reviewToApprove =
                review || (await withErrors(dispatch(createModelReview({ id: model.id, type })).unwrap(), setError));

            if (reviewToApprove) {
                const res = await withErrors(
                    updateReview(reviewToApprove.id, ModelReviewStatus.APPROVED, data.comment),
                    setError,
                );
                res !== false && onSubmitted && onSubmitted();
            }
        },
        [model, review, updateReview, type],
    );

    const sendToReviewHandler = useCallback(async () => {
        const res = await withErrors(dispatch(createModelReview({ id: model.id, type })).unwrap(), setError);
        res !== false && onSubmitted && onSubmitted();
    }, [model, review, type]);

    const rejectModelHandler = useCallback(async () => {
        if (review) {
            const res = await withErrors(updateReview(review.id, ModelReviewStatus.REJECTED), setError);
            res !== false && onSubmitted && onSubmitted();
        }
    }, [updateReview, review]);

    const disapproveModelHandler = useCallback(async () => {
        if (confirm('Are you sure you want to disapprove model?') && review) {
            const res = await withErrors(updateReview(review.id, ModelReviewStatus.PENDING), setError);
            res !== false && onSubmitted && onSubmitted();
        }
    }, [updateReview, review]);

    useEffect(() => {
        dispatch(modelReviewList({ id: model.id, limit: -1, filters: { type } }));
    }, [model.id, type]);

    useEffect(() => {
        setValue('comment', review?.comment || '');
    }, [review]);

    return (
        <div className={classNames('form')}>
            <div className={classNames('mb-2')}>
                <span
                    className={classNames(
                        'badge',
                        review?.status === ModelReviewStatus.APPROVED
                            ? 'badge-success'
                            : review?.status === ModelReviewStatus.PENDING
                            ? 'badge-warning'
                            : 'badge-danger',
                    )}
                >
                    {review?.status === ModelReviewStatus.APPROVED
                        ? 'Approved'
                        : review?.status === ModelReviewStatus.PENDING
                        ? 'Waiting for approve'
                        : 'Not approved'}
                </span>
            </div>

            <div className={classNames('')}>
                {review && <ModelReviewInfo review={review} />}

                <Authorized actions={[userRole.ROLE_ADMIN]}>
                    <FieldTextArea
                        control={control}
                        name={'comment'}
                        error={errors?.comment?.message || (errors as any)?.type?.message}
                        wrapperProps={{ label: validationSchema.fields.comment.spec.label }}
                        disabled={review?.status === ModelReviewStatus.APPROVED}
                    />
                </Authorized>
            </div>

            <div className={classNames('')}>
                <Authorized actions={[userRole.ROLE_STAFF]}>
                    {!review && (
                        <>
                            <Button
                                type={'button'}
                                onClick={sendToReviewHandler}
                                className={classNames('btn-success', 'btn-sm', 'mr-3')}
                                loading={loading}
                            >
                                Send to review
                            </Button>
                            <FormError error={errors?.comment?.message || (errors as any)?.type?.message} />
                        </>
                    )}
                </Authorized>
                <Authorized actions={[userRole.ROLE_ADMIN]}>
                    {review?.status !== ModelReviewStatus.APPROVED && (
                        <Button
                            type={'button'}
                            onClick={handleSubmit(approveModelHandler)}
                            className={classNames('btn-success', 'btn-sm', 'mr-3')}
                            loading={loading}
                        >
                            Approve
                        </Button>
                    )}

                    {review?.status === ModelReviewStatus.APPROVED && (
                        <Button
                            type={'button'}
                            onClick={handleSubmit(disapproveModelHandler)}
                            className={classNames('btn-danger', 'btn-sm')}
                            loading={loading}
                        >
                            Disapprove
                        </Button>
                    )}

                    {review?.status === ModelReviewStatus.PENDING && (
                        <Button
                            type={'button'}
                            onClick={handleSubmit(rejectModelHandler)}
                            className={classNames('btn-danger', 'btn-sm')}
                            loading={loading}
                        >
                            Reject
                        </Button>
                    )}
                </Authorized>
            </div>
        </div>
    );
};

export default ModelReviewForm;
