import React from 'react';
import { Staff } from 'src/types/staff';
import classNames from 'classnames';
import { InternationalPhoneNumberFormData, useInternationalPhoneNumber } from 'src/hooks/international-phone-number';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FieldInternationalPhoneNumber from 'src/components/react-hook-form/fields/FieldInternationalPhoneNumber';
import Button from 'src/components/Button';
import { useDispatch } from 'src/hooks/dispatch';
import styles from '../assets/update-form.module.scss';
import { withErrors } from '../../../../../components/react-hook-form/utils/make-form-errors';
import { updatePhone } from '../../../../../store/thunks/staff-thunks';

export type UpdatePhoneFormData = {
    phoneNumber: string;
};

type FormData = UpdatePhoneFormData & InternationalPhoneNumberFormData;

interface Props {
    user: Staff;
    onSuccess?: () => void;
    onBack?: (error?: any) => void;
}

const UpdatePhone: React.FC<Props> = ({ user, onSuccess, onBack }: Props) => {
    const dispatch = useDispatch();

    const {
        validationSchema: phoneNumberValidationSchema,
        defaultValues: defaultPhoneNumberValues,
        withPreparedData: withPreparedPhoneData,
    } = useInternationalPhoneNumber({
        fieldName: 'phoneNumber',
        initialValue: user.phoneNumber,
    });

    const validationSchema = yup.object().shape({
        phoneNumber: phoneNumberValidationSchema.label('Phone Number'),
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        formState: { isSubmitting, errors },
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultPhoneNumberValues,
        },
    });

    const submit = async (data: FormData) => {
        data = withPreparedPhoneData(data);
        const res = await withErrors<UpdatePhoneFormData>(dispatch(updatePhone(data)).unwrap(), setError);
        if (res !== false) {
            onSuccess && onSuccess();
        }
    };

    return (
        <div className={classNames('card')}>
            <div className={classNames('card-body', 'login-card-body')}>
                <p className={classNames('login-box-msg', 'pb-2', styles.title)}>Enter your new phone number</p>

                <form onSubmit={handleSubmit(submit)} className={classNames('form')}>
                    <FieldInternationalPhoneNumber
                        name="phoneNumber"
                        control={control}
                        clearErrors={clearErrors}
                        // register={register}
                        watch={watch}
                        label={''}
                        error={errors.phoneNumber?.message}
                        type={'tel'}
                        toggleProps={{
                            label: 'International format',
                            className: classNames(styles.toggleLabel),
                        }}
                    />
                    <div className={classNames('d-flex', 'justify-content-around', 'align-items-baseline')}>
                        {onBack && (
                            <span onClick={onBack} className={classNames(styles.backBtn)}>
                                Back
                            </span>
                        )}
                        <Button
                            type="submit"
                            className={classNames('btn-success', 'mt-3', styles.saveBtn)}
                            disabled={isSubmitting}
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdatePhone;
