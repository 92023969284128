import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import { deleteCondition } from 'src/store/thunks/condition-thunks';
import { Condition } from 'src/types/condition';
import { conditionLoadingSelector } from 'src/store/selectors/condition-selectors';

interface Props {
    condition: Condition;
    successCallback?: () => void;
    children: ReactNode;
}

const DeleteConditionAction: FC<Props> = ({ condition, children, successCallback }: Props) => {
    const dispatch = useDispatch();
    const loading = useSelector(conditionLoadingSelector);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleDelete = useCallback(async () => {
        await dispatch(deleteCondition(condition.id)).unwrap();
        setShowModal(false);
        toast.success('Condition was deleted');
        successCallback && successCallback();
    }, [condition.id]);

    return (
        <>
            <a href="#" onClick={handleShow}>
                {children}
            </a>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this condition?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classNames('btn-default')} onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        className={classNames('btn-danger')}
                        onClick={handleDelete}
                        disabled={loading}
                        loading={loading}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteConditionAction;
