import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'scores';

const list = (id: string, params: any): AxiosPromise<any> => EndPointService.get(`/scans/${id}/${ROUTE}`, params);

const create = (id: string, payload: any): AxiosPromise<any> => EndPointService.post(`/scans/${id}/${ROUTE}`, payload);

const update = (id: string, scoreId: string, payload: any): AxiosPromise<any> =>
    EndPointService.patch(`/scans/${id}/${ROUTE}/${scoreId}`, payload);

export default {
    list,
    create,
    update,
};
