import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import classNames from 'classnames';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { useDispatch } from 'src/hooks/dispatch';
import { bodyPartCurrentSelector } from 'src/store/selectors/body-part-selectors';
import Actions from 'src/screens/BodyPart/screens/View/components/Actions';
import BodyPartsInfo from 'src/screens/BodyPart/screens/View/components/BodyPartsInfo';
import { getBodyPart } from 'src/store/thunks/body-part-thunks';
import { makeBreadcrumbs } from 'src/screens/BodyPart/helpers/breadcrumb';

interface RouteParams {
    id: string;
}

interface Props {}

const BodyPartViewScreen: FC<Props> = () => {
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const bodyPart = useSelector(bodyPartCurrentSelector);

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getBodyPart(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                {bodyPart && (
                    <>
                        <ContentHeader
                            title={'View body part'}
                            breadcrumbs={[
                                { title: 'Body parts', link: Router.generate(routes.BODY_PART_LIST) },
                                ...makeBreadcrumbs(bodyPart),
                            ]}
                        />
                        <Content>
                            <div className={classNames('card card-primary card-outline card-outline-tabs')}>
                                <div className={classNames('card-header')}>{bodyPart ? `${bodyPart.name}` : ''}</div>
                                <div className={classNames('card-body')}>
                                    {bodyPart && <BodyPartsInfo bodyPart={bodyPart} />}
                                </div>
                                <div className={classNames('card-footer')}>
                                    <Actions bodyPart={bodyPart} />
                                </div>
                            </div>
                        </Content>
                    </>
                )}
                {!bodyPart && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default BodyPartViewScreen;
