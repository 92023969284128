import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const bodyPartStateSelector = (state: StoreState) => state.bodyPart;
export const bodyPartListSelector = createDraftSafeSelector(bodyPartStateSelector, (state) => state.data);
export const bodyPartErrorSelector = createDraftSafeSelector(bodyPartStateSelector, (state) => state.error);
export const bodyPartLoadingSelector = createDraftSafeSelector(
    bodyPartStateSelector,
    (state) => state.loading === 'loading',
);
export const bodyPartCurrentSelector = createDraftSafeSelector(bodyPartStateSelector, (state) => state.current);
