import React from 'react';
import BaseChangePasswordForm, {
    BaseChangePasswordFormData,
} from 'src/screens/Profile/components/BaseChangePasswordForm';
import { Dispatch } from 'redux';

interface Props {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChangePasswordForm: React.FC<Props> = (props: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSubmit = async (data: BaseChangePasswordFormData, dispatch: Dispatch) => {
        // await asyncUpdateAdminSystem(UserAuthService.getId() as string, data, dispatch);
    };

    return <BaseChangePasswordForm onSubmit={handleSubmit} />;
};

export default ChangePasswordForm;
