import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { resolveApiCall } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import ScanPHYScoreApi from 'src/services/end-points/scan-phy-score-api';
import { ScanPhyScore } from 'src/types/scan-phy-score';
import { Scan } from 'src/types/scan';

export const getScanPhyScore = createAsyncThunk<ScanPhyScore, Scan['id'], ThunkConfig>(
    'scan-phy-score/get',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanPhyScore } = getState();

        return resolveApiCall(thunkAPI, scanPhyScore, async () => {
            const response = await ScanPHYScoreApi.get(payload);
            return response.data;
        });
    },
);

export const updateScanPhyScore = createAsyncThunk<ScanPhyScore, { id: string } & Partial<ScanPhyScore>, ThunkConfig>(
    'scan-phy-score/update',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { scanPhyScore } = getState();

        return resolveApiCall(
            thunkAPI,
            scanPhyScore,
            async () => {
                const response = await ScanPHYScoreApi.update(id, payload);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);
