import * as yup from 'yup';

const scanPhyScoreSchema = yup.object().shape({
    headCervicalSpine: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Head/Cervical Spine*'),
    shouldersScapula: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Shoulders/Scapula*'),
    elbowWrists: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Elbow/Wrists*'),
    thoracicSpineRibcage: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Thoracic Spine/Ribcage*'),
    lumbarSpinePelvisSacrumCoccyx: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Lumbar Spine/Pelvis/Sacrum/Coccyx*'),
    hips: yup
        .number()
        .required()
        .typeError('field is required and must be a `number` type')
        .min(0)
        .max(10)
        .label('Hips*'),
    knees: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Knees*'),
    feet: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Feet*'),
    stance: yup
        .number()
        .typeError('field is required and must be a `number` type')
        .required()
        .min(0)
        .max(10)
        .label('Stance*'),
    stabilityStrengthScore: yup
        .number()
        .required()
        .typeError('field is required and must be a `number` type')
        .min(0)
        .max(10)
        .label('Stability/Strength Score*'),
});

export default scanPhyScoreSchema;
