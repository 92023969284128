import React, { FC, memo, PropsWithChildren } from 'react';
import EditableSelectColumn from 'src/components/react-table/columns/EditableSelectColumn';
import { updateScanScore } from 'src/store/thunks/scan-score-thunks';
import { useDispatch } from 'src/hooks/dispatch';
import { ScanScore } from 'src/types/scan-score';
import { useParams } from 'react-router';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import scanScoreValue from 'src/enumerables/scan-score-value';
import { useSelector } from 'react-redux';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';

type CellScanProps = PropsWithChildren<CellProps<ScanScore, number>>;

type Props = {
    props: CellScanProps;
};

const EditableScoreValueColumn: FC<Props> = ({
    props: {
        value,
        row: { original },
    },
}: Props) => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const scan = useSelector(scanCurrentSelector);

    const onSelectSubmit = async (data: number): Promise<boolean> => {
        try {
            await dispatch(updateScanScore({ scanId: id, id: original.id, value: data })).unwrap();
            return true;
        } catch (e: any) {
            const message = e.pop().error?.message || 'Something went wrong';
            toast.error(message);
            return false;
        }
    };

    return scan?.review ? (
        <>{scanScoreValue.getLabel(value)}</>
    ) : (
        <EditableSelectColumn
            onSubmit={onSelectSubmit}
            placement="top"
            value={value}
            text={scanScoreValue.getLabel(value)}
            data={scanScoreValue.mapData()}
        />
    );
};

export default memo(EditableScoreValueColumn);
