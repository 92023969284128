import { action, makeObservable, observable } from 'mobx';
import { BodyPart } from '../types/body-part';
import ConfigStore from 'src/stores/config.store';

export interface BodyPartsApi {
    list: () => Promise<BodyPart[]>;
}

export class BodyPartsStore {
    @observable
    public bodyParts: BodyPart[] = [];
    @observable
    public bodyPartsLoading = false;
    @observable
    public bodyPartsError: string | null = null;
    @observable
    public config: ConfigStore;

    constructor(config: ConfigStore) {
        this.config = config;
        makeObservable(this);
    }

    @action
    public setBodyParts = (bodyParts: BodyPart[]) => {
        this.bodyParts = observable(bodyParts);
    };

    public getById(bodyPartId: string): BodyPart | null {
        return this.recursiveSearch(this.bodyParts, (bp) => bp.id === bodyPartId).pop() || null;
    }

    public getByType(type: string): BodyPart[] {
        return this.recursiveSearch(this.bodyParts, (bp) => bp.types.includes(type));
    }

    /**
     * Returns all parents hierarchy (including given body part)
     */
    public getPath(bodyPartId: string): BodyPart[] {
        function recursiveSearch(bodyParts: BodyPart[]): BodyPart[] {
            for (const bodyPart of bodyParts) {
                if (bodyPart.id === bodyPartId) {
                    return [bodyPart];
                }

                if (bodyPart.children.length) {
                    const result = recursiveSearch(bodyPart.children);

                    if (result.length) {
                        return [bodyPart, ...result];
                    }
                }
            }

            return [];
        }

        return recursiveSearch(this.bodyParts);
    }

    private recursiveSearch(bodyParts: BodyPart[], comparisonFunc: (item: BodyPart) => boolean): BodyPart[] {
        const res = [];
        for (const bodyPart of bodyParts) {
            if (comparisonFunc(bodyPart)) {
                res.push(bodyPart);
            }
            if (bodyPart.children.length) {
                res.push(...this.recursiveSearch(bodyPart.children, comparisonFunc));
            }
        }

        return res;
    }
}

export default BodyPartsStore;
