import React from 'react';
import securityService from 'src/security/security';
import NoMatchScreen from 'src/screens/NoMatch';
import userRole from 'src/enumerables/user-role';
import AdminProfileScreen from './screens/Admin';
import StaffProfileScreen from './screens/Staff';
import ProviderProfileScreen from './screens/Provider';

interface Props {}

const ProfileScreen: React.FC<Props> = (props: Props) => {
    switch (true) {
        case securityService.isGranted(userRole.ROLE_ADMIN):
            return <AdminProfileScreen {...props} />;

        case securityService.isGranted(userRole.ROLE_PT):
            return <ProviderProfileScreen {...props} />;

        case securityService.isGranted(userRole.ROLE_STAFF):
            return <StaffProfileScreen {...props} />;

        default:
            return <NoMatchScreen />;
    }
};

export default ProfileScreen;
