import React from 'react';
import { Route } from 'react-router';
import routes from 'src/navigation/routes';
import { PointListScreen } from 'src/screens/Model/screens/Point/screens/List';
import { ModelListScreen } from 'src/screens/Model/screens/List';
import Authenticated from 'src/components/auth/Authenticated';
import Restricted from 'src/components/auth/Restricted';
import userRole from 'src/enumerables/user-role';
import { ModelReviewListScreen } from 'src/screens/Model/screens/Reviews/screens/List';
import { ModelReviewEditScreen } from 'src/screens/Model/screens/Reviews/screens/Edit';
import { ModelReviewViewScreen } from 'src/screens/Model/screens/Reviews/screens/View';

const modelRoutes = [
    <Route
        exact
        key={routes.MODEL_POINT_LIST}
        path={routes.MODEL_POINT_LIST}
        render={() => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <PointListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.MODEL_LIST}
        path={routes.MODEL_LIST}
        render={() => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ModelListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.MODEL_REVIEW_LIST}
        path={routes.MODEL_REVIEW_LIST}
        render={() => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <ModelReviewListScreen />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.MODEL_REVIEW_EDIT}
        path={routes.MODEL_REVIEW_EDIT}
        render={(props) => (
            <Authenticated>
                <Restricted authParams={[{ action: userRole.ROLE_ADMIN }, { action: userRole.ROLE_PT }]}>
                    <ModelReviewEditScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.MODEL_REVIEW_VIEW}
        path={routes.MODEL_REVIEW_VIEW}
        render={(props) => (
            <Authenticated>
                <Restricted
                    authParams={[
                        { action: userRole.ROLE_ADMIN },
                        { action: userRole.ROLE_PT },
                        { action: userRole.ROLE_STAFF },
                    ]}
                >
                    <ModelReviewViewScreen {...props} />
                </Restricted>
            </Authenticated>
        )}
    />,
];

export default modelRoutes;
