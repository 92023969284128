import React, { FC, memo, useEffect, useState } from 'react';
import Loader from 'src/components/Loader';
import styles from '../assets/json.module.scss';
import classNames from 'classnames';
import { Scan } from 'src/types/scan';

type ScanJsonProps = {
    scan: Scan;
};

const ScanJson: FC<ScanJsonProps> = ({ scan }: ScanJsonProps) => {
    const [json, setJson] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchJson = async () => {
        if (scan?.dataPath) {
            setLoading(true);
            fetch(scan.dataPath)
                .then((res) => res.text())
                .then((data) => {
                    setJson(data);
                    setLoading(false);
                    setError(null);
                })
                .catch(() => {
                    setJson(null);
                    setError("Couldn't load json file");
                    setLoading(false);
                });
        } else {
            setError('No data path');
        }
    };

    useEffect(() => {
        fetchJson();
    }, [scan?.id]);

    return (
        <div className={classNames('json-wrapper box box-error')}>
            <div className={classNames('box-body')}>
                <div className="json-text">
                    {json && <pre className={classNames(styles.preJson)}>{json}</pre>}
                    {error && <p className={classNames('text-danger')}>{error}</p>}
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default memo(ScanJson);
