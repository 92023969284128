import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'src/store/configure-store';
import { Paginated, PaginationService } from 'src/services/api-handlers/pagination';
import { QueryParams } from 'src/types/grid';
import SortingService from '../../services/sorting';
import { Staff } from 'src/types/staff';
import { StaffApi } from 'src/services/end-points';
import { handleError, resolveApiCall, toastErrorCallback } from 'src/services/api-handlers/api-resolver';
import { makeFormErrorsFromResponse } from 'src/components/react-hook-form/utils/make-form-errors';
import { convertValuesToObject } from 'src/components/react-hook-form/utils/convert-values';
import { UpdatePhoneFormData } from '../../screens/PhoneVerification/screens/ConfirmPhoneScreen/components/UpdatePhone';
import { VerifyFormData } from '../../screens/PhoneVerification/screens/VerifyPhoneScreen/components/VerifyPhoneCodeForm';
import { push } from 'connected-react-router';
import { routes } from '../../navigation';
import Router from '../../navigation/router';
import UserAuthService from '../../services/user-auth';
import { toast } from 'react-toastify';
import { ResourceCallbacks } from '../../components/Resource';

export const listStaff = createAsyncThunk<Paginated<Staff>, QueryParams, ThunkConfig>(
    'staff/list',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();
        const { filters, sorting, ...rest } = payload;

        return PaginationService.resolveApiCall(
            thunkAPI,
            staff,
            async () =>
                StaffApi.list({
                    ...filters,
                    ...SortingService.makeOrder(sorting),
                    ...rest,
                }),
            payload.page,
            staff.data,
        );
    },
);

export const getStaff = createAsyncThunk<Staff, Staff['id'], ThunkConfig>('staff/get', async (payload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { staff } = getState();

    return resolveApiCall(thunkAPI, staff, async () => {
        const response = await StaffApi.get(payload);
        return response.data;
    });
});

export const deleteStaff = createAsyncThunk<void, Staff['id'], ThunkConfig>(
    'staff/delete',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();

        return resolveApiCall(thunkAPI, staff, async () => StaffApi.remove(payload));
    },
);

export const createStaff = createAsyncThunk<Staff, { [key: string]: any }, ThunkConfig>(
    'staff/create',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();

        return resolveApiCall(
            thunkAPI,
            staff,
            async () => {
                const response = await StaffApi.create(convertValuesToObject(payload));
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const updateStaff = createAsyncThunk<Staff, { id: string } & Partial<Staff>, ThunkConfig>(
    'staff/update',
    async ({ id, ...payload }, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();

        return resolveApiCall(
            thunkAPI,
            staff,
            async () => {
                const response = await StaffApi.update(id, payload);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const updatePhone = createAsyncThunk<Staff, UpdatePhoneFormData, ThunkConfig>(
    'staff/update-phone',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();

        return resolveApiCall(
            thunkAPI,
            staff,
            async () => {
                const response = await StaffApi.updatePhone(payload);
                return response.data;
            },
            async (err) => makeFormErrorsFromResponse(err.response.data),
        );
    },
);

export const verifyPhone = createAsyncThunk<Staff, VerifyFormData, ThunkConfig>(
    'staff/verify-phone',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();

        return resolveApiCall(
            thunkAPI,
            staff,
            async () => {
                const response = await StaffApi.verifyPhone(payload);
                return response.data;
            },
            async (err) => {
                if (err.response?.status === 401) {
                    await UserAuthService.logout();
                    push(Router.generate(routes.HOME));
                    handleError(err);
                    toastErrorCallback(err);
                    const message = err.response?.data?.message;
                    toast.error(message || 'Your session has expired');

                    return;
                }

                return makeFormErrorsFromResponse(err.response.data);
            },
        );
    },
);

export const sendPhoneCode = createAsyncThunk<void, ResourceCallbacks, ThunkConfig>(
    'staff/send-phone-code',
    async (payload, thunkAPI) => {
        const { getState } = thunkAPI;
        const { staff } = getState();
        const { success, failure } = payload;

        return resolveApiCall(
            thunkAPI,
            staff,
            async () => {
                const { data } = await StaffApi.sendPhoneCode();
                success && (await success(data));
                return data;
            },
            async (err) => {
                failure && (await failure(err.payload));
                handleError(err);
                toastErrorCallback(err.payload);
                return err;
            },
        );
    },
);
