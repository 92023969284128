import React from 'react';
import GuestLayout from 'src/components/layout/GuestLayout';
import classNames from 'classnames';
import VerifyPhoneCodeForm from './components/VerifyPhoneCodeForm';
import { useUser } from 'src/hooks/user';
import Loader from 'src/components/Loader';

interface Props {}

const VerifyPhoneScreen: React.FC<Props> = ({}: Props) => {
    const user = useUser();

    return (
        <GuestLayout>
            <div className={classNames('login-page')}>{user ? <VerifyPhoneCodeForm user={user} /> : <Loader />}</div>
        </GuestLayout>
    );
};

export default VerifyPhoneScreen;
