import { isSelectableItem } from './selectable-item';
import lodash from 'lodash';

/**
 * Convert redux form values into object
 *
 * @param values
 */
export const convertValuesToObject = (values: { [key: string]: any }): Record<any, any> | null => {
    if (!values) {
        return null;
    }

    const result: Record<any, any> = {};

    lodash.forEach(values, (value: any, key: string) => {
        result[key] = convertValue(value);
    });

    return result;
};

const convertValue = (value: any): any => {
    if (isSelectableItem(value)) {
        return value.value;
    } else if (value instanceof Date) {
        return value.toISOString();
    } else if (value instanceof Array) {
        return value.map((element) => convertValue(element));
    } else if (lodash.isObject(value)) {
        return convertValuesToObject(value);
    } else {
        return value;
    }
};
