import { CellProps } from 'react-table';
import React, { FC, memo } from 'react';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-actions-column.module.scss';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModelReview } from 'src/types/model-review';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import routes from 'src/navigation/routes';
import { useParams } from 'react-router';

type Action = 'view' | 'edit';

interface Props extends CellProps<ModelReview> {
    actions: Action[];
}

const ModelReviewActionsColumn: FC<Props> = memo(({ cell: { value }, actions }: Props) => {
    const { id } = useParams<{ id: string }>();
    return (
        <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
            {actions.includes('view') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="conditions-view">View</Tooltip>}>
                    <Link
                        to={Router.generate(routes.MODEL_REVIEW_VIEW, { id, reviewId: value })}
                        className={classNames(['text-decoration-none', styles.actionLink])}
                    >
                        <FontAwesomeIcon icon={faEye} color="#0d6efd" />
                    </Link>
                </OverlayTrigger>
            )}
            {actions.includes('edit') && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="conditions-edit">Edit</Tooltip>}>
                    <Link
                        to={Router.generate(routes.MODEL_REVIEW_EDIT, { id, reviewId: value })}
                        className={classNames(['text-decoration-none', styles.actionLink])}
                    >
                        <FontAwesomeIcon icon={faEdit} color="#0d6efd" />
                    </Link>
                </OverlayTrigger>
            )}
        </div>
    );
});

export default ModelReviewActionsColumn;
