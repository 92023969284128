import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styles from './assets/sidebar-item-link.module.scss';

interface Props {
    title: string;
    to: string;
    icon: IconProp;
    isActive?: (props: Props) => boolean;
}

const SidebarItemLink: React.FunctionComponent<Props> = ({ title, to, icon, isActive: isActiveItem }: Props) => {
    const location = useLocation();
    const isActive = isActiveItem ? isActiveItem({ title, to, icon }) : location.pathname === to;

    return (
        <li className={classNames('nav-item')}>
            <Link to={to} className={classNames('nav-link', isActive && 'active')}>
                <FontAwesomeIcon className={classNames(styles.icon, 'nav-icon')} icon={icon} />
                <p>{title}</p>
            </Link>
        </li>
    );
};

export default SidebarItemLink;
