import React, { useCallback, useEffect } from 'react';
import Point from './Point';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import { observer } from 'mobx-react';
import { Point as PointType, PointType as PointTypeEnum } from 'src/types/points';
import ActivePoint from './ActivePoint';
import PointsStore from 'src/stores/points-store';

interface Props {
    display: boolean;
}

const ExistingPoints: React.FC<Props> = observer(({ display = false }: Props) => {
    const {
        points: pointsStore,
        modelScene,
        models: modelsStore,
    } = useStores<Omit<Stores, 'points'> & { points: PointsStore }>();

    const pointClickHandler = useCallback((event: any, point: PointType) => {
        event.stopPropagation();
        // dont do anything in created mode
        if (modelScene.createMode) return;

        modelScene.setActivePoint(modelScene.activePoint?.id !== point.id ? point : null);
    }, []);

    const isActivePoint = useCallback(
        (point: PointType) => {
            return modelScene.activePoint?.id === point.id || modelScene.isHighlighted(point);
        },
        [modelScene.activePoint?.id],
    );

    if (false === display) {
        return null;
    }

    return (
        <group>
            {pointsStore.filteredPoints.map((point) =>
                isActivePoint(point) ? (
                    <ActivePoint
                        {...point.coordinates}
                        z={point.coordinates.z as number}
                        key={point.id}
                        onClick={(e) => pointClickHandler(e, point)}
                    />
                ) : (
                    <Point
                        {...point.coordinates}
                        z={point.coordinates.z as number}
                        key={point.id}
                        onClick={(e) => pointClickHandler(e, point)}
                    />
                ),
            )}
        </group>
    );
});

export default ExistingPoints;
