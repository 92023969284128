import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { Paginated } from 'src/services/api-handlers/pagination';
import { scanListSelector, scanLoadingSelector } from 'src/store/selectors/scan-selectors';
import ScanList from 'src/screens/Scan/components/ScanList';
import { scanList } from 'src/store/thunks/scan-thunks';
import { Scan } from 'src/types/scan';

const ScanListScreen: FC = () => {
    const dispatch = useDispatch();
    const loading: boolean = useSelector(scanLoadingSelector);
    const data: Paginated<Scan> = useSelector(scanListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(scanList(params));
    }, []);

    return (
        <MainLayout>
            <ContentHeader title="Sessions" />
            <Content>
                <ScanList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default memo(ScanListScreen);
