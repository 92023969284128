import { createSlice } from '@reduxjs/toolkit';
import { login, logout, sendCode, verifyCode } from 'src/store/thunks/auth-thunks';
import { ReducerState } from 'src/store/configure-store';
import { takeOne } from 'src/helpers/store';

export interface AuthState extends ReducerState {
    // inProgress: boolean;
    // authorized: boolean;
    // error: { [key: string]: any };
}

const initialState: AuthState = {
    error: '',
    loading: 'none',
    requestIds: [],
    // inProgress: false,
    // authorized: false,
    // error: {},
};

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, takeOne.pendingActionCase)
            .addCase(login.fulfilled, takeOne.fulfilledActionCase)
            .addCase(login.rejected, takeOne.rejectedActionCase)
            .addCase(logout.pending, takeOne.pendingActionCase)
            .addCase(logout.fulfilled, takeOne.fulfilledActionCase)
            .addCase(logout.rejected, takeOne.rejectedActionCase)
            .addCase(sendCode.pending, takeOne.pendingActionCase)
            .addCase(sendCode.fulfilled, takeOne.fulfilledActionCase)
            .addCase(sendCode.rejected, takeOne.rejectedActionCase)
            .addCase(verifyCode.pending, takeOne.pendingActionCase)
            .addCase(verifyCode.fulfilled, takeOne.fulfilledActionCase)
            .addCase(verifyCode.rejected, takeOne.rejectedActionCase);
    },
});

export const {} = slice.actions;

export default slice.reducer;
