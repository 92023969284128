import React, { FC, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { modelListSelector, modelLoadingSelector } from 'src/store/selectors/model-selectors';
import { Paginated } from 'src/services/api-handlers/pagination';
import ModelList from 'src/screens/Scan/screens/Model/components/ModelList';
import ScanTabs from 'src/screens/Scan/components/ScanTabs';
import { modelList } from 'src/store/thunks/model-thunks';
import { Model } from 'src/types/model';
import { useParams } from 'react-router';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';
import { getScan } from 'src/store/thunks/scan-thunks';

const ModelListScreen: FC = () => {
    const dispatch = useDispatch();
    const loading: boolean = useSelector(modelLoadingSelector);
    const { id } = useParams<{ id: string }>();
    const data: Paginated<Model> = useSelector(modelListSelector);
    const scan = useSelector(scanCurrentSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(modelList({ ...params, filters: { ...params.filters, scan: id } }));
    }, []);

    useEffect(() => {
        if (scan?.id !== id) {
            dispatch(getScan(id));
        }
    }, [id]);

    return (
        <MainLayout>
            <ContentHeader
                title="Models"
                breadcrumbs={[
                    { title: 'Sessions', link: Router.generate(routes.SCAN_LIST) },
                    { title: `${scan?.name}`, link: Router.generate(routes.SCAN_VIEW, { id }) },
                ]}
            />
            <Content>
                <ScanTabs />
                <ModelList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default memo(ModelListScreen);
