import React, { PropsWithChildren, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import user from '../../services/user-auth';
import { routes } from '../../navigation';
import Router from '../../navigation/router';

type Strategy = 'fully' | 'guest' | 'anonymous';

interface Props {
    redirectTo?: string;
    strategy?: Strategy | Strategy[];
}

const Authenticated: React.FunctionComponent<Props> = ({
    children,
    redirectTo,
    strategy = 'fully',
}: PropsWithChildren<Props>) => {
    const strategyArr = strategy instanceof Array ? strategy : [strategy];

    const checkCallbacks: Record<Strategy, () => boolean> = {
        fully: () => user.isLoggedIn() && user.isFullyAuthenticated(),
        guest: () => user.isLoggedIn() && !user.isFullyAuthenticated(),
        anonymous: () => !user.isLoggedIn(),
    };

    for (const item of strategyArr) {
        if (checkCallbacks[item]()) {
            return children as ReactElement;
        }
    }

    return <Redirect to={redirectTo ? redirectTo : Router.generate(routes.HOME)} />;
};

export default Authenticated;
