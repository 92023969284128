import React, { FC } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { AppDispatch } from 'src/store/configure-store';
import { bodyPartValidationLoadingSelector } from 'src/store/selectors/body-part-validation-selectors';
import BodyPartValidationForm, {
    BodyPartValidationFormData,
} from 'src/screens/BodyPartValidation/components/BodyPartValidationForm';
import { createBodyPartValidation } from 'src/store/thunks/body-part-validation-thunks';
import { useParams } from 'react-router';

interface Props {}

const BodyPartValidationCreateScreen: FC<Props> = () => {
    const loading = useSelector(bodyPartValidationLoadingSelector);
    const { type } = useParams<{ type: string }>();
    const handleSubmit = async (data: BodyPartValidationFormData, dispatch: AppDispatch) => {
        await dispatch(createBodyPartValidation(data)).unwrap();
    };

    return (
        <MainLayout>
            <ContentHeader
                title={`Create ${type}`}
                breadcrumbs={[
                    {
                        title: 'Body part validation',
                        link: Router.generate(routes.BODY_PART_VALIDATION_LIST, { type }),
                    },
                ]}
            />
            <Content>
                <BodyPartValidationForm title={`Create ${type}`} type={type} onSubmit={handleSubmit} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default BodyPartValidationCreateScreen;
