import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/use-store';
import { Coordinates, Point } from 'src/types/points';
import { Stores } from 'src/stores';
import { useForm } from 'react-hook-form';
import FieldDropdown from 'src/components/common/form/FieldDropdown';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import classNames from 'classnames';
import { isSelectableItem, SelectableItem } from 'src/utils/selectable-item';
import FieldInput from 'src/components/common/form/FieldInput';
import Loader from 'src/components/common/Loader';
import { BodyPart } from 'src/types/body-part';
import useSceneStore from 'src/hooks/use-scene-store';

export interface PointFormData {
    bodyPart: SelectableItem | string;
    coordinates: Coordinates;
    model: string;
    type: string;
}

export interface Props {
    point?: Point;
    defaultValues?: Partial<PointFormData>;
    onSubmit: (data: PointFormData) => Promise<any>;
    children?: ReactNode;
}

const MAX_LEVEL = 3; // starts from 0
const MAX_POINT_NUMBER = 1;

const PointForm: React.FunctionComponent<Props> = observer(
    ({ point, onSubmit, defaultValues = {}, children }: Props) => {
        const { bodyParts: partsStore, points: pointsStore, models: modelsStore } = useStores<Stores>();
        const sceneStore = useSceneStore();
        const {
            control,
            handleSubmit,
            register,
            setValue,
            watch,
            formState: { isSubmitting, errors },
        } = useForm({
            resolver: yupResolver(
                yup.object().shape({
                    bodyPart: yup.mixed().required(),
                }),
            ),
            defaultValues: {
                ...(point || {}),
                bodyPart: point?.bodyPart?.id,
                ...defaultValues,
                bodyParts: [],
            } as any,
        });

        const { bodyParts, bodyPart: bodyPartValue } = watch() as any;

        const getRequiredBodyParts = () => {
            return partsStore.getByType(modelsStore.type as string);
        };

        const bodyPartIndexes = useMemo(() => {
            return Array.from(Array(MAX_LEVEL + 1).keys());
        }, [MAX_LEVEL]);

        const labelByIndex = useCallback((i: number) => {
            return ['Body Zone', 'Body Zone Side', 'Body Part', 'Body Detail'][i];
        }, []);

        /**
         * Find next required body part and set it as a value
         */
        const setNextBodyPart = useCallback((bodyParts: BodyPart[]) => {
            for (const bodyPart of bodyParts) {
                const exists = !!pointsStore.points.find((p) => p.bodyPart.id === bodyPart.id);

                if (!exists) {
                    const path = partsStore.getPath(bodyPart.id);
                    for (const i in path) {
                        setTimeout(() => {
                            path[i] && setValue(`bodyParts[${i}]`, path[i]);
                        }, 30 * Number(i + 0.5));
                    }
                    break;
                }
            }
        }, []);

        /**
         * Set bodyParts based on given point
         */
        const setValuesFromPoint = useCallback((point: Point) => {
            const ancestors = partsStore.getPath(point.bodyPart.id);
            const promises = ancestors.map((bodyPart, i) => {
                return new Promise<BodyPart | null>((resolve) => {
                    setTimeout(() => {
                        setValue(`bodyParts[${i}]`, bodyPart);
                        resolve(bodyPart);
                    }, 100 * i);
                });
            });

            return Promise.all(promises);
        }, []);

        useEffect(() => {
            register('bodyPart');
        }, []);

        /**
         * Init form
         */
        useEffect(() => {
            const init = async () => {
                register('bodyPart');

                if (point) {
                    await setValuesFromPoint(point);
                    return;
                }

                setNextBodyPart(getRequiredBodyParts());
            };

            init();
        }, []);

        /**
         * Yes, it is useEffect in the loop, there is no mistake here
         */
        for (let key = 0; key <= MAX_LEVEL; key++) {
            useEffect(() => {
                const bodyPart = bodyParts[key];
                if (!bodyPart) {
                    return;
                }

                const isLastChild = !bodyPart.children.length;
                const isInvalidChild = !!bodyParts[key + 1] && !bodyPart.children.includes(bodyParts[key + 1]);

                isLastChild
                    ? setValue('bodyPart', {
                          name: bodyPart.name,
                          value: bodyPart.id,
                      })
                    : setValue('bodyPart', null);

                isInvalidChild && setValue(`bodyParts[${key + 1}]`, null);
            }, [bodyParts[key]]);
        }

        /**
         * Disallow to add more points than MAX_POINT_NUMBER
         */
        useEffect(() => {
            const currentBodyPart = partsStore.getById(
                isSelectableItem(bodyPartValue) ? bodyPartValue.value : bodyPartValue,
            );
            const existingPointsLength = pointsStore.points.filter(
                ({ bodyPart }) => bodyPart.id === currentBodyPart?.id,
            ).length;

            sceneStore.createdPoints.forEach((createdPoint, i) => {
                if (i + 1 + existingPointsLength > (MAX_POINT_NUMBER || 0)) {
                    sceneStore.removeCreatedPoint(createdPoint as any);
                }
            });
        }, [sceneStore, sceneStore.createdPoints.length]);

        /**
         * Highlight points for selected body part
         */
        useEffect(() => {
            if (bodyPartValue) {
                sceneStore.setHighlightCriteria(
                    'bodyPart',
                    isSelectableItem(bodyPartValue) ? bodyPartValue.value : bodyPartValue,
                );
            } else {
                sceneStore.clearHighlightCriteria('bodyPart');
            }

            return () => sceneStore.clearHighlightCriteria('bodyPart');
        }, [sceneStore, bodyPartValue]);

        const submit = async (data: PointFormData) => {
            // remove unnecessary fields from result data
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { bodyParts, ...rest } = data as any;
            // if success submit and is create form, then move to next body part
            (await onSubmit(rest)) && !point && setNextBodyPart(getRequiredBodyParts());
        };

        return (
            <form onSubmit={handleSubmit(submit)}>
                <FieldInput
                    name={'model'}
                    register={register}
                    type={'hidden'}
                    wrapperProps={{
                        classNames: {
                            wrapperContainer: 'mb-0',
                        },
                    }}
                />

                {bodyPartIndexes.map((i) => {
                    const siblings = i === 0 ? partsStore.bodyParts : bodyParts[i - 1]?.children;
                    const bodyPart = bodyParts[i];

                    return siblings?.length ? (
                        <div key={i}>
                            <FieldDropdown
                                name={`bodyParts[${i}]`}
                                wrapperProps={{ label: labelByIndex(i) }}
                                control={control}
                                error={
                                    !bodyPart && errors.bodyPart?.message
                                        ? `${labelByIndex(i)} is a required field`
                                        : ''
                                }
                                data={siblings}
                                valueField={'id'}
                                textField={(item: BodyPart) => {
                                    if (!item) return '';
                                    return item.name + (item?.types.includes(modelsStore.type as any) ? '*' : '');
                                }}
                                busy={partsStore.bodyPartsLoading}
                            />
                            {bodyPart?.note && (
                                <p className="body-part-note">
                                    <i className="fa fa-exclamation-circle" />
                                    {bodyPart.note}
                                </p>
                            )}
                        </div>
                    ) : null;
                })}

                <div className={classNames('point-form-actions')}>
                    <button type="submit" className={classNames('btn btn-success')} disabled={isSubmitting}>
                        {point ? 'Save' : 'Save and Next'}
                    </button>
                    {children}
                </div>
                {isSubmitting && <Loader />}
            </form>
        );
    },
);

export default PointForm;
