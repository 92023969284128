import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import styles from '../assets/model-review-form.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import { toast } from 'react-toastify';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import Button from 'src/components/Button';
import { ModelReview } from 'src/types/model-review';
import modelReviewSchema from 'src/screens/Model/screens/Reviews/schema/model-review-schema';
import FieldTextArea from 'src/components/react-hook-form/fields/FieldTextArea';
import { routes } from 'src/navigation';
import { useHistory } from 'react-router-dom';

export type ModelReviewFormData = {
    comment: string;
};

interface ModelReviewFormProps {
    title: string;
    modelReview: ModelReview | null;
    onSubmit: (data: ModelReviewFormData, dispatch: AppDispatch) => Promise<any>;
}

const ModelReviewForm: FC<ModelReviewFormProps> = ({ title, onSubmit, modelReview }: ModelReviewFormProps) => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting, submitCount, isSubmitSuccessful, errors },
    } = useForm<ModelReviewFormData>({
        resolver: yupResolver(modelReviewSchema),
        defaultValues: { ...modelReview },
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const submit = async (data: ModelReviewFormData) => {
        await withErrors<ModelReviewFormData>(onSubmit(data, dispatch), setError);
    };

    useEffect(() => {
        isSubmitSuccessful && toast.success('Data was saved');
    }, [submitCount]);

    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('card', 'form')}>
            <div className={classNames('card-header')}>{title}</div>
            <div className={classNames('card-body')}>
                <div className={classNames('row')}>
                    <div className={classNames('col-md-6')}>
                        <FieldTextArea
                            control={control}
                            name="comment"
                            wrapperProps={{ label: modelReviewSchema.fields.comment.spec.label }}
                            error={errors.comment?.message}
                        />
                    </div>
                </div>
            </div>
            <div className={classNames(styles.actions, 'card-footer')}>
                <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
                <Button
                    className={classNames('btn', 'btn-danger')}
                    onClick={() => history.push(routes.MODEL_LIST)}
                    disabled={isSubmitting}
                >
                    <FontAwesomeIcon icon={faUndoAlt} /> Cancel
                </Button>
            </div>
        </form>
    );
};

export default ModelReviewForm;
