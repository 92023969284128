import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ScanPreview from 'scan-preview';
import classNames from 'classnames';
import ScanInfo from 'src/screens/Scan/screens/View/components/ScanInfo';
import ModelInfo from 'src/screens/Scan/screens/View/components/ModelInfo';
import ModelReviewForm from 'src/screens/Scan/screens/View/components/ModelReviewForm';
import ScanDeviceForm from 'src/screens/Scan/screens/View/components/ScanDeviceForm';
import styles from '../assets/scan-preview-container.module.scss';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { modelListSelector } from 'src/store/selectors/model-selectors';
import { Model } from 'src/types/model';
import { modelList } from 'src/store/thunks/model-thunks';
import { useDispatch } from 'src/hooks/dispatch';
import { Scan } from 'src/types/scan';
import { bodyPartListSelector } from 'src/store/selectors/body-part-selectors';
import { Paginated } from 'src/services/api-handlers/pagination';
import { BodyPartWithChildren } from 'src/types/body-part';
import {
    modelPointCreate,
    modelPointDelete,
    modelPointList,
    modelPointUpdate,
} from 'src/store/thunks/model-point-thunks';
import { Point } from 'src/types/model-point';
import ScanJson from 'src/screens/Scan/screens/View/components/ScanJson';
import { bodyPartTree } from 'src/store/thunks/body-part-thunks';
import Authorized from 'src/components/auth/Authorized';
import { fetchAll } from 'src/helpers/fetch-all';
import userRole from 'src/enumerables/user-role';
import { Card, Nav, Tab } from 'react-bootstrap';
import ScansReview from 'src/screens/Scan/screens/View/components/ScansReview';

interface Props {
    scan: Scan;
    modelId?: string;
}

const ScanModelPreview: React.FC<Props> = ({ scan, modelId }: Props) => {
    const dispatch = useDispatch();
    const models = useSelector(modelListSelector);
    const bodyParts = useSelector(bodyPartListSelector) as Paginated<BodyPartWithChildren>;
    const [points, setPoints] = useState<Point[]>([]);
    const [selectedModel, setSelectedModel] = useState<Model | null>(null);
    const [isImage, setIsImage] = useState(false);

    const prepareModel = useCallback(
        (item: Model) => ({
            ...item,
            canEditModel: !item.isApproved,
            canEditImage: !item.isImageApproved,
        }),
        [],
    );

    const preparePoint = useCallback(
        (item: Point) => ({
            ...item,
            createdBy: item.createdBy
                ? {
                      ...item.createdBy,
                      fullName: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
                      role: item.createdBy.roles[0],
                  }
                : null,
        }),
        [],
    );

    const modelsPrepared = useMemo(() => models.list.map(prepareModel), [models.list]);
    const pointsPrepared = useMemo(() => {
        return points.filter(({ type }) => (isImage ? type === 'image' : type === 'model')).map(preparePoint);
    }, [points, isImage]);
    const bodyPartsPrepared = bodyParts.list;

    const fetchModels = useCallback(() => {
        dispatch(modelList({ limit: -1, filters: { scan: scan.id } }));
    }, [scan.id]);

    const fetchBodyPartTree = useCallback(() => {
        dispatch(bodyPartTree());
    }, [scan.id]);

    const fetchAllPoints = useCallback(async (scanBodyModel: string) => {
        setPoints(
            await fetchAll((page) => {
                return dispatch(modelPointList({ filters: { scanBodyModel }, limit: -1, page })).unwrap() as any;
            }),
        );
    }, []);

    const onModelChange = useCallback(
        async (model: any) => {
            setSelectedModel(models.list.find(({ id }) => id === model.id) || null);
            fetchAllPoints(model.id);
        },
        [models.list],
    );

    const onPointCreate = useCallback(async ({ model, ...data }: any) => {
        const point = await dispatch(modelPointCreate({ ...data, scanBodyModel: model })).unwrap();
        setPoints((prev) => [...prev, point]);

        return preparePoint(point);
    }, []);

    const onPointUpdate = useCallback(async (id: string, data: any) => {
        const point = await dispatch(modelPointUpdate({ id, ...data })).unwrap();
        setPoints((prev) => {
            const cur = [...prev];
            const idx = cur.findIndex((item) => item.id === id);

            cur[idx] = point;
            return cur;
        });

        return preparePoint(point);
    }, []);

    const onPointDelete = useCallback(async (id: string) => {
        await dispatch(modelPointDelete(id)).unwrap();
        setPoints((prev) => prev.filter((item) => item.id !== id));
    }, []);

    const onNotification = useCallback((type: 'error' | 'success', text: string) => {
        type === 'error' ? toast.error(text) : toast.success(text);
    }, []);

    const config = useMemo(() => {
        return {
            events: {
                onNotification,
                onPointCreate,
                onPointDelete,
                onPointUpdate,
                onModelChange,
                onLayoutChange: setIsImage,
            },
            showFilters: true,
            showLayers: true,
        };
    }, [onNotification, onPointCreate, onPointDelete, onPointUpdate, onModelChange, setIsImage]);

    useEffect(() => {
        fetchModels();
        fetchBodyPartTree();
    }, [scan.id]);

    return (
        <div>
            {!!modelsPrepared.length && (
                <ScanPreview
                    preSelectedModel={modelId}
                    models={modelsPrepared}
                    bodyParts={bodyPartsPrepared}
                    points={pointsPrepared}
                    config={config}
                />
            )}

            {selectedModel ? (
                <div className={classNames(styles.sideControls)}>
                    <div className={classNames(styles.controlBlock, styles.controlBlockInfo)}>
                        <ScanInfo scan={scan} />
                        <ModelInfo model={selectedModel} />
                    </div>
                    <div className={classNames(styles.controlBlock, styles.reviewForm)}>
                        <Tab.Container defaultActiveKey="model-review">
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="model-review" className={'text-black'}>
                                        Model Review
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="device-info" className={'text-black'}>
                                        Device Info
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={styles.tabNavItem}>
                                    <Nav.Link eventKey="json" className={'text-black'}>
                                        Json
                                    </Nav.Link>
                                </Nav.Item>
                                <Authorized actions={[userRole.ROLE_ADMIN, userRole.ROLE_PT]}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="scan-review" className={classNames('text-black')}>
                                            <span>Scan Review</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Authorized>
                            </Nav>
                            <Card.Body>
                                <Tab.Content>
                                    <Tab.Pane eventKey="model-review">
                                        <ModelReviewForm
                                            model={selectedModel}
                                            type={isImage ? 'image' : 'model'}
                                            onSubmitted={fetchModels}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="device-info">
                                        <ScanDeviceForm scan={scan} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="json">
                                        <ScanJson scan={scan} />
                                    </Tab.Pane>
                                    <Authorized actions={[userRole.ROLE_ADMIN, userRole.ROLE_PT]}>
                                        <Tab.Pane eventKey="scan-review">
                                            <ScansReview scan={scan} />
                                        </Tab.Pane>
                                    </Authorized>
                                </Tab.Content>
                            </Card.Body>
                        </Tab.Container>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ScanModelPreview;
