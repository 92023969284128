import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const modelPointStateSelector = (state: StoreState) => state.modelPoint;
export const modelPointListSelector = createDraftSafeSelector(modelPointStateSelector, (state) => state.data);
export const modelPointErrorSelector = createDraftSafeSelector(modelPointStateSelector, (state) => state.error);
export const modelPointLoadingSelector = createDraftSafeSelector(
    modelPointStateSelector,
    (state) => state.loading === 'loading',
);
export const modelPointCurrentSelector = createDraftSafeSelector(modelPointStateSelector, (state) => state.current);
