import { Route } from 'react-router';
import React from 'react';
import routes from '../routes';
import LogoutScreen from '../../screens/Auth/screens/Logout';
import LoginScreen from 'src/screens/Auth/screens/Login';
import Authenticated from 'src/components/auth/Authenticated';
import VerifyCodeScreen from '../../screens/Auth/screens/VerifyCode';
import Router from '../router';

const authRoutes = [
    <Route
        exact
        key={routes.HOME}
        path={routes.HOME}
        render={() => (
            <Authenticated strategy={['anonymous', 'guest']} redirectTo={Router.generate(routes.DASHBOARD)}>
                <LoginScreen />
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.VERIFY_CODE}
        path={routes.VERIFY_CODE}
        render={() => (
            <Authenticated strategy={'guest'}>
                <VerifyCodeScreen />
            </Authenticated>
        )}
    />,
    <Route
        exact
        key={routes.LOGOUT}
        path={routes.LOGOUT}
        render={() => (
            <Authenticated strategy={['fully', 'guest']}>
                <LogoutScreen />
            </Authenticated>
        )}
    />,
];

export default authRoutes;
