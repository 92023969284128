import { EnumerableAbstract } from './enumerable.abstract';

class ModelStep extends EnumerableAbstract {
    public readonly STEP_1 = 1;
    public readonly STEP_2 = 2;
    public readonly STEP_3 = 3;
    public readonly STEP_4 = 4;

    public getName = () => 'ModelStep';

    public defaultChoices = () => ({
        [this.STEP_1]: 'Low quality step',
        [this.STEP_2]: 'Medium quality step',
        [this.STEP_3]: 'Good quality step',
        [this.STEP_4]: 'Video',
    });
}

const modelStep = new ModelStep();

export default modelStep;
