import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { staffCurrentSelector } from 'src/store/selectors/staff-selectors';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import classNames from 'classnames';
import StaffInfo from './components/StaffInfo';
import Actions from './components/Actions';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { getStaff } from 'src/store/thunks/staff-thunks';
import { useDispatch } from 'src/hooks/dispatch';

interface RouteParams {
    id: string;
}

interface Props {}

const ViewScreen: React.FunctionComponent<Props> = () => {
    const { id } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const staff = useSelector(staffCurrentSelector);

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getStaff(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title={'View staff'}
                    breadcrumbs={[{ title: 'Staff', link: Router.generate(routes.STAFF_LIST) }]}
                />
                <Content>
                    <div className={classNames('card card-primary card-outline card-outline-tabs')}>
                        <div className={classNames('card-header')}>
                            {staff ? `${staff.firstName} ${staff.lastName}` : ''}
                        </div>
                        <div className={classNames('card-body')}>{staff && <StaffInfo staff={staff} />}</div>
                        <div className={classNames('card-footer')}>
                            <Actions staff={staff} />
                        </div>
                    </div>
                </Content>
                {!staff && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default ViewScreen;
