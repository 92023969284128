import { useEffect, useMemo, useState } from 'react';
import securityService from 'src/security/security';
import userRole from 'src/enumerables/user-role';
import { useDispatch, useSelector } from 'react-redux';
import { staffCurrentSelector } from 'src/store/selectors/staff-selectors';
import user from '../services/user-auth';
import { getStaff } from '../store/thunks/staff-thunks';
import { Staff } from '../types/staff';

export function useUser<T extends Staff = Staff>(): T | null {
    const [currentUser, setCurrentUser] = useState<T | null>(null);
    const [userId, setUserId] = useState(user.getId());
    const dispatch = useDispatch();
    const users = {
        [userRole.ROLE_STAFF]: useSelector(staffCurrentSelector),
        [userRole.ROLE_ADMIN]: useSelector(staffCurrentSelector),
        [userRole.ROLE_PT]: useSelector(staffCurrentSelector),
    };

    const [role, action] = useMemo(() => {
        if (!userId) {
            return [null, null];
        }

        switch (true) {
            case securityService.isGranted(userRole.ROLE_ADMIN):
                return [userRole.ROLE_ADMIN, getStaff(userId)];
            case securityService.isGranted(userRole.ROLE_STAFF):
                return [userRole.ROLE_STAFF, getStaff(userId)];
            case securityService.isGranted(userRole.ROLE_PT):
                return [userRole.ROLE_PT, getStaff(userId)];
            default:
                return [null, null];
        }
    }, [userId]);

    useEffect(() => {
        if (action) {
            dispatch(action);
        }
    }, [action]);

    useEffect(() => {
        if (role) {
            const user = users[role as keyof typeof users] as T;
            setCurrentUser(user);
        }
    }, [role, ...Object.values(users)]);

    useEffect(() => {
        setUserId(user.getId());
    }, [user.getId()]);

    return currentUser;
}
