import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import classNames from 'classnames';
import Visible from 'src/components/common/Visible';
import { Mesh, Object3D } from 'three';

interface Props {}

const LayersForm: React.FC<Props> = observer((props: Props) => {
    const { modelScene } = useStores<Stores>();
    const [show, setShow] = useState(false);

    const showToggle = useCallback(() => {
        setShow((currentVal) => !currentVal);
    }, [setShow]);

    const row = (layer: Mesh) => {
        const { visible, opacity } = modelScene.getLayerOptions(layer as Mesh);

        return (
            <div key={layer.uuid} className={classNames('layers-row')}>
                <p>{layer.name}</p>
                <div className={classNames('inputs-wrap')}>
                    <label>
                        <input
                            type="checkbox"
                            checked={visible}
                            onChange={(e) => modelScene.setLayerOption(layer, 'visible', e.target.checked)}
                        />
                        Visible
                    </label>

                    <div className={classNames('layers-opacity')}>
                        <input
                            type="range"
                            max={1}
                            min={0}
                            step={0.1}
                            value={opacity}
                            onChange={(e) =>
                                modelScene.setLayerOption(layer as Mesh, 'opacity', Number(e.target.value))
                            }
                        />
                        Opacity
                    </div>
                </div>
            </div>
        );
    };

    const renderRow = (item: Object3D): React.ReactNode => {
        if (item instanceof Mesh) {
            return row(item);
        }

        return item.children.map(renderRow);
    };

    return (
        <React.Fragment>
            {!show && (
                <button type="button" className={classNames('btn', 'btn-primary', 'btn-block')} onClick={showToggle}>
                    Layers
                </button>
            )}
            {show && (
                <button className={classNames('close-btn')} onClick={showToggle}>
                    &#10006;
                </button>
            )}
            <Visible if={show}>
                <div className={classNames('layers-form')}>{modelScene.scene?.children.map(renderRow)}</div>
            </Visible>
        </React.Fragment>
    );
});

export default LayersForm;
