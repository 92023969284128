import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import { useDispatch } from 'src/hooks/dispatch';
import { QueryParams } from 'src/types/grid';
import { Paginated } from 'src/services/api-handlers/pagination';
import ModelReviewList from 'src/screens/Model/screens/Reviews/components/ModelReviewList';
import { modelReviewListSelector, modelReviewLoadingSelector } from 'src/store/selectors/mode-review-selectors';
import { modelReviewList } from 'src/store/thunks/model-review-thunks';
import { ModelReview } from 'src/types/model-review';
import { useParams } from 'react-router';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';

const ModelReviewListScreen: FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const loading: boolean = useSelector(modelReviewLoadingSelector);
    const data: Paginated<ModelReview> = useSelector(modelReviewListSelector);

    const getData = useCallback((params: QueryParams) => {
        dispatch(modelReviewList({ ...params, id }));
    }, []);

    return (
        <MainLayout>
            <ContentHeader
                title="Reviews"
                breadcrumbs={[{ title: 'Models', link: Router.generate(routes.MODEL_LIST) }]}
            />
            <Content>
                <ModelReviewList data={data} getData={getData} />
                {loading && <Loader />}
            </Content>
        </MainLayout>
    );
};

export default ModelReviewListScreen;
