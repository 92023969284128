import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import React, { FC } from 'react';
import { QueryParams } from 'src/types/grid';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import { Condition } from 'src/types/condition';
import ConditionActionsColumn from 'src/screens/Condition/components/ConditionActionsColumn';
import classNames from 'classnames';
import styles from 'src/screens/Condition/assets/condition-list.module.scss';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

type Props = {
    data: Paginated<Condition>;
    getData: (params: QueryParams) => void;
};

const conditionColumns: Column<Condition>[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Body Part',
        accessor: 'bodyPart',
        Cell: ({ value: { name, id } }) => (
            <Link to={Router.generate(routes.BODY_PART_EDIT, { id })} className={'text-decoration-none'}>
                {name}
            </Link>
        ),
        width: '15%',
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <ConditionActionsColumn {...props} actions={['view', 'edit', 'delete']} />,
        disableSortBy: true,
        disableFilters: true,
        width: '10%',
    },
];

const ConditionList: FC<Props> = ({ data, getData }: Props) => {
    return (
        <Grid<Condition>
            columns={conditionColumns}
            data={data}
            getData={getData}
            title={
                <div className={classNames(styles.heading, 'd-flex', 'justify-content-end')}>
                    <Link className={classNames('text-decoration-none')} to={Router.generate(routes.CONDITION_CREATE)}>
                        <FontAwesomeIcon icon={faPlus} /> Create
                    </Link>
                </div>
            }
            defaultSorting={{ createdAt: 'DESC' }}
        />
    );
};

export default ConditionList;
