import React, { FC, memo } from 'react';
import { CellProps } from 'react-table';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import DeleteScanCondition from 'src/screens/Scan/screens/Condition/components/DeleteScanCondition';
import styles from '../assets/scan-condition-actions-column.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ScanCondition } from 'src/types/scan-condition';

type Action = 'delete';

interface Props extends CellProps<ScanCondition> {
    actions: Action[];
}

const ScanConditionActionsColumn: FC<Props> = memo(
    ({
        cell: {
            row: { original },
        },
        actions,
    }: Props) => (
        <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
            {actions.includes('delete') && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-body-part-validation-delete">Delete</Tooltip>}
                >
                    <div>
                        <DeleteScanCondition scanCondition={original}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </DeleteScanCondition>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    ),
);

export default ScanConditionActionsColumn;
