import { BodyPart } from 'src/types/body-part';
import { Breadcrumb } from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';

export const makeBreadcrumbs = (bodyPart: BodyPart): Breadcrumb[] => {
    return bodyPart.path.map((bp) => ({
        title: bp.name,
        link: Router.generate(routes.BODY_PART_CHILDREN, { id: bp.id }),
    }));
};
