import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'statistic';

const modelsStatistic = (params: any): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/models`, params);

const scanStatistic = (): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/scans`);

export default {
    scanStatistic,
    modelsStatistic,
};
