import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const scanConditionStateSelector = (state: StoreState) => state.scanCondition;
export const scanConditionListSelector = createDraftSafeSelector(scanConditionStateSelector, (state) => state.data);
export const scanConditionErrorSelector = createDraftSafeSelector(scanConditionStateSelector, (state) => state.error);
export const scanConditionLoadingSelector = createDraftSafeSelector(
    scanConditionStateSelector,
    (state) => state.loading === 'loading',
);
export const scanConditionCurrentSelector = createDraftSafeSelector(
    scanConditionStateSelector,
    (state) => state.current,
);
