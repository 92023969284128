import { action, makeObservable, observable } from 'mobx';
import { DoubleSide, Group, Mesh, Side } from 'three';
import { AbstractSceneStore } from './abstract-scene-store';
import ConfigStore from './config.store';

export type LayerOptions = {
    visible: boolean;
    opacity: number;
    side: Side;
};

export class ModelSceneStore extends AbstractSceneStore {
    @observable
    public wireframeMode = false;
    @observable
    public scene: Group | null = null;
    @observable
    public layersOptions: Record<string, LayerOptions> = {};

    constructor(config: ConfigStore) {
        super(config);
        makeObservable(this);
    }

    @action
    public setScene(scene: Group | null) {
        this.scene = scene;
    }

    @action
    public setWireframeMode(value: boolean) {
        this.wireframeMode = value;
    }

    public getLayerOptions(item: Mesh) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { visible: true, opacity: 1, side: DoubleSide, ...(this.layersOptions[item.uuid] || {}) };
    }

    @action
    public setLayerOption<T extends keyof LayerOptions>(item: Mesh, name: T, value: LayerOptions[T]) {
        this.layersOptions = {
            ...this.layersOptions,
            [item.uuid]: {
                ...(this.layersOptions[item.uuid] || {}),
                [name]: value,
            },
        };
    }
}

export default ModelSceneStore;
