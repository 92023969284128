import { EnumerableAbstract } from './enumerable.abstract';

class BodyPartValidationType extends EnumerableAbstract {
    public readonly GENERAL = 'general';
    public readonly SQUAT = 'squat';
    public readonly IMAGE = 'image';

    public getName = () => 'UserRole';

    public defaultChoices = () => ({
        [this.GENERAL]: 'General',
        [this.SQUAT]: 'Squat',
        [this.IMAGE]: 'Image',
    });
}

const bodyPartValidationType = new BodyPartValidationType();

export default bodyPartValidationType;
