import React, { FC, memo } from 'react';
import { CellProps } from 'react-table';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteBodyPartValidation from 'src/screens/BodyPartValidation/components/DeleteBodyPartValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from '../assets/body-part-validation-actions-column.module.scss';
import { BodyPartValidation } from 'src/types/body-part-validation';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Action = 'delete';

interface Props extends CellProps<BodyPartValidation> {
    actions: Action[];
}

const BodyPartValidationActionsColumn: FC<Props> = memo(
    ({
        cell: {
            row: { original },
        },
        actions,
    }: Props) => (
        <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
            {actions.includes('delete') && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-body-part-validation-delete">Delete</Tooltip>}
                >
                    <div>
                        <DeleteBodyPartValidation bodyPartValidation={original}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </DeleteBodyPartValidation>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    ),
);

export default BodyPartValidationActionsColumn;
