import React, { PropsWithChildren } from 'react';
import styles from './assets/main.module.scss';
import classNames from 'classnames';
import { Argument as ClassValue } from 'classnames';

interface Props {
    className?: ClassValue;
}

const Main: React.FunctionComponent<Props> = ({ className, children }: PropsWithChildren<Props>) => (
    <main className={classNames(styles.main, className)}>{children}</main>
);

export default Main;
