import lodash from 'lodash';

type FiltersType = { [T: string]: string | number | Record<string, string | number> };
type PreparedFiltersType = { [T: string]: string | number };

class Filter {
    public makeFilters(filters?: FiltersType): PreparedFiltersType {
        const preparedFilters: PreparedFiltersType = {};

        if (!filters) {
            return preparedFilters;
        }

        lodash.forEach(filters, (value, key) => {
            if (value instanceof Object) {
                Object.keys(value).map((key) => {
                    preparedFilters[key] = value[key];
                });
                return;
            }

            preparedFilters[key] = value;
        });

        return preparedFilters;
    }
}

const filterService = new Filter();

export default filterService;
