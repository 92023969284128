import * as React from 'react';
import { SelectableItem } from '../../../enumerables/enumerable.abstract';
import { FilterProps } from './types';
import FieldFetchingDropdown from 'src/components/react-hook-form/fields/FieldFetchingDropdown';
import { Paginated } from 'src/services/api-handlers/pagination';
import { BodyPart } from 'src/types/body-part';
import { useSelector } from 'react-redux';
import { bodyPartListSelector, bodyPartLoadingSelector } from 'src/store/selectors/body-part-selectors';
import { useCallback, useEffect } from 'react';
import { bodyPartList } from 'src/store/thunks/body-part-thunks';
import { QueryParams } from 'src/types/grid';
import { useDispatch } from 'src/hooks/dispatch';
import { useForm } from 'react-hook-form';
import FieldDropdown from 'src/components/react-hook-form/fields/FieldDropdown';

type Args = QueryParams & { fetchingDropdown: boolean };

function bodyPartFilter<T extends Record<any, any>>({
    fetchingDropdown,
    filters,
    ...params
}: Args): React.FunctionComponent<FilterProps<T>> {
    return React.memo(({ column: { setFilter } }: FilterProps<T>) => {
        const dispatch = useDispatch();
        const { control, watch } = useForm();
        const bodyPartsData: Paginated<BodyPart> = useSelector(bodyPartListSelector);
        const bodyPartSelectableList: SelectableItem[] = bodyPartsData.list.map(({ name, id }) => ({
            name,
            value: id,
        }));
        const bodyPartsLoading = useSelector(bodyPartLoadingSelector);

        const fetchBodyParts = useCallback((page: number, searchTerm?: string) => {
            return dispatch(
                bodyPartList({ filters: { name: searchTerm, ...filters }, limit: -1, page, ...params }),
            ).unwrap();
        }, []);

        const { bodyPart } = watch();

        const preparedData = [{ name: '', value: null }, ...bodyPartSelectableList];

        useEffect(() => {
            setFilter(bodyPart?.value);
        }, [bodyPart]);

        useEffect(() => {
            if (!fetchingDropdown) {
                fetchBodyParts(1);
            }
        }, []);

        return fetchingDropdown ? (
            <FieldFetchingDropdown
                busy={bodyPartsLoading}
                disabled={bodyPartsLoading}
                control={control}
                data={preparedData}
                fetchData={fetchBodyParts}
                name="bodyPart"
                wrapperProps={{ noWrapper: true }}
                textField={'name'}
                valueField={'value'}
                page={bodyPartsData.page}
                maxPage={bodyPartsData.totalPages as number}
                fetchOnSearch={true}
            />
        ) : (
            <FieldDropdown
                busy={bodyPartsLoading}
                disabled={bodyPartsLoading}
                control={control}
                data={preparedData}
                name="bodyPart"
                wrapperProps={{ noWrapper: true }}
                textField={'name'}
                valueField={'value'}
            />
        );
    });
}

export default bodyPartFilter;
