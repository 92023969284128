import { User } from './user';
import { SelectableItem } from '../utils/selectable-item';
import { BodyPartBase } from '../types/body-part';

export enum PointType {
    Model = 'model',
    Image = 'image',
}

export interface Point {
    id: string;
    model: string;
    bodyPart: BodyPartBase;
    coordinates: Coordinates;
    createdBy: User | null;
}

export interface Coordinates {
    x: number;
    y: number;
    z?: number;
}

export interface PointCreateData {
    bodyPart: SelectableItem | string;
    coordinates: Coordinates;
    model: string;
    type: string;
}
