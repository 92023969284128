import React, { PointerEvent, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import Loader from 'src/components/common/Loader';
import { v4 as uuid } from 'uuid';
import CreatedPoints from './components/CreatedPoints';
import ExistingPoints from './components/ExistingPoints';

interface Props {}

type MouseCords = { x: number; y: number };

const MOUSE_SIGNIFICANT_MOVE = 5; // pixels

const ImageScene: React.FC<Props> = observer((props: Props) => {
    const { models: modelsStore, imageScene } = useStores<Stores>();
    const [loading, setLoading] = useState(true);
    const wrapperRef = useRef<ReactZoomPanPinchRef>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const pointerDownPosRef = useRef<MouseCords>({ x: 0, y: 0 });

    const isMouseSignificallyMoved = useCallback(({ x, y }: MouseCords) => {
        return (
            Math.abs(pointerDownPosRef.current.x - x) > MOUSE_SIGNIFICANT_MOVE ||
            Math.abs(pointerDownPosRef.current.y - y) > MOUSE_SIGNIFICANT_MOVE
        );
    }, []);

    const imagePointerDownHandler = useCallback((e: PointerEvent<HTMLImageElement>) => {
        pointerDownPosRef.current.x = e.clientX;
        pointerDownPosRef.current.y = e.clientY;
    }, []);

    const imagePointerUpHandler = useCallback((e: PointerEvent<HTMLImageElement>) => {
        // should not add points if not in create mode
        if (!imageRef.current || !imageScene.createMode) {
            return;
        }
        // should not add points if user tries to zoom/change image position
        if (isMouseSignificallyMoved({ x: e.clientX, y: e.clientY })) {
            return;
        }

        const rect = imageRef.current?.getBoundingClientRect();
        if (rect) {
            const x = e.clientX - rect.left; // x position within the image.
            const y = e.clientY - rect.top; // y position within the image.
            const heightRatio = rect.height / imageRef.current.height; // image original height to rendered height ratio
            const widthRatio = rect.width / imageRef.current.width; // image original width to rendered width ratio
            const finalX = x / widthRatio;
            const finalY = y / heightRatio;

            imageScene.addCreatedPoint({ id: uuid(), coordinates: { x: finalX, y: finalY } });
        }
    }, []);

    return (
        <TransformWrapper
            ref={wrapperRef}
            initialScale={0.5}
            maxScale={2}
            minScale={0.5}
            centerOnInit={false}
            centerZoomedOut={true}
        >
            <TransformComponent wrapperClass={classNames('image-preview-wrapper')}>
                <img
                    style={{ pointerEvents: 'auto' }}
                    ref={imageRef}
                    src={modelsStore.activeModel?.imageUrl}
                    onLoad={() => {
                        wrapperRef.current?.centerView();
                        setLoading(false);
                    }}
                    onPointerDown={imagePointerDownHandler}
                    onPointerUp={imagePointerUpHandler}
                />

                <CreatedPoints />

                <ExistingPoints />
            </TransformComponent>
            {loading && <Loader />}
        </TransformWrapper>
    );
});

export default ImageScene;
