import React, { useEffect, useState } from 'react';
import { Provider } from 'mobx-react';
import { PointsStore } from './stores/points-store';
import { BodyPartsStore } from './stores/body-parts-store';
import ConfigStore from './stores/config.store';
import ModelsStore from './stores/models-store';
import ModelSceneStore from './stores/model-scene-store';
import { Config as BaseConfig } from './types/config';
import ImageSceneStore from './stores/image-scene-store';
import SceneContainer from './components/scene/SceneContainer';

interface Props extends Config {
    preSelectedModel?: string;
}

// need to move stores outside component or mobx will throw error about changed stores on rerender
const config = new ConfigStore();
const modelsStore = new ModelsStore(config);
const pointsStore = new PointsStore(config);
const bodyPartsStore = new BodyPartsStore(config);
const modelScene = new ModelSceneStore(config);
const imageScene = new ImageSceneStore(config);

const ScanPreview: React.FC<Props> = ({ models, points, config: init, bodyParts, preSelectedModel }: Props) => {
    // const [configInitiated, setConfigInitiated] = useState(false);
    // this should be the first
    useEffect(() => {
        config.setConfig(init);
        // setConfigInitiated(true);
    }, [config]);

    useEffect(() => {
        modelsStore.setModels(models, preSelectedModel);
    }, [models]);

    useEffect(() => {
        pointsStore.setPoints(points);
    }, [points]);

    useEffect(() => {
        bodyPartsStore.setBodyParts(bodyParts);
    }, [bodyParts]);

    return (
        <Provider
            points={pointsStore}
            bodyParts={bodyPartsStore}
            config={config}
            models={modelsStore}
            modelScene={modelScene}
            imageScene={imageScene}
        >
            {config.isConfigInitiated && (
                <React.Fragment>
                    <SceneContainer />
                </React.Fragment>
            )}
        </Provider>
    );
};

export default ScanPreview;
export type Config = BaseConfig;
