import { useCallback, useEffect, useState } from 'react';

type CountdownOptions = {
    initialValue?: number;
    endValue?: number;
    revers?: boolean;
};

export const useCountdown = ({ initialValue = 0, endValue, revers = false }: CountdownOptions) => {
    const [value, setValue] = useState(initialValue);
    const [timer, setTimer] = useState<any>(null);

    const setTimerWrapper = (timer: any) => {
        setTimer((prev: any) => {
            if (prev) {
                clearInterval(prev);
            }

            return timer;
        });
    };

    const startTimer = useCallback(() => {
        const timer = setInterval(() => {
            setValue((prev) => (revers ? prev - 1 : prev + 1));
        }, 1000);

        setTimerWrapper(timer);
    }, [revers]);

    const stopTimer = useCallback(() => {
        if (timer) {
            clearInterval(timer);
            setTimerWrapper(null);
        }
    }, [timer]);

    const reset = () => {
        stopTimer();
        setValue(initialValue);
        startTimer();
    };

    useEffect(() => {
        startTimer();

        return stopTimer;
    }, []);

    useEffect(() => {
        if (typeof endValue !== 'undefined' && value === endValue) {
            stopTimer();
        }
    }, [value, endValue, stopTimer]);

    return { value, reset };
};
