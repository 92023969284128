import React, { SyntheticEvent, useCallback } from 'react';
import Point from './Point';
import useStores from '../../../../hooks/use-store';
import { Stores } from 'src/stores';
import { Point as PointObj } from 'src/types/points';
import PointsStore from 'src/stores/points-store';
import { observer } from 'mobx-react';

interface Props {}

const ExistingPoints: React.FC<Props> = observer((props: Props) => {
    const { points: pointsStore, imageScene } = useStores<Omit<Stores, 'points'> & { points: PointsStore }>();

    const pointClickHandler = useCallback((event: any, point: PointObj) => {
        event.stopPropagation();
        // dont do anything in created mode
        if (imageScene.createMode) return;

        imageScene.setActivePoint(imageScene.activePoint?.id !== point.id ? point : null);
    }, []);

    const isActivePoint = useCallback(
        (point: PointObj) => {
            return imageScene.activePoint?.id === point.id || imageScene.isHighlighted(point);
        },
        [imageScene.activePoint?.id],
    );

    return (
        <React.Fragment>
            {pointsStore.filteredPoints.map((point) => (
                <Point
                    key={point.id}
                    {...point.coordinates}
                    isActive={isActivePoint(point)}
                    onClick={(e: SyntheticEvent) => pointClickHandler(e, point)}
                />
            ))}
        </React.Fragment>
    );
});

export default ExistingPoints;
