import { AxiosPromise } from 'axios';
import { EndPointService } from '../api-handlers/axios';

const ROUTE = 'body-part-validations';

const create = (payload: any): AxiosPromise<any> => EndPointService.post(`/${ROUTE}`, payload);

const get = (id: string): AxiosPromise<any> => EndPointService.get(`/${ROUTE}/${id}`);

const list = (params: any): AxiosPromise<any> => EndPointService.get(`/${ROUTE}`, params);

const remove = (id: string): AxiosPromise<any> => EndPointService.delete(`/${ROUTE}/${id}`);

export default {
    get,
    list,
    remove,
    create,
};
