import React, { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren } from 'react';
import classNames, { Argument } from 'classnames';
import styles from '../assets/field-input.module.scss';
import { Control } from 'react-hook-form/dist/types/form';
import { CommonFieldProps } from '../types/common';
import { Controller } from 'react-hook-form';
import FieldWrapper, { FieldWrapperProps } from './FieldWrapper';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

interface FieldControlledInputProps extends CommonFieldProps {
    control: Control;
    wrapperProps?: FieldWrapperProps;
    errorClassName?: Argument;
    defaultValue?: string;
}

type Props = FieldControlledInputProps &
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    InputMaskProps;

const FieldMaskedInput: React.FunctionComponent<Props> = ({
    wrapperProps,
    className = 'form-control',
    errorClassName = 'border-danger text-danger',
    control,
    error,
    children,
    defaultValue = '',
    ...props
}: PropsWithChildren<Props>) => (
    <Controller
        name={props.name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }: any) => (
            <FieldWrapper {...wrapperProps} classNames={wrapperProps?.classNames} name={props.name} error={error}>
                <InputMask
                    id={props.id || props.name}
                    {...props}
                    {...field}
                    className={classNames(styles.input, className, error && errorClassName)}
                />
                {children}
            </FieldWrapper>
        )}
    />
);

export default FieldMaskedInput;
