import { AxiosPromise } from 'axios';
import { EndPointService } from 'src/services/api-handlers/axios';

const ROUTE = 'auths';

function login(username: string, password: string): AxiosPromise<any> {
    return EndPointService.post(`/${ROUTE}/staff`, { username, password });
}

function updatePassword(payload: { password: string; passwordRepeat: string }): AxiosPromise<any> {
    return EndPointService.post('/users/reset-password', payload);
}

const refresh = (refreshToken: any): AxiosPromise<any> => {
    return EndPointService.post(`/${ROUTE}/refresh`, { refreshToken });
};

const sendCode = (): AxiosPromise<any> => {
    return EndPointService.post(`/${ROUTE}/send-code`);
};

const verifyCode = (payload: any): AxiosPromise<any> => {
    return EndPointService.post(`/${ROUTE}/verify-code`, payload);
};

export default {
    login,
    updatePassword,
    refresh,
    sendCode,
    verifyCode,
};
