import React from 'react';
import styles from './assets/header.module.scss';
import classNames from 'classnames';
import Navigation from './Navigation';
import UserPreferences from './UserPreferences';

const Header: React.FunctionComponent = React.memo(() => {
    return (
        <header
            className={classNames(
                styles.header,
                'main-header',
                'navbar',
                'navbar-expand',
                'navbar-white',
                'navbar-light',
            )}
        >
            <Navigation />
            <UserPreferences />
        </header>
    );
});

export default Header;
