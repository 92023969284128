import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import FieldInput from '../../../components/react-hook-form/fields/FieldInput';
import { withErrors } from 'src/components/react-hook-form/utils/make-form-errors';
import Button from '../../../components/Button';

export interface BaseChangePasswordFormData {
    password: string;
    repeatPassword: string;
}

interface Props {
    onSubmit: (data: BaseChangePasswordFormData, dispatch: Dispatch) => Promise<any>;
}

const validationSchema = yup.object().shape({
    password: yup.string().required().min(6).max(255).label('Password'),
    repeatPassword: yup
        .string()
        .required()
        .min(6)
        .max(16)
        .equals([yup.ref('password')], 'Passwords must match')
        .label('Repeat Password'),
});

const BaseChangePasswordForm: React.FunctionComponent<Props> = ({ onSubmit }: Props) => {
    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { isSubmitting, submitCount, isSubmitSuccessful, errors },
    } = useForm<BaseChangePasswordFormData>({
        resolver: yupResolver(validationSchema),
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (isSubmitSuccessful) {
            toast.success('Password was successfully changed');
            reset({ password: '', repeatPassword: '' });
        }
    }, [submitCount]);

    const submit = async (data: BaseChangePasswordFormData) => {
        await withErrors<BaseChangePasswordFormData>(onSubmit(data, dispatch), setError);
    };

    return (
        <form onSubmit={handleSubmit(submit)} className={classNames('form')}>
            <div className={classNames('row')}>
                <div className={classNames('col-md-6')}>
                    <FieldInput
                        control={control}
                        name="password"
                        type="password"
                        wrapperProps={{ label: validationSchema.fields.password.spec.label }}
                        error={(errors.password as any)?.message}
                    />
                    <FieldInput
                        control={control}
                        name="repeatPassword"
                        type="password"
                        wrapperProps={{ label: validationSchema.fields.repeatPassword.spec.label }}
                        error={(errors.repeatPassword as any)?.message}
                    />
                </div>
            </div>
            <div>
                <Button type="submit" className={classNames('btn-success')} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSave} /> Save
                </Button>
            </div>
        </form>
    );
};

export default BaseChangePasswordForm;
