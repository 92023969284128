import * as yup from 'yup';
import { StringSchema } from 'yup';

export type InternationalPhoneNumberFormData = {
    internationalPhoneFormat: boolean;
    phoneNumberInt: string;
    phoneNumberLocal: string;
};

type Props = {
    fieldName: string;
    initialValue?: string;
};

type Output = {
    defaultValues: InternationalPhoneNumberFormData;
    validationSchema: StringSchema;
    withPreparedData: (data: InternationalPhoneNumberFormData) => any;
};

export const useInternationalPhoneNumber = ({ fieldName, initialValue = '' }: Props): Output => {
    return {
        defaultValues: {
            [fieldName]: initialValue || '',
            internationalPhoneFormat: initialValue?.[0] === '+',
            phoneNumberInt: initialValue?.[0] === '+' ? initialValue : '',
            phoneNumberLocal: initialValue?.[0] === '+' ? '' : initialValue,
        } as any,
        validationSchema: yup
            .string()
            .test({
                message: 'Valid phone format: XXX-XXX-XXXX',
                test: (value: any, { parent: data }: { parent: InternationalPhoneNumberFormData }) => {
                    if (data.internationalPhoneFormat) {
                        return true;
                    }

                    return /(\d{3})\-?(\d{3})\-?(\d{4})/.test(data.phoneNumberLocal);
                },
            })
            .test({
                message: 'Valid phone format: +XX-XXX-XXX-XXXX',
                test: (value: any, { parent: data }: { parent: InternationalPhoneNumberFormData }) => {
                    if (!data.internationalPhoneFormat) {
                        return true;
                    }

                    const parsed = data.phoneNumberInt.replace(/[\-_]/g, '');

                    return /^\+[\d]{10,14}$/.test(parsed);
                },
            }),
        withPreparedData: (data: InternationalPhoneNumberFormData): any => {
            const updatedData: any = { ...data };
            updatedData[fieldName] = (
                data.internationalPhoneFormat ? data.phoneNumberInt : data.phoneNumberLocal
            ).replace(/([^\+\d])/g, '');

            delete updatedData['internationalPhoneFormat'];
            delete updatedData['phoneNumberInt'];
            delete updatedData['phoneNumberLocal'];

            return updatedData;
        },
    };
};
