import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import Loader from 'src/components/Loader';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import { useDispatch } from 'src/hooks/dispatch';
import { scanCurrentSelector } from 'src/store/selectors/scan-selectors';
import ScanTabs from 'src/screens/Scan/components/ScanTabs';
import { getScan } from 'src/store/thunks/scan-thunks';
import ScanModelPreview from 'src/screens/Scan/screens/View/components/ScanModelPreview';
import { useQuery } from 'src/hooks/query';

interface RouteParams {
    id: string;
}

interface Props {}

const ScanViewScreen: FC<Props> = () => {
    const { id } = useParams<RouteParams>();
    const { model } = useQuery();
    const dispatch = useDispatch();
    const scan = useSelector(scanCurrentSelector);

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getScan(id)).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.error);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <ContentHeader
                    title={scan ? `${scan?.name}` : 'Loading scan...'}
                    breadcrumbs={[{ title: 'Sessions', link: Router.generate(routes.SCAN_LIST) }]}
                />
                <Content>
                    <ScanTabs />

                    {scan && <ScanModelPreview scan={scan} modelId={model} />}
                </Content>
                {!scan && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default ScanViewScreen;
