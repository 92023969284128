import React, { FC, memo } from 'react';
import { CellProps } from 'react-table';
import { faCommentDots, faDotCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Router from 'src/navigation/router';
import classNames from 'classnames';
import styles from '../assets/model-actions-column.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import routes from 'src/navigation/routes';
import { Model } from 'src/types/model';

type Action = 'view' | 'points' | 'reviews';

interface Props extends CellProps<Model> {
    actions: Action[];
}

const ModelActionsColumn: FC<Props> = memo(({ cell: { row }, actions }: Props) => (
    <div className={classNames(styles.container, 'btn-group', 'btn-group-toggle')}>
        {actions.includes('view') && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="model-review-points">View</Tooltip>}>
                <Link
                    to={Router.generate(routes.SCAN_VIEW, { id: row.original?.scan?.id, model: row.original?.id })}
                    className={classNames(['text-decoration-none', styles.actionLink])}
                >
                    <FontAwesomeIcon icon={faEye} color="#0d6efd" />
                </Link>
            </OverlayTrigger>
        )}

        {actions.includes('points') && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="model-review-points">Points</Tooltip>}>
                <Link
                    to={Router.generate(routes.MODEL_POINT_LIST, { id: row.original?.id })}
                    className={classNames(['text-decoration-none', styles.actionLink])}
                >
                    <FontAwesomeIcon icon={faDotCircle} color="#0d6efd" />
                </Link>
            </OverlayTrigger>
        )}
        {actions.includes('reviews') && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="model-review-edit">Reviews</Tooltip>}>
                <Link
                    to={Router.generate(routes.MODEL_REVIEW_LIST, { id: row.original?.id })}
                    className={classNames(['text-decoration-none', styles.actionLink])}
                >
                    <FontAwesomeIcon icon={faCommentDots} color="#0d6efd" />
                </Link>
            </OverlayTrigger>
        )}
    </div>
));

export default ModelActionsColumn;
