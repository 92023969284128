import React from 'react';
import GuestLayout from 'src/components/layout/GuestLayout';
import classNames from 'classnames';
import VerifyCodeForm from './components/VerifyCodeForm';

interface Props {}

const VerifyCodeScreen: React.FC<Props> = () => {
    return (
        <GuestLayout>
            <div className={classNames('login-page')}>
                <VerifyCodeForm />
            </div>
        </GuestLayout>
    );
};

export default VerifyCodeScreen;
