import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import ConfigStore from './config.store';
import { Model } from '../types/models';

type ModelApiListArgs = { sessionId: string };

export interface ModelsApi {
    list: (args: Partial<ModelApiListArgs>) => Promise<Model[]>;
}

export class ModelsStore {
    @observable
    public models: IObservableArray<Model> = observable([]);
    @observable
    public activeModel: Model | null = null;
    @observable
    public activeImage = false;
    @observable
    public activeModelValid = false;
    @observable
    public modelLoading = false;
    @observable
    public modelError: string | null = null;
    @observable
    public config: ConfigStore;
    @computed
    public get type() {
        return this.activeImage ? 'image' : this.activeModel?.type;
    }

    constructor(config: ConfigStore) {
        this.config = config;
        makeObservable(this);
    }

    @action
    public setModels = (models: Model[], activeModelId?: string) => {
        this.models = observable(models);
        const activeModel = activeModelId ? models.find(({ id }) => id === activeModelId) : null;

        this.setActiveModel(activeModel || models[0] || null);
    };

    @action
    public setActiveModel = (model: Model | null) => {
        this.activeModel = model;
        if (model) {
            this.config.triggerEvent('onModelChange', [model]);
        }
    };

    @action
    public setActiveImage(value: boolean) {
        this.activeImage = value;
        this.config.triggerEvent('onLayoutChange', [value]);
    }
}

export default ModelsStore;
