import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { crudHelpers, takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { ModelReview } from 'src/types/model-review';
import {
    createModelReview,
    getModelReview,
    modelReviewList,
    updateModelReview,
} from 'src/store/thunks/model-review-thunks';

export interface ModelReviewState extends ReducerState {
    data: Paginated<ModelReview>;
    current: ModelReview | null;
}

const initialState: ModelReviewState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'modelReview',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(modelReviewList.pending, takeEvery.pendingActionCase)
            .addCase(modelReviewList.rejected, takeEvery.rejectedActionCase)
            .addCase(modelReviewList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(createModelReview.pending, takeOne.pendingActionCase)
            .addCase(createModelReview.rejected, takeOne.rejectedActionCase)
            .addCase(createModelReview.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterCreate);
                state.data.list.push(action.payload);
            })

            .addCase(getModelReview.pending, takeEvery.pendingActionCase)
            .addCase(getModelReview.rejected, takeEvery.rejectedActionCase)
            .addCase(getModelReview.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterGet);
            })

            .addCase(updateModelReview.pending, takeEvery.pendingActionCase)
            .addCase(updateModelReview.rejected, takeEvery.rejectedActionCase)
            .addCase(updateModelReview.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
