/**
 * Mapped status item interface
 */
export interface SelectableItem {
    name: string;
    value: any;
}

/**
 * Check if value is selectable item
 *
 * @param x
 */
export function isSelectableItem(x: any): x is SelectableItem {
    return x && typeof x === 'object' && 'name' in x && 'value' in x;
}
