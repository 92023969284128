import { StoreState } from 'src/store/configure-store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const bodyPartValidationStateSelector = (state: StoreState) => state.bodyPartValidation;
export const bodyPartValidationListSelector = createDraftSafeSelector(
    bodyPartValidationStateSelector,
    ({ data }) => data,
);
export const bodyPartValidationErrorSelector = createDraftSafeSelector(
    bodyPartValidationStateSelector,
    ({ error }) => error,
);
export const bodyPartValidationLoadingSelector = createDraftSafeSelector(
    bodyPartValidationStateSelector,
    ({ loading }) => loading === 'loading',
);
export const bodyPartValidationCurrentSelector = createDraftSafeSelector(
    bodyPartValidationStateSelector,
    ({ current }) => current,
);
