import React, { PropsWithChildren } from 'react';
import styles from './assets/base-layout.module.scss';
import classNames from 'classnames';
import Main from './main/Main';

interface Props {}

const GuestLayout: React.FunctionComponent<Props> = ({ children }: PropsWithChildren<Props>) => (
    <div className={classNames(styles.layout)}>
        <div className={classNames(styles.content)}>
            <header />
            <Main>{children}</Main>
        </div>
        <footer />
    </div>
);

export default GuestLayout;
