import React, { FC, useCallback } from 'react';
import MainLayout from 'src/components/layout/MainLayout';
import ContentHeader from 'src/components/layout/main/ContentHeader';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import Content from 'src/components/layout/main/Content';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Loader from 'src/components/Loader';
import { AppDispatch } from 'src/store/configure-store';
import { useDispatch } from 'src/hooks/dispatch';
import { getModelReview, updateModelReview } from 'src/store/thunks/model-review-thunks';
import ModelReviewForm, { ModelReviewFormData } from 'src/screens/Model/screens/Reviews/components/ModelReviewForm';
import { modelReviewCurrentSelector, modelReviewLoadingSelector } from 'src/store/selectors/mode-review-selectors';
import Resource, { ResourceCallbacks } from 'src/components/Resource';
import ModelReviewStatus from 'src/enumerables/model-review-status';

interface RouteParams {
    id: string;
    reviewId: string;
}

interface Props {}

const EditScreen: FC<Props> = () => {
    const { id, reviewId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const modelReview = useSelector(modelReviewCurrentSelector);
    const loading = useSelector(modelReviewLoadingSelector);

    const handleSubmit = async (data: ModelReviewFormData, dispatch: AppDispatch) => {
        await dispatch(updateModelReview({ ...data, id, reviewId, status: ModelReviewStatus.PENDING })).unwrap();
    };

    const action = useCallback(
        async (callbacks: ResourceCallbacks) => {
            try {
                const res = await dispatch(getModelReview({ modelId: id, reviewId })).unwrap();
                callbacks.success && callbacks.success(res);
            } catch (e) {
                callbacks.failure && callbacks.failure((e as any)?.response);
            }
        },
        [id],
    );

    return (
        <MainLayout>
            <Resource action={action}>
                <>
                    <ContentHeader
                        title="Edit review"
                        breadcrumbs={[{ title: 'Model Reviews', link: Router.generate(routes.MODEL_REVIEW_LIST) }]}
                    />
                    <Content>
                        <ModelReviewForm
                            title={`${modelReview?.bodyModel ?? 'Model Review Edit'}`}
                            onSubmit={handleSubmit}
                            modelReview={modelReview}
                        />
                    </Content>
                </>
                {loading && <Loader />}
            </Resource>
        </MainLayout>
    );
};

export default EditScreen;
