import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import useStores from 'src/hooks/use-store';
import { Stores } from 'src/stores';
import { User } from 'src/types/user';
import ClearableDropdownList from 'src/components/common/form/ClearableDropdownList';
import Visible from 'src/components/common/Visible';

export interface Props {}

const FilterForm: React.FunctionComponent<Props> = observer((props: Props) => {
    const { points: pointsStore, models: modelsStore } = useStores<Stores>();
    const [show, setShow] = useState(false);

    const onChange = useCallback((value: User) => {
        pointsStore.setFilter({ createdBy: value });
    }, []);

    const onClear = useCallback(() => {
        pointsStore.setFilter({});
    }, []);

    const showToggle = () => {
        setShow(!show);
        onClear();
    };

    useEffect(() => {
        pointsStore.setFilter({});
    }, [modelsStore.activeModel, modelsStore.activeImage]);

    return (
        <React.Fragment>
            {!show && (
                <button type="button" className={classNames('btn', 'btn-primary', 'btn-block')} onClick={showToggle}>
                    Filters
                </button>
            )}
            {show && (
                <button className={classNames('close-btn')} onClick={showToggle}>
                    &#10006;
                </button>
            )}
            <Visible if={show}>
                <div className={classNames('filter-form')}>
                    <label>Filter points by user: </label>
                    <ClearableDropdownList
                        value={pointsStore.filter.createdBy}
                        data={pointsStore.users}
                        onChange={onChange}
                        dataKey={'id'}
                        textField={(item: any) => `${item?.firstName} ${item?.lastName}`}
                        renderValue={({ item }) => {
                            return `${item?.firstName} ${item?.lastName}`;
                        }}
                        allowClear={true}
                        onClear={onClear}
                    />
                </div>
            </Visible>
        </React.Fragment>
    );
});

export default FilterForm;
