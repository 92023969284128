import { createSlice } from '@reduxjs/toolkit';
import {
    bodyPartList,
    bodyPartTree,
    deleteBodyPart,
    getBodyPart,
    updateBodyPart,
} from 'src/store/thunks/body-part-thunks';
import { Paginated } from 'src/services/api-handlers/pagination';
import { crudHelpers, takeOne, takeEvery } from 'src/helpers/store';
import { ReducerState } from 'src/store/configure-store';
import { BodyPart } from 'src/types/body-part';

export interface BodyPartsState extends ReducerState {
    data: Paginated<BodyPart>;
    current: BodyPart | null;
}

const initialState: BodyPartsState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'bodyParts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bodyPartList.pending, takeEvery.pendingActionCase)
            .addCase(bodyPartList.rejected, takeEvery.rejectedActionCase)
            .addCase(bodyPartList.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterList);
            })

            .addCase(bodyPartTree.pending, takeEvery.pendingActionCase)
            .addCase(bodyPartTree.rejected, takeEvery.rejectedActionCase)
            .addCase(bodyPartTree.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, (store, action) => {
                    store.data = { list: action.payload, page: 1, pageSize: -1, totalCount: -1, totalPages: -1 };
                });
            })

            .addCase(getBodyPart.pending, takeEvery.pendingActionCase)
            .addCase(getBodyPart.rejected, takeEvery.rejectedActionCase)
            .addCase(getBodyPart.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, crudHelpers.afterGet);
            })

            .addCase(updateBodyPart.pending, takeOne.pendingActionCase)
            .addCase(updateBodyPart.rejected, takeOne.rejectedActionCase)
            .addCase(updateBodyPart.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterUpdate);
            })

            .addCase(deleteBodyPart.pending, takeOne.pendingActionCase)
            .addCase(deleteBodyPart.rejected, takeOne.rejectedActionCase)
            .addCase(deleteBodyPart.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, crudHelpers.afterDelete);
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
