import React, { ReactNode, useEffect, useState } from 'react';
import Error from 'src/components/Error';
import { ResolverApiFailure } from 'src/services/api-handlers/api-resolver';
import { AxiosResponse } from 'axios';

export interface ResourceCallbacks<T = any> {
    success?: (value?: T | PromiseLike<T>) => void;
    failure?: (response?: AxiosResponse) => void;
}

interface Props {
    children: ReactNode;
    action: (callbacks: ResourceCallbacks<any>) => void;
}

const Resource: React.FC<Props> = ({ children, action }: Props) => {
    const [error, setError] = useState<ResolverApiFailure['error']['response'] | null>(null);

    useEffect(() => {
        action({ failure: setError });
    }, [action]);

    return (
        <>
            {error ? (
                <Error code={error.data?.statusCode} message={error.data?.message || error.statusText} />
            ) : (
                children
            )}
        </>
    );
};

export default Resource;
