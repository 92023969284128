import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

interface Props {
    x: number;
    y: number;
    onClick?: (e: SyntheticEvent) => void;
    isActive?: boolean;
}

const Point: React.FC<Props> = ({ x, y, onClick, isActive = false }: Props) => {
    return (
        <span
            className={classNames('image-point', isActive ? 'active' : '')}
            style={{ top: `${y}px`, left: `${x}px` }}
            onClick={onClick}
        />
    );
};

export default Point;
