import { createSlice } from '@reduxjs/toolkit';
import { ReducerState } from 'src/store/configure-store';
import { takeEvery, takeOne } from 'src/helpers/store';
import { Paginated } from 'src/services/api-handlers/pagination';
import { getScanPhyScore, updateScanPhyScore } from 'src/store/thunks/scan-phy-score-thunks';
import { ScanPhyScore } from 'src/types/scan-phy-score';

export interface ScanPhyScoreState extends ReducerState {
    data: Paginated<ScanPhyScore>;
    current: ScanPhyScore | null;
}

const initialState: ScanPhyScoreState = {
    error: '',
    loading: 'none',
    requestIds: [],
    current: null,
    data: {
        list: [],
        page: 1,
        meta: {
            updatedAt: null,
        },
    },
};

export const slice = createSlice({
    name: 'scanPhyScore',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getScanPhyScore.pending, takeEvery.pendingActionCase)
            .addCase(getScanPhyScore.rejected, takeEvery.rejectedActionCase)
            .addCase(getScanPhyScore.fulfilled, (state, action) => {
                takeEvery.fulfilledActionCase(state, action, (store, action) => {
                    store.current = action.payload;
                });
            })

            .addCase(updateScanPhyScore.pending, takeOne.pendingActionCase)
            .addCase(updateScanPhyScore.rejected, takeOne.rejectedActionCase)
            .addCase(updateScanPhyScore.fulfilled, (state, action) => {
                takeOne.fulfilledActionCase(state, action, (store, action) => {
                    store.current = action.payload;
                });
            });
    },
});

export const {} = slice.actions;

export default slice.reducer;
