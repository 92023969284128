import { Paginated } from 'src/services/api-handlers/pagination';
import Grid, { Column } from 'src/components/react-table/Grid';
import React, { FC } from 'react';
import { QueryParams } from 'src/types/grid';
import DateTimeColumn from 'src/components/react-table/columns/DateTimeColumn';
import classNames from 'classnames';
import styles from '../assets/scan-condition-list.module.scss';
import { Link, useParams } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ScanCondition } from 'src/types/scan-condition';
import ScanConditionActionsColumn from './ScanConditionActionsColumn';

type Props = {
    data: Paginated<ScanCondition>;
    getData: (params: QueryParams) => void;
};

type Params = {
    id: string;
};

const conditionColumns: Column<ScanCondition>[] = [
    // {
    //     Header: 'Sessions',
    //     accessor: 'scan',
    // },
    {
        Header: 'Condition',
        accessor: 'condition',
        Cell: ({ value }) => value.name,
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: DateTimeColumn,
        disableFilters: true,
    },
    {
        Header: 'Updated at',
        accessor: 'updatedAt',
        Cell: DateTimeColumn,
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <ScanConditionActionsColumn {...props} actions={['delete']} />,
        disableSortBy: true,
        disableFilters: true,
        width: '10%',
    },
];

const ScanConditionList: FC<Props> = ({ data, getData }: Props) => {
    const { id } = useParams<Params>();
    return (
        <Grid<ScanCondition>
            columns={conditionColumns}
            data={data}
            getData={getData}
            title={
                <div className={classNames(styles.heading, 'd-flex', 'justify-content-end')}>
                    <Link
                        className={classNames('text-decoration-none')}
                        to={Router.generate(routes.SCAN_CONDITION_CREATE, { id })}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Create
                    </Link>
                </div>
            }
            defaultSorting={{ createdAt: 'DESC' }}
        />
    );
};

export default ScanConditionList;
