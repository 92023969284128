import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../../../components/Button';
import { GlobalError, withErrors } from '../../../../../components/react-hook-form/utils/make-form-errors';
import FieldInput from '../../../../../components/react-hook-form/fields/FieldInput';
import { useDispatch } from 'src/hooks/dispatch';
import FormError from '../../../../../components/react-hook-form/FormError';
import { useHistory } from 'react-router-dom';
import Router from 'src/navigation/router';
import { routes } from 'src/navigation';
import styles from '../assets/verify-code-form.module.scss';
import { useCountdown } from 'src/hooks/countdown';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from 'src/hooks/user';
import { sendCode as sendCodeAction, verifyCode as verifyCodeAction } from '../../../../../store/thunks/auth-thunks';

export interface VerifyFormData {
    code: string;
}

interface VerifyFormProps {}

const validationSchema = yup.object().shape({
    code: yup.string().required().max(4),
});

const VerifyForm: React.FunctionComponent<VerifyFormProps> = ({}: VerifyFormProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        control,
        setError,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = useForm<VerifyFormData>({
        resolver: yupResolver(validationSchema),
    });
    const user = useUser();
    const { value, reset } = useCountdown({ initialValue: 30, revers: true });
    const canResend = value <= 0;
    const trimedPhoneNumber = useMemo(() => {
        if (!user?.phoneNumber) {
            return null;
        }

        // e.g. +38*******96
        return (
            user.phoneNumber.slice(0, 3) + new Array(user.phoneNumber.length - 4).join('*') + user.phoneNumber.slice(-2)
        );
    }, [user?.phoneNumber]);

    const onSubmit = async (data: VerifyFormData) => {
        const res = await withErrors<VerifyFormData>(dispatch(verifyCodeAction(data)).unwrap(), setError);

        if (res !== false) {
            history.push(Router.generate(routes.DASHBOARD));
        }
    };

    const sendCode = useCallback(
        (onSuccess?: () => void) => {
            dispatch(
                sendCodeAction({
                    success: onSuccess,
                    failure: (res) => {
                        switch (res?.status) {
                            case 401:
                                return history.push(Router.generate(routes.HOME));
                            case 400:
                                break; // ignore 400 error on page refresh, etc.
                            default:
                                toast.error((res?.data as any)?.message);
                        }
                    },
                }),
            );
            reset();
        },
        [dispatch, reset],
    );

    const resendCode = useCallback(() => {
        sendCode(() => toast.success('Code was sent'));
    }, [sendCode]);

    useEffect(() => {
        sendCode();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classNames('form')}>
            <div className={classNames('login-logo')}>PHY</div>
            <div className={classNames('card')}>
                <div className={classNames('card-body')}>
                    <p className="login-box-msg">
                        We&apos;ve sent you the security code <br />
                        {trimedPhoneNumber ? `(${trimedPhoneNumber})` : ''}
                    </p>
                    <div>
                        <FormError
                            error={errors.code?.message || (errors as GlobalError)._error?.message}
                            classNames={{ errorContainer: 'text-center mb-2' }}
                        />
                        <FieldInput
                            name="code"
                            control={control}
                            className={classNames('form-control')}
                            placeholder="XXXX"
                            wrapperProps={{
                                classNames: {
                                    inputContainer: 'input-group mb-2',
                                    errorContainer: 'text-center mb-2',
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            className={classNames('btn', 'btn-primary', 'btn-block')}
                            disabled={isSubmitting}
                        >
                            Sign In
                        </Button>
                        <div className={classNames(styles.links)}>
                            <Link to={routes.HOME} className={classNames(styles.link)}>
                                Back
                            </Link>
                            <a
                                href="#"
                                className={classNames([styles.link, !canResend && styles.disabled])}
                                onClick={resendCode}
                            >
                                Resend code {!canResend && <span>({value}s)</span>}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default VerifyForm;
