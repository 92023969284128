import { EnumerableAbstract } from './enumerable.abstract';

class UserRole extends EnumerableAbstract {
    public readonly ROLE_STAFF = 'ROLE_STAFF';
    public readonly ROLE_ADMIN = 'ROLE_ADMIN';
    public readonly ROLE_PT = 'ROLE_PT';

    public getName = () => 'UserRole';

    public defaultChoices = () => ({
        [this.ROLE_STAFF]: 'Staff',
        [this.ROLE_ADMIN]: 'Admin',
        [this.ROLE_PT]: 'Provider',
    });
}

const userRole = new UserRole();

export default userRole;
